import {publicRequest, userRequest} from "../../requestMethod";
import Notification from "../../utils/notification";
import {
    getBankFailure,
    getBankStart,
    getBankSuccess,
    deleteBankFailure,
    deleteBankStart,
    getSingleBankFailure,
    getSingleBankStart,
    getSingleBankSuccess,
    deleteBankSuccess,
    updateBankFailure,
    updateBankStart,
    updateBankSuccess,
    addBankFailure,
    addBankStart,
    addBankSuccess,
} from "../bankRedux";

//
export const addBank = async (dispatch, Bank) => {
    dispatch(addBankStart());
    try {
        // const res = await userRequest.post(`/Banks`, Bank);
        const res = await userRequest.post(`/admin/bank-details`, Bank);
        console.log("post data", res.data.data)
        dispatch(addBankSuccess(res.data.data));
        Notification.success(res.data.message);
        localStorage.setItem("tabstate", "");
        window.location.href="/artisan-management";
    } catch (err) {
        if (err && err.response && err.response.status === 403) {
            localStorage.clear();
            window.location.href = "/signin";
        }else{
            dispatch(addBankFailure());
            Notification.error(err.response.data.message);
        }
    }
};

export const getBanks = async (dispatch) => {
    dispatch(getBankStart());
    try {
        const res = await publicRequest.get("/admin/bank-details");
        dispatch(getBankSuccess(res.data.data));
        // Notification.success(res.data.status.msg);
    } catch (err) {
        if (err && err.response && err.response.status === 403) {
            localStorage.clear();
            window.location.href = "/signin";
        }else{
            dispatch(getBankFailure());
            Notification.error(err.response.data.message);
        }
    }
};

export const getBank = async (id, dispatch) => {
    // alert(5)
    dispatch(getSingleBankStart());
    try {
        const res = await userRequest.get(`/admin/bank-details/${id}?include=owner`);
        dispatch(getSingleBankSuccess(res.data.data.bank));
        // Notification.success(res.data.message);
    } catch (err) {
        if (err && err.response && err.response.status === 403) {
            localStorage.clear();
            window.location.href = "/signin";
        }else{
            dispatch(getSingleBankFailure());
            Notification.error(err.response.data.message);
        }
    }
};

export const updateBank = async (id, artisan, dispatch) => {
    // alert(5)
    dispatch(updateBankStart());
    try {
        const res = await userRequest.put(`/admin/bank-details/${id}`, artisan);
        // dispatch(updateBankSuccess(res.data.data));
        localStorage.setItem("tabstate", "");
        Notification.success(res.data.message);
        window.location.href="/artisan-management";
    } catch (err) {
        if (err && err.response && err.response.status === 403) {
            localStorage.clear();
            window.location.href = "/signin";
        }else{
            dispatch(updateBankFailure());
            Notification.error(err.response.data.message);
        }
    }
};

//
export const deleteBankFunc = async (id, dispatch) => {
    dispatch(deleteBankStart());
    try {
        const res = await userRequest.delete(`/Banks/${id}`);
        dispatch(deleteBankSuccess(id));
        Notification.success(res.data.status.msg);
        window.location.reload();
    } catch (err) {
        if (err && err.response && err.response.status === 403) {
            localStorage.clear();
            window.location.href = "/signin";
        }else{
            dispatch(deleteBankFailure());
            Notification.error(err.response.data.message);
        }
    }
};
