import React, {ChangeEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SidebarNavbar from "../../components/Sidebar"
import Footer from "../../components/Footer";
import Notification from "../../utils/notification";
import { addProduct, getProduct, updateProduct } from "../../redux/ApiCalls/productCalls";
import { getArtisans } from "../../redux/ApiCalls/artisanCalls";
import { getCategories } from "../../redux/ApiCalls/categoryCalls";
import Spinner from "../../components/Loading";
import { useLocation } from "react-router-dom";

const View = ({ location }) => {
    const [artisan_id, setArtisan] = useState("")
    const [category_id, setCategory] = useState("")
    const [name, setName] = useState("")
    const [description, setDescription] = useState("")
    const [deliverydesc, setDeliverydesc] = useState("")
    const [deliveryrange, setDeliveryrange] = useState("")
    const [amount, setAmount] = useState("")
    // const [featured_image, setFeatureImage] = useState("")
    const [fileList, setFileList] = useState(null);
    const [fileLimit, setFileLimit] = useState(false);
    const [is_active, setActive] = useState(1)
    const [is_verified, setVerified] = useState(0)
    const [start_date, setStartDate] = useState(null)
    const [finish_date, setFinishDate] = useState(null)

    const locationpath = useLocation();
    const id = locationpath.pathname.split('/')[2];
    const dispatch = useDispatch()

    useEffect(() => {
        getProduct(id, dispatch)
    }, [])

    useEffect(() => {
        getCategories(dispatch);
    }, [dispatch]);

    useEffect(() => {
        getArtisans(dispatch, '');
    }, [dispatch]);

    const product = useSelector((state) => state.product?.product);

    const categories = useSelector((state) => state.categories.categories);
    const artisans = useSelector((state) => state.artisans.artisans);
    const { isFetching, error, isSuccess } = useSelector(state => state.product)

    const handleImage = (e) => {
        const reader = new FileReader();
        reader.readAsDataURL(e);
        // setFeatureImage(e);
        reader.onload = function () {
        };
    };

    const HandleFileListUpload = (e: ChangeEvent<HTMLInputElement>) => {
        let limitExceeded=false;
        if(e.target.files.length>=3 && e.target.files.length<=5) setFileLimit(false)
        else {
            setFileLimit(true);
            Notification.error("Minimum of 3 or maximum of 5 files is required");
            return true;
        }
        setFileList(e.target.files);
      };
    
      const files = fileList ? [...fileList] : [];

    const updateProductFunc = (e) => {
        e.preventDefault()
        if(!fileLimit)
        {
            const formData = new FormData();
            formData.append("artisan_id", artisan_id ? artisan_id : product?.artisan_id);
            formData.append("category_id", category_id ? category_id : product?.category_id);
            formData.append("name", name ? name : product?.name);
            formData.append("description", description ? description : product?.description);
            formData.append("amount", amount ? amount : product?.amount);
            formData.append("delivery_range", deliveryrange ? deliveryrange : product?.delivery_time_range);
            formData.append("delivery_desc", deliverydesc ? deliverydesc : product?.delivery_time_desc);
            files.forEach((file, i) => {
                formData.append(`featured_image[${i}]`,  file);
            });
            formData.append("is_active", product?.is_active ? 1 : is_active);
            formData.append("is_verified", product?.is_verified ? 1 : is_verified);
            formData.append("start_date", start_date ? start_date.slice(0,10) : product?.start_date);
            formData.append("finish_date", finish_date ? finish_date.slice(0,10) : product?.finish_date);
            formData.append("_method", "PUT");

            updateProduct(id, (formData), dispatch)
        }
        else{
            Notification.error("Minimum of 3 or maximum of 5 files is required");
        }
    }

    return (
        <>
            <div id="layoutSidenav">
                <SidebarNavbar />
                <div id="layoutSidenav_content">
                    <main>
                        <div className="container px-2">
                            <div className="d-flex justify-content-between align-items-baseline">
                                <h4 className="mt-4">Marketplace</h4>
                                <div className="breadcrumb mb-4">
                                    <a className="btn btn-xs btn-primary" href="/marketplace">List Marketplace</a>
                                </div>
                            </div>
                            <div className="row justify-content-center">
                                <div className="col-lg-12">
                                    <div className="card shadow-lg border-0 rounded-lg mt-5">
                                        <div className="card-header">
                                            <h5 className=" my-4">Update Product</h5>
                                        </div>
                                        <div className="card-body">
                                            <form>
                                                <div className="row mb-3">
                                                    <img src={product?.featured_image}  /> 
                                                </div>
                                                <div className="row mb-5">
                                                    <div className="col-md-6">
                                                        <div className="form-floating">
                                                            <select
                                                                className="form-control"
                                                                name="artisan_id"
                                                                defaultValue={product?.artisan_id}
                                                                onChange={(e) => setArtisan(e.target.value)}>
                                                                <option>Select Artisan</option>
                                                                {artisans?.data?.map((item, idx) =>
                                                                    <option key={idx} value={item.id}>{item.first_name} {" "} {item.last_name}</option>
                                                                )}
                                                            </select>
                                                            <label htmlFor="inputLastName">Artisan</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-floating mb-3 mb-md-0">
                                                            <input className="form-control"
                                                                id="inputFirstName"
                                                                type="text"
                                                                name="name"
                                                                defaultValue={product?.name}
                                                                onChange={(e) => setName(e.target.value)}
                                                                placeholder="Enter product name" />
                                                            <label htmlFor="inputFirstName">Product Name</label>
                                                        </div>
                                                    </div>

                                                </div>

                                                <div className="row mb-5 mt-4">
                                                    <div className="col-md-6">
                                                        <div className="form-floating">
                                                            <select
                                                                className="form-control"
                                                                defaultValue={product?.category_id}
                                                                name="category_id"
                                                                onChange={(e) => setCategory(e.target.value)}
                                                                id="category_id">
                                                                <option>Select Category</option>
                                                                {categories?.data?.map((item, idx) =>
                                                                    <option key={idx} value={item.id}>{item.name}</option>
                                                                )}
                                                            </select>
                                                            <label htmlFor="inputLastName">Category</label>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-floating mb-3 mb-md-0">
                                                            <input className="form-control" id="inputFirstName"
                                                                type="text"
                                                                name="description"
                                                                defaultValue={product?.description}
                                                                onChange={(e) => setDescription(e.target.value)}
                                                                placeholder="Enter description" />
                                                            <label htmlFor="inputFirstName">Description</label>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row mb-5 mt-4">
                                                    <div className="col-md-6">
                                                        <div className="form-floating">
                                                            <input className="form-control" id="inputLastName"
                                                                   type="text"
                                                                   name="amount"
                                                                   defaultValue={product?.amount}
                                                                   onChange={(e) => setAmount(e.target.value)}
                                                                   placeholder="Enter amount" />
                                                            <label htmlFor="inputLastName">Amount</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-floating">
                                                            <input className="form-control" id="inputLastName"
                                                                   type="file"
                                                                   accept="image/png, image/gif, image/jpeg"
                                                                   onChange={HandleFileListUpload}
                                                                   disabled={fileLimit}
                                                                   multiple
                                                            />
                                                            <label htmlFor="inputLastName">Upload Pictures of product</label>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row mb-5">
                                                    <div className="col-md-6">
                                                        <div className="form-floating mb-3 mb-md-0">
                                                            <input className="form-control"
                                                                id="inputFirstName"
                                                                type="text"
                                                                name="name"
                                                                defaultValue={product?.delivery_time_range}
                                                                onChange={(e) => setDeliveryrange(e.target.value)}
                                                                placeholder="Enter time range" />
                                                            <label htmlFor="inputFirstName">Delivery Time Range</label>
                                                        </div>
                                                    </div>


                                                    <div className="col-md-6">
                                                        <div className="form-floating">
                                                            <select
                                                                className="form-control"
                                                                defaultValue={product?.delivery_time_desc}
                                                                name="category_id"
                                                                onChange={(e) => setDeliverydesc(e.target.value)}
                                                                id="category_id">
                                                                <option>Select Description</option>
                                                                <option>min</option>
                                                                <option>hour</option>
                                                                <option>day</option>
                                                                <option>week</option>
                                                                <option>month</option>                                                                
                                                            </select>
                                                            <label htmlFor="inputLastName">Delivery Time Description</label>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row mb-5">
                                                    <div className="col-md-6">
                                                        <span>{product?.start_date}</span>
                                                        <div className="form-floating mb-3 mb-md-0">
                                                            <input className="form-control" id="inputStartDate"
                                                                   type="datetime-local"
                                                                   name="start_date"
                                                                   defaultValue={product?.start_date}
                                                                   onChange={(e)=>setStartDate(e.target.value)}
                                                                   placeholder="Enter start date"/>
                                                            <label htmlFor="inputStartDate">Start Date</label>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <span>{product?.finish_date}</span>
                                                        <div className="form-floating">
                                                            <input className="form-control" id="inputEndDate"
                                                                   type="datetime-local"
                                                                   name="finish_date"
                                                                   defaultValue={product?.finish_date}
                                                                   onChange={(e)=>setFinishDate(e.target.value)}
                                                                   placeholder="Enter finish date"/>
                                                            <label htmlFor="inputEndDate">Finish Date</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mb-5">
                                                    <div className="form-group">
                                                        <div className="form-check form-check-inline">
                                                            <input className="form-check-input" type="checkbox" id="inlineCheckbox1"
                                                                   name="is_active"
                                                                   checked={product?.is_active ? true : false}
                                                                   onChange={(e) => e.target.checked ? setActive(1) : setActive( 0)}
                                                            />
                                                            <label style={{fontSize:"16px"}} className="form-check-label" htmlFor="inlineCheckbox1">Make Active</label>
                                                        </div>

                                                        <div className="form-check form-check-inline">
                                                            <input className="form-check-input" type="checkbox" id="inlineCheckbox2"
                                                                   name="is_verified"
                                                                   checked={product?.is_verified ? true : false}
                                                                   onChange={(e) => e.target.checked ? setVerified( 1) : setVerified( 0)}
                                                            />
                                                            <label style={{fontSize:"16px"}} className="form-check-label" htmlFor="inlineCheckbox2">Make Verified</label>
                                                        </div>

                                                    </div>

                                                </div>
                                                <div className="col-md-4 mt-4 mb-0">
                                                    <div className="d-grid">
                                                        <a onClick={updateProductFunc} className="btn btn-primary btn-block">
                                                            {isFetching ? (
                                                                <Spinner />
                                                            ) : (
                                                                <span>update{'  '}</span>
                                                            )}
                                                        </a>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                    <Footer />
                </div>
            </div>
        </>
    )
}
export default View;