import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "user",
  initialState: {
    currentUser: null,
    users: [],
    isFetching: false,
    isSuccess:false,
    error: false,
  },
  reducers: {
    loginStart: (state) => {
      state.isFetching = true;
    },
    loginSuccess: (state, action) => {
      state.isFetching = false;
      state.currentUser = action.payload;
    },
    loginFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    getAllUserStart: (state) => {
      state.isFetching = true; 
      state.error = false;
    },
    getAllUserSuccess: (state, action) => {
      state.isFetching = false;
      state.users = action.payload;
    },
    getAllUserFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    logout: (state) => {
      state.currentUser = null;
    },
    suspendUserStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    suspendUserSuccess: (state, action) => {
      state.isFetching = false;
    },
    suspendUserFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
  },
});

export const {
  logout,
  loginStart,
  loginSuccess,
  loginFailure,
  getAllUserStart,
  getAllUserSuccess,
  getAllUserFailure,
  suspendUserStart,
  suspendUserSuccess,
  suspendUserFailure
} = userSlice.actions;
export default userSlice.reducer;
