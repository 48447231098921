import axios from "axios";
import { getUserToken, getSuperToken} from './helpers/auth'
import { useRecoilState } from 'recoil';

const BASE_URL = process.env.REACT_APP_BASE_URL;
const TOKEN = getUserToken();
const SUPERTOKEN = getSuperToken();
export const publicRequest = axios.create({
    baseURL: BASE_URL,
    headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
    }
})
 
// console.log("TTTTOOOOOOKEEEEN", TOKEN)
// console.log("SEEEECCCCCCTOOOKENNNNN", localStorage.getItem("JamboloAdmintoken"))
export const userRequest = axios.create({
    baseURL: BASE_URL,
    // headers: { token: `Bearer ${TOKEN}` },
    headers: {
        'Authorization': `Bearer ${TOKEN}`,
        "Accept": "application/json",
        "Content-Type": "application/json",
    }
});

export const useAxios = () => {
    // const router = useRouter()
    const [auth, setAuth] = useRecoilState(TOKEN);
  
    const axiosInstance = axios.create({
      baseURL: BASE_URL,
      headers: {
        'Authorization': `Bearer ${TOKEN}`,
        "Accept": "application/json",
        "Content-Type": "application/json",
    }
    });
  
    
  
    axiosInstance.interceptors.request.use(async req => {
      req.headers['Accept'] = 'application/json';
      // req.headers['Content-Type'] = 'multipart/form-data';
  
      const token = auth;
      req.headers['Authorization'] = token ? `Bearer ${token}` : null;
  
      return req
    })
  
    axiosInstance.interceptors.response.use(
      (response) => {
        if ([401, 403].includes(response.status) && auth) {
          // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
          setAuth(null);
        }
        return response.data
      },
      (error) => {
        if (error?.message?.includes('Network Error')) {
        //   notify('There seems to be something wrong with your internet connection, please check and try again.', { type: 'error', duration: 5000 })
          return
        }
        if (error?.response?.data) {
          return Promise.reject(error?.response?.data?.message || error?.response?.data)
        }
        return Promise.reject(error.message)
      });
  
    return axiosInstance
  }

export const userSuperRequest = axios.create({
    baseURL: BASE_URL,
    // headers: { token: `Bearer ${TOKEN}` },
    headers: {
        'Authorization': `Bearer ${SUPERTOKEN}`,
        "Accept": "application/json",
        "Content-Type": "application/json",
    }
});

export const userSuperRequestFile = axios.create({
    baseURL: BASE_URL,
    // headers: { token: `Bearer ${TOKEN}` },
    headers: {
        'Authorization': `Bearer ${SUPERTOKEN}`,
        // "Accept": "application/json",
        'Content-Type': 'application/pdf',
    }
});
