import React, { useEffect, useState } from "react";
import SidebarNavbar from "../../components/Sidebar";
import Footer from "../../components/Footer";
import CustomLoader from "../../components/CustomLoader";
import DataTableExtensions from "react-data-table-component-extensions";
import DataTable from "react-data-table-component";
import 'react-data-table-component-extensions/dist/index.css';
import { getArtisans, deleteArtisanFunc, handlePageChange, handlePerRowsChange,creditArtisan } from "../../redux/ApiCalls/artisanCalls";
import { useDispatch, useSelector } from "react-redux";
import Moment from "react-moment";
import zIndex from "@material-ui/core/styles/zIndex";
import NumberFormat from "react-number-format";
import Switch from "react-switch";
import { FeatureArtisanFunc } from "../../redux/ApiCalls/authCalls";

const ArtisanManagement = () => {
    const dispatch = useDispatch();
    const [status, setStatus] = useState(null);
    const [featureArtisan, SetFeatureArtisanPopup] = useState(false);
    const [userId, setUserId] = useState(null);
    const filterPhone = (e) => {
        setBusiness(e);
        getArtisans(dispatch, '',business)
    }

    const FeatureArtisanPop = () => {
        SetFeatureArtisanPopup(true)
        document.body.style.overflow = 'hidden';
    }
   
    useEffect(() => {
        getArtisans(dispatch, '')
    }, [dispatch])

    const [columns, setColumns] = useState([
        {
            name: 'logo',
            sortable: true,
            width: '100px',
            cell: row => row.profile ?
                <img src={row.profile} height={20} width={20} />          
                
                :
                <img src={row.logo} height={20} width={20}/>
                
        },
        {
            name: 'Wallet',
            selector: 'created_at',
            sortable: true,
            width: '150px',
            cell: row => row.wallet.balance ?
                  <div>
                    ₦<NumberFormat value={(row.wallet?.balance)} displayType={'text'} thousandSeparator={true} />
                  </div>
                :
                <div>

                </div>
        },
        {
            name: 'First Name',
            selector: 'first_name',
            sortable: true,
            width: '200px',
        },
        {
            name: 'Last Name',
            selector: 'last_name',
            sortable: true,
            width: '200px',
        },
        {
            name: 'Mobile',
            selector: 'phone',
            sortable: true,
            width: '200px',
            cell: row => row.phone ?
                <div>
                    {row.phone}
                </div>
                :
                <div>
                    N/A
                </div>
        },
        {
            name: ' Featured',
            selector: 'created_at',
            sortable: true,
            width: '100px',
            cell: row => row?.created_at &&
                <div>
                    <Switch checked={row.is_featured==1?'checked':''} onChange={(e) => {
                        FeatureArtisanPop();
                        setUserId(row.id);
                        setStatus(row.is_featured)
                    }}  />
                    
                </div>
        },
        {
            name: 'Business Name',
            selector: 'business_name',
            sortable: true,
            width: '200px',
        },
        {
            name: 'Created',
            selector: 'created_at',
            sortable: true,
            width: '120px',
            cell: row => row.created_at ?
                <div>
                    <Moment format="D MMM YYYY">{row.created_at}</Moment>
                </div>
                :
                <div>

                </div>
        },
        {
            name: 'Action',
            selector: 'created_at',
            sortable: true,
            cell: row => row.created_at &&
                <div style={{ width: 300 }}>
                    <a className="btn btn-xs btn-primary text-white" style={{fontSize: '10px'}} href={`view-artisan/${row.id}`}>view</a> {"|"}
                    <a className="btn btn-xs btn-primary text-white" style={{fontSize: '10px'}} href={`edit-artisan/${row.id}`}>edit</a> {"|"}
                    <button
                        style={{fontSize: '10px'}}
                        onMouseUp={(e) => {
                            deleteArtisanPop();
                            setArtisanId(row.id);
                        }}
                        type="submit"
                        className="btn btn-xs btn-danger text-white" >delete</button>{"|"}
                    <button
                        style={{fontSize: '10px'}}
                        onMouseUp={(e) => {
                            creditWalletPop();
                            setArtisanId(row);
                        }}
                        type="submit"
                        className="btn btn-xs btn-success text-white" >credit wallet</button>
                </div>
        }
    ],
    )
    const customStyles = {
        rows: {
            style: {
                minHeight: '72px', // override the row height
                minWidth: '10px'
            },
        },
        headCells: {
            style: {
                paddingLeft: '2px', // override the cell padding for head cells
                paddingRight: '2px',
            },
        },
        // cells: {
        //     style: {
        //         paddingLeft: '8px', // override the cell padding for data cells
        //         paddingRight: '8px',
        //         width: '15px'
        //     },
        // },
    };
    const [deleteArtisan, SetDeletePopup] = useState(false)
    const [creditWallet, SetCreditWalletPopup] = useState(false)
    const [artisanId, setArtisanId] = useState(null)
    const [amount, setAmount] = useState(0)
    const [phone, setPhone] = useState()
    const [business, setBusiness] = useState()
    const FeatureFunc = () => {
        FeatureArtisanFunc (userId, dispatch);
    }
    const deleteFunc = () => {
        deleteArtisanFunc(artisanId, dispatch);
    }

    const paymentFunc = () => {
        // artisanId.city_id=amount;
        const formData = new FormData();
        formData.append("owner_type", "artisan");
        // formData.append("name", amount);
        // formData.append("description", amount);
        formData.append("currency", "NGN");
        formData.append("is_active", 1);
        formData.append("amount", amount);
        formData.append("owner_id", artisanId.id);
        
        creditArtisan(artisanId.id,dispatch,(formData));
    }

    const handlePageChanges = (pageNum, perPage) => {
        handlePageChange(pageNum, 15, dispatch);
    }

    const handlePerRowsChanges = (perPage, page) => {
        handlePerRowsChange(page, perPage, dispatch);
    }

    const deleteArtisanPop = () => {
        SetDeletePopup(true)
        document.body.style.overflow = 'hidden';
    }

    const creditWalletPop = () => {
        SetCreditWalletPopup(true)
        document.body.style.overflow = 'hidden';
    }

    const hide = () => {
        SetDeletePopup(false);
        SetCreditWalletPopup(false);
        SetFeatureArtisanPopup(false);
        document.body.style.overflow = 'hidden';
    }

    const artisans = useSelector((state) => state.artisans.artisans);
    const data = artisans?.data;
    const totalRows = artisans?.total
    const tableData = { columns, data };
    return (
        <>
            <div id="layoutSidenav">

                <SidebarNavbar />
                <div id="layoutSidenav_content">
                     <main>
                        <div className="container-fluid px-4">
                            <div className="d-flex justify-content-between align-items-baseline">
                                <h5 className="mt-4">Artisan Management</h5>
                                <div className="breadcrumb mb-6">
                                    <div className="breadcrumb mb-2">
                                        <a className="btn btn-xs btn-primary" href="/bulk-artisan">Bulk Upload Artisan</a>&nbsp;&nbsp;
                                    </div>
                                    <div className="breadcrumb mb-2">
                                        <a className="btn btn-xs btn-primary" href="/add-artisan">Add New Artisan</a>
                                    </div>
                                </div>
                            </div>

                            <div className="card mb-4">
                                <div className="card-body">
                                    
                                    <div className="form-floating" style={{ width: "20%", float: "right", marginBottom: "10px", paddingRight:"5px" }}>
                                        <input
                                            className="form-control"
                                            type="text"
                                            onChange={(e) => filterPhone(e.target.value)}/>
                                            
                                        <label htmlFor="inputLastName">Filter</label>
                                    </div>
                                    
                                    <DataTableExtensions {...tableData}>
                                        <DataTable
                                            columns={columns}
                                            data={data}
                                            noHeader
                                            defaultSortAsc={false}
                                            highlightOnHover
                                            // progressPending={orderLoading}
                                            progressComponent={<CustomLoader />}
                                            pagination={true}
                                            paginationServer={true}
                                            paginationResetDefaultPage={true}
                                            export={false}
                                            filterHidden={true}
                                            print={false}
                                            paginationPerPage={15}
                                            paginationTotalRows={totalRows}
                                            paginationRowsPerPageOptions={[15, 30, 45, 60, 75]}
                                            onChangeRowsPerPage={handlePerRowsChanges}
                                            onChangePage={handlePageChanges}
                                            // customStyles={customStyles}
                                        />
                                    </DataTableExtensions>
                                </div>
                            </div>
                        </div>
                    </main>

                    <div>
                        {deleteArtisan && (
                            <div style={{
                                position: "fixed", /* Sit on top of the page content */
                                width: "100%", /* Full width (cover the whole page) */
                                height: "100%", /* Full height (cover the whole page) */
                                top: "0",
                                left: "0",
                                right: "0",
                                bottom: "0",
                                backgroundColor: "rgba(0, 0, 0, 0.7)", /* #333333 background with opacity */
                                zIndex: "1110"
                            }} className="overflow-auto">
                                <div
                                    style={{
                                        height: "100vh",
                                        display: "flex",
                                        alignItems: "center",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                    }}
                                >
                                    <div
                                        style={{
                                            padding: "24px",
                                            gap: "40px",
                                            position: "relative",
                                            width: "22%",
                                            background: "#FFFFFF",
                                            borderRadius: "12px",
                                        }}
                                        className="col-6 col-md-6">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <label htmlFor="email">Are you sure you want to delete the artisan</label>
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-md-12">

                                                <button
                                                    onClick={deleteFunc}
                                                    style={{ fontSize: "14px", width: "35%" }} type="button" className="btn btn-primary btn-sm text-white mx-2 my-2" data-bs-toggle="tooltip" data-bs-placement="top"
                                                    title="500 Naira Charges Apply">
                                                    {/* {isFetching ? (
                                                    <Spinner />
                                                ) : ( */}
                                                    <span>Yes{'  '}</span>
                                                    {/* )} */}
                                                </button>

                                                <button
                                                    onClick={() => hide(false)}
                                                    className="btn btn-danger btn-sm text-white mx-2 my-2"
                                                    style={{ width: "35%", float: "right" }}
                                                >
                                                    <span>No{'  '}</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {creditWallet && (
                            <div style={{
                                position: "fixed", /* Sit on top of the page content */
                                width: "100%", /* Full width (cover the whole page) */
                                height: "100%", /* Full height (cover the whole page) */
                                top: "0",
                                left: "0",
                                right: "0",
                                bottom: "0",
                                backgroundColor: "rgba(0, 0, 0, 0.7)", /* #333333 background with opacity */
                                zIndex: "1110"
                            }} className="overflow-auto">
                                <div
                                    style={{
                                        height: "100vh",
                                        display: "flex",
                                        alignItems: "center",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                    }}
                                >
                                    <div
                                        style={{
                                            padding: "24px",
                                            gap: "40px",
                                            position: "relative",
                                            width: "22%",
                                            background: "#FFFFFF",
                                            borderRadius: "12px",
                                        }}
                                        className="col-6 col-md-6">
                                            <div className="row">
                                                <div className="col-md-2">
                                                    
                                                </div>
                                                <div className="col-md-9">
                                                    <label htmlFor="email"><b>Credit Artisan ({artisanId.first_name+' '+artisanId.last_name}) </b></label>
                                                </div>
                                                <div className="col-md-1">
                                                    
                                                </div>
                                            </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label htmlFor="email">Supply Amount</label>
                                            </div>
                                            <div className="col-md-6">
                                                <input type="hidden" id="user_id" name="user_id" value={artisanId.id}/>
                                                <input
                                                    className="form-control"
                                                    id="inputFirstName"
                                                    type="number"
                                                    name="amount"
                                                    value={artisanId.amount}
                                                    onChange={(e) => setAmount(e.target.value)}
                                                    placeholder="Enter Amount"
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-md-12">

                                            <button
                                                    onClick={() => hide(false)}
                                                    className="btn btn-danger btn-sm text-white mx-2 my-2"
                                                    style={{ fontSize: "14px", width: "35%" }}                                                    
                                                >
                                                    <span>Cancel{'  '}</span>
                                                </button>

                                                <button
                                                    onClick={paymentFunc}
                                                     type="button" 
                                                     style={{ width: "35%", float: "right" }}
                                                    className="btn btn-success btn-sm text-white mx-2 my-2" 
                                                    data-bs-toggle="tooltip" data-bs-placement="top">
                                                    {/* {isFetching ? (
                                                    <Spinner />
                                                ) : ( */}
                                                    <span>Submit{'  '}</span>
                                                    {/* )} */}
                                                </button>

                                               
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                         {featureArtisan && (
                            <div style={{
                                position: "fixed", /* Sit on top of the page content */
                                width: "100%", /* Full width (cover the whole page) */
                                height: "100%", /* Full height (cover the whole page) */
                                top: "0",
                                left: "0",
                                right: "0",
                                bottom: "0",
                                backgroundColor: "rgba(0, 0, 0, 0.7)", /* #333333 background with opacity */
                                zIndex: "1110"
                            }} className="overflow-auto">
                                <div
                                    style={{
                                        height: "100vh",
                                        display: "flex",
                                        alignItems: "center",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                    }}
                                >
                                    <div
                                        style={{
                                            padding: "24px",
                                            gap: "40px",
                                            position: "relative",
                                            width: "22%",
                                            background: "#FFFFFF",
                                            borderRadius: "12px",
                                        }}
                                        className="col-6 col-md-6">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <label htmlFor="email">Are you sure you want to {status==1?'un':''}feature this artisan</label>
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-md-12">

                                                <button
                                                    onClick={FeatureFunc}
                                                    style={{ fontSize: "14px", width: "35%" }} type="button" className="btn btn-primary btn-sm text-white mx-2 my-2" data-bs-toggle="tooltip" data-bs-placement="top"
                                                    title="500 Naira Charges Apply">
                                                    {/* {isFetching ? (
                                                    <Spinner />
                                                ) : ( */}
                                                    <span>Yes{'  '}</span>
                                                    {/* )} */}
                                                </button>

                                                <button
                                                    onClick={() => hide(false)}
                                                    className="btn btn-danger btn-sm text-white mx-2 my-2"
                                                    style={{ width: "35%", float: "right" }}
                                                >
                                                    <span>No{'  '}</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        
                    </div>

                    <Footer />
                </div>


            </div>

        </>
    )
}
export default ArtisanManagement;