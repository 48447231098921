import React, { Component } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from '../../components/Loading';
import { resetFunc } from '../../redux/ApiCalls/authCalls';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

const ResetPassword  = () =>  {
    const [password, setPassword] = useState("");
    const [password_confirmation, setConfirmPassword] = useState("");

    const dispatch = useDispatch()
    const {isFetching, error, currentUser, isSuccess} = useSelector(state=>state.user)
    
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const token = params.get('token');
    const email = params.get('email');
    const handleReset = (e) =>{
        e.preventDefault()
        resetFunc(dispatch,{email,token, password, password_confirmation})
    }
    
        return (
            <>
                <body className="bg-primary">
                    <div id="layoutAuthentication" style={{background: "#dc3545"}}>
                        <div id="layoutAuthentication_content">
                            <main>
                                <div className="container">
                                    <div className="row justify-content-center">
                                        <div className="col-lg-5">
                                            <div className="card shadow-lg border-0 rounded-lg mt-5">
                                                <div className="card-header"><h3
                                                    className="text-center font-weight-light my-4">Reset Password</h3>
                                                </div>
                                                <div className="card-body">
                                                    <div className="small mb-3 text-muted">Enter new password.
                                                    </div>
                                                    <form>
                                                        <div className="form-floating mb-3">
                                                            <input className="form-control" id="inputEmail" type="password"
                                                            onChange={(e)=>setPassword(e.target.value)}
                                                                   placeholder="name@example.com"/>
                                                            <label htmlFor="inputEmail">Password</label>
                                                        </div>
                                                        <div className="form-floating mb-3">
                                                            <input className="form-control"
                                                             id="inputEmail" type="password"
                                                             onChange={(e)=>setConfirmPassword(e.target.value)}
                                                                   placeholder="name@example.com"/>
                                                            <label htmlFor="inputEmail">Confirm Password</label>
                                                        </div>
                                                        <div
                                                        className="d-flex align-items-center justify-content-center mt-4 mb-0">
                                                        {/*<a className="small" href="/forgotpassword">Forgot Password?</a>*/}
                                                        <a style={{ padding:"8px 50px"}} className="btn btn-success" onClick={handleReset}>
                                                            {isFetching ? (
                                                                <Spinner/>
                                                            ) : (
                                                                <span>Reset{'  '}</span>
                                                            )}
                                                        </a>
                                                    </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </main>
                        </div>
                    </div>
                </body>
            </>
        )
    }
export default ResetPassword;