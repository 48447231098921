import React, { Component, useState } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import SignIn from "./routes/Signin";
import ForgotPassword from "./routes/ForgotPassword";
import ResetPassword from "./routes/ResetPassword";
import Dashboard from "./routes/Dashboard";
import Table from "./routes/Table";
import ArtisanManagement from "./routes/ArtisanManagement/index";
import AddArtisanManagement from "./routes/ArtisanManagement/add";
import BulkArtisanManagement from "./routes/ArtisanManagement/bulk";
import ViewArtisanManagement from "./routes/ArtisanManagement/view";
import EditArtisanManagement from "./routes/ArtisanManagement/edit";

import AdminManagement from "./routes/AdminManagement/index";
import AddAdminManagement from "./routes/AdminManagement/add";
import ViewAdminManagement from "./routes/AdminManagement/view";
import EditAdminManagement from "./routes/AdminManagement/edit";

import PostManagement from "./routes/PostManagement/index";
import AddPostManagement from "./routes/PostManagement/add";
import ViewPostManagement from "./routes/PostManagement/view";


import MarketPlace from "./routes/MarketPlace/index";
import AddMarketPlace from "./routes/MarketPlace/add";
import ViewMarketPlace from "./routes/MarketPlace/view";

import ReportManagement from "./routes/ReportManagement/index";

import Users from "./routes/Settings/index";
import ViewUsers from "./routes/Settings/view";
import Transaction from "./routes/Settings/transaction";
import PrivateRoute from "./components/PrivateRoute";
import {useSelector} from "react-redux";
import { Redirect } from 'react-router-dom'
import SuperDashboard from './routes/SuperDashboard';
import EditProfile from './routes/AdminManagement/profile2';
import MarketManagement from './routes/ReportManagement/marketplace';
import Categories from './routes/Category';
import { QueryClient, QueryClientProvider } from 'react-query';

const AppRoute = () => {
    const user = useSelector(state=>state.user.currentUser);
    const [queryClient] = useState(() => new QueryClient())
    return (
        <QueryClientProvider client={queryClient}>
        <React.Fragment>
            <BrowserRouter>
                <Switch>

                    <Route component={user ? Dashboard : SignIn} path="/" exact />
                    <Route component={user ? Dashboard : SignIn} path="/signin" />
                    <Route component={user ? Dashboard : ForgotPassword} path="/forgotpassword" />
                    <Route component={user ? Dashboard : ResetPassword} path="/resetPassword" />

                    <PrivateRoute component={Dashboard} path="/dashboard" />

                    

                    <PrivateRoute component={AddArtisanManagement} path="/add-artisan" />
                    <PrivateRoute component={BulkArtisanManagement} path="/bulk-artisan" />
                    <PrivateRoute component={ViewArtisanManagement} path="/view-artisan/:id" />
                    <PrivateRoute component={EditArtisanManagement} path="/edit-artisan/:id" />
                    <PrivateRoute component={ArtisanManagement} path="/artisan-management" />
                    <PrivateRoute component={Categories} path="/categories" />
                    

                    <Route component={SuperDashboard} path="/s/dashboard" />
                    <Route component={ReportManagement} path="/report" />
                    <Route component={AddAdminManagement} path="/add-admin" />
                    <Route component={ViewAdminManagement} path="/view-admin/:id" />
                    <Route component={EditAdminManagement} path="/Profile" />
                    <Route component={EditProfile} path="/edit-Profile" />
                    <Route component={AdminManagement} path="/admin-accounts" />
                    <Route component={MarketManagement} path="/market-management" />

                    <PrivateRoute component={AddPostManagement} path="/add-post" />
                    <PrivateRoute component={ViewPostManagement} path="/view-post/:id" />
                    <PrivateRoute component={PostManagement} path="/post-management" />

                    


                    <PrivateRoute component={AddMarketPlace} path="/add-marketplace" />
                    <PrivateRoute component={ViewMarketPlace} path="/view-marketplace/:id" />
                    <PrivateRoute component={MarketPlace} path="/marketplace" />
                    <PrivateRoute component={Users} path="/users" />
                    <PrivateRoute component={ViewUsers} path="/view-users/1" />
                    <PrivateRoute component={Transaction} path="/view-transactions/:id" />
                    <PrivateRoute component={Table} path="/table" />
                </Switch>
            </BrowserRouter>

        </React.Fragment>
        </QueryClientProvider>
    );
}
export default AppRoute;

