import { createSlice } from "@reduxjs/toolkit";

export const artisanSlice = createSlice({
    name: "artisan",
    initialState: {
        artisans: [],
        artisan: null,
        isFetching: false,
        error: false,
    },
    reducers: {
        //GET ALL
        getArtisanStart: (state) => {
            state.isFetching = true;
            state.error = false;
        },
        getArtisanSuccess: (state, action) => {
            state.isFetching = false;
            state.artisans = action.payload;
        },
        getArtisanFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },
        //GET Single Artisan
        getSingleArtisanStart: (state) => {
            state.isFetching = true;
            state.error = false;
        },
        getSingleArtisanSuccess: (state, action) => {
            state.isFetching = false;
            state.artisan = action.payload;
        },
        getSingleArtisanFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },
        //DELETE
        deleteArtisanStart: (state) => {
            state.isFetching = true;
            state.error = false;
        },
        deleteArtisanSuccess: (state, action) => {
            state.isFetching = false;
            // state.artisans.splice(
            //   state.artisans.findIndex((item) => item._id === action.payload),
            //   1
            // );
            // state.artisans = {...state.artisans};
        },
        deleteArtisanFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },
        //UPDATE
        updateArtisanStart: (state) => {
            state.isFetching = true;
            state.error = false;
        },
        updateArtisanSuccess: (state, action) => {
            state.isFetching = false;
            state.artisans[
                state.artisans.findIndex((item) => item._id === action.payload.id)
                ] = action.payload.artisan;
        },
        updateArtisanFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },
        //UPDATE
        addArtisanStart: (state) => {
            state.isFetching = true;
            state.error = false;
        },
        addArtisanSuccess: (state, action) => {
            state.isFetching = false;
            state.artisans.push(action.payload);
        },
        addArtisanFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },
    },
});


export const {
    getArtisanStart,
    getArtisanSuccess,
    getArtisanFailure,
    getSingleArtisanStart,
    getSingleArtisanSuccess,
    getSingleArtisanFailure,
    deleteArtisanStart,
    deleteArtisanSuccess,
    deleteArtisanFailure,
    updateArtisanStart,
    updateArtisanSuccess,
    updateArtisanFailure,
    addArtisanStart,
    addArtisanSuccess,
    addArtisanFailure,
} = artisanSlice.actions;

export default artisanSlice.reducer;
