import {publicRequest, userRequest} from "../../requestMethod";
import Notification from "../../utils/notification";
import {
    getDashboardStatFailure,
    getDashboardStatStart,
    getDashboardStatSuccess,
    getTransactionFailure,
    getTransactionStart,
    getTransactionSuccess,
} from "../transactionRedux";



export const getDashboardStat = async (dispatch) => {
    // alert(5)
    dispatch(getDashboardStatStart());
    try {
        const res = await userRequest.get(`/admin/dashboard/total-stats`);
        dispatch(getDashboardStatSuccess(res.data.data));
        // Notification.success(res.data.message);
    } catch (err) {
        if (err && err.response && err.response.status === 403) {
            localStorage.clear();
            window.location.href = "/signin";
        }else{
            dispatch(getDashboardStatFailure());
            Notification.error(err.response.data.message);
        }
    }
};

export const getTransaction = async (dispatch) => {
    // alert(5)
    dispatch(getTransactionStart());
    try {
        // const res = await userRequest.get(type === "wallet"?
        //     `/admin/transactions?filter[payment_purpose]=wallet-topup`
        //     :type === "order"
        //         ?`/admin/transactions?filter[payment_purpose]=callout-charge`
        //     :`/admin/transactions`);
        const res = await userRequest.get(`/admin/transactions`);
        dispatch(getTransactionSuccess(res.data.data.transactions));
        // Notification.success(res.data.message);
    } catch (err) {
        if (err && err.response && err.response.status === 403) {
            localStorage.clear();
            window.location.href = "/signin";
        }else{
            dispatch(getTransactionFailure());
            Notification.error(err.response.data.message);
        }

    }
};


export const getTransactionByUser = async (user_id,dispatch) => {
    // alert(5)
    dispatch(getTransactionStart());
    try {
        // const res = await userRequest.get(type === "wallet"?
        //     `/admin/transactions?filter[payment_purpose]=wallet-topup`
        //     :type === "order"
        //         ?`/admin/transactions?filter[payment_purpose]=callout-charge`
        //     :`/admin/transactions`);
        const res = await userRequest.get(`/admin/transactions/${user_id}`);
        dispatch(getTransactionSuccess(res.data.data.transactions));
        return res.data.data.transactions;
        // Notification.success(res.data.message);
    } catch (err) {
        if (err && err.response && err.response.status === 403) {
            localStorage.clear();
            window.location.href = "/signin";
        }else{
            dispatch(getTransactionFailure());
            Notification.error(err.response.data.message);
        }

    }
};

export const handlePageChange = async (pageNum, perPage, dispatch) => {
    // alert(5)
    dispatch(getTransactionStart());
    try {
        const res = await userRequest.get(`/admin/transactions?page=${pageNum}&limit=${perPage}`);
        dispatch(getTransactionSuccess(res.data.data.transactions));
        // Notification.success(res.data.message);
    } catch (err) {
        dispatch(getTransactionFailure());
        Notification.error(err.response.data.message);
    }
};

export const handlePerRowsChange = async (page, perPage, dispatch) => {
    // alert(5)
    dispatch(getTransactionStart());
    try {
        const res = await userRequest.get(`/admin/transactions?page=${page}&limit=${perPage}`);
        dispatch(getTransactionSuccess(res.data.data.transactions));
        // Notification.success(res.data.message);
    } catch (err) {
        dispatch(getTransactionFailure());
        Notification.error(err.response.data.message);
    }
};
