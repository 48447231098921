import { createSlice } from "@reduxjs/toolkit";

export const productSlice = createSlice({
    name: "product",
    initialState: {
        product: null,
        products: [],
        productGallery: null,
        productGalleries: [],
        isFetching: false,
        error: false,
    },
    reducers: {
        //GET ALL
        getProductStart: (state) => {
            state.isFetching = true;
            state.error = false;
        },
        getProductSuccess: (state, action) => {
            state.isFetching = false;
            state.products = action.payload;
        },
        getProductFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },
        //GET Single product
        getSingleProductStart: (state) => {
            state.isFetching = false;
            state.error = false;
        },
        getSingleProductSuccess: (state, action) => {
            state.isFetching = false;
            state.product = action.payload;
        },
        getSingleProductFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },
        //DELETE
        deleteProductStart: (state) => {
            state.isFetching = true;
            state.error = false;
        },
        deleteProductSuccess: (state, action) => {
            state.isFetching = false;
            // state.products.splice(
            //   state.products.findIndex((item) => item._id === action.payload),
            //   1
            // );
            // state.products = {...state.products};
        },
        deleteProductFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },
        //UPDATE
        updateProductStart: (state) => {
            state.isFetching = true;
            state.error = false;
        },
        updateProductSuccess: (state, action) => {
            state.isFetching = false;
            state.products[
                state.products.findIndex((item) => item._id === action.payload.id)
                ] = action.payload.product;
        },
        updateProductFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },
        //UPDATE
        addProductStart: (state) => {
            state.isFetching = true;
            state.error = false;
        },
        addProductSuccess: (state, action) => {
            state.isFetching = false;
            state.products.push(action.payload);
        },
        addProductFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },





        getProductGalleryStart: (state) => {
            state.isFetching = true;
            state.error = false;
        },
        getProductGallerySuccess: (state, action) => {
            state.isFetching = false;
            state.productGalleries = action.payload;
        },
        getProductGalleryFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },
        //GET Single product
        getSingleProductGalleryStart: (state) => {
            state.isFetching = false;
            state.error = false;
        },
        getSingleProductGallerySuccess: (state, action) => {
            state.isFetching = false;
            state.productGallery = action.payload;
        },
        getSingleProductGalleryFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },
        //DELETE
        deleteProductGalleryStart: (state) => {
            state.isFetching = true;
            state.error = false;
        },
        deleteProductGallerySuccess: (state, action) => {
            state.isFetching = false;
            // state.products.splice(
            //   state.products.findIndex((item) => item._id === action.payload),
            //   1
            // );
            // state.products = {...state.products};
        },
        deleteProductGalleryFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },

        addProductGalleryStart: (state) => {
            state.isFetching = true;
            state.error = false;
        },
        addProductGallerySuccess: (state, action) => {
            state.isFetching = false;
            state.productGalleries.push(action.payload);
        },
        addProductGalleryFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },
    },
});

export const {
    getProductStart,
    getProductSuccess,
    getProductFailure,
    getSingleProductStart,
    getSingleProductSuccess,
    getSingleProductFailure,
    deleteProductStart,
    deleteProductSuccess,
    deleteProductFailure,
    updateProductStart,
    updateProductSuccess,
    updateProductFailure,
    addProductStart,
    addProductSuccess,
    addProductFailure,


    addProductGalleryFailure,
    addProductGalleryStart,
    addProductGallerySuccess,
    getSingleProductGalleryFailure,
    getSingleProductGalleryStart,
    getSingleProductGallerySuccess,
    getProductGalleryFailure,
    getProductGalleryStart,
    getProductGallerySuccess,
    deleteProductGalleryFailure,
    deleteProductGalleryStart,
    deleteProductGallerySuccess,
} = productSlice.actions;

export default productSlice.reducer;
