import React from 'react';
import Spinner from '../../components/Loading'
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from 'react-router-dom';
import {loginFunc} from "../../redux/ApiCalls/authCalls";
import {Link} from "react-router-dom";

const Signin = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const dispatch = useDispatch()
    const {isFetching, error, currentUser, isSuccess} = useSelector(state=>state.user)

    const handleLogin = (e) =>{
        e.preventDefault()
        loginFunc(dispatch,{email, password})
    }
        return (
            <div>
                <body className="bg-primary">
                    <div id="layoutAuthentication" style={{background: "#dc3545"}}>
                    <div id="layoutAuthentication_content">
                        <main>
                            <div className="container">
                                <div className="row justify-content-center">
                                    <div className="col-lg-4">
                                        <div style={{marginTop:"12rem"}} className="card shadow-lg border-0 rounded-lg">
                                            <div className="card-header">
                                                <h4
                                                className="text-center font-weight-light my-4"><i className="fas fa-lock fa-fw"></i> Admin User</h4></div>
                                            <div className="card-body">
                                                <form>
                                                    <div className="form-floating mb-3">
                                                        <input className="form-control" id="inputEmail"
                                                               type="email"
                                                               name="email"
                                                               onChange={(e)=>setEmail(e.target.value)}
                                                               placeholder="name@example.com"/>
                                                        <label htmlFor="inputEmail">Email address</label>
                                                    </div>
                                                    <div className="form-floating mb-3">
                                                        <input className="form-control" id="inputPassword"
                                                               onChange={(e)=>setPassword(e.target.value)}
                                                               type="password"
                                                               placeholder="Password"/>
                                                        <label htmlFor="inputPassword">Password</label>
                                                    </div>
                                                    {/*<div className="form-check mb-3">*/}
                                                    {/*    /!*<input className="form-check-input" id="inputRememberPassword"*!/*/}
                                                    {/*    /!*       type="checkbox" value=""/>*!/*/}
                                                    {/*    /!*<label className="form-check-label"*!/*/}
                                                    {/*    /!*       htmlFor="inputRememberPassword">Remember Password</label>*!/*/}
                                                    {/*</div>*/}
                                                    <div
                                                        className="d-flex align-items-center justify-content-center mt-4 mb-0">
                                                        {/*<a className="small" href="/forgotpassword">Forgot Password?</a>*/}
                                                        <a style={{background:"#dc3545", padding:"8px 50px"}} className="btn btn-primary" onClick={handleLogin}>
                                                            {isFetching ? (
                                                                <Spinner/>
                                                            ) : (
                                                                <span>Sign In{'  '}</span>
                                                            )}
                                                        </a>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </main>
                    </div>
                </div>
                </body>
            </div>
        )
    }
export default Signin;


