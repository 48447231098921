import React, {useEffect, useState} from "react";
import Moment from "react-moment";
import {useDispatch, useSelector} from "react-redux";
import DataTableExtensions from "react-data-table-component-extensions";
import DataTable from "react-data-table-component";
import 'react-data-table-component-extensions/dist/index.css';
import {getDashboardStat, getTransaction} from "../../redux/ApiCalls/transactionSuperCalls";
import SidebarNavbar from "../../components/Sidebar"
import Footer from "../../components/Footer";
import CustomLoader from "../../components/CustomLoader";

const SuperDashboard = () => {
    const dispatch = useDispatch()

    useEffect( () => {
        getTransaction(dispatch)
    }, [dispatch])

    useEffect( () => {
        getDashboardStat(dispatch)
    }, [dispatch])
    const dashboardStats = useSelector((state) => state?.transactions?.dashboard_start);
    const transactions = useSelector((state) => state?.transactions?.transactions);

    const [columns, setColumns] = useState([
        {
            name: 'Payment Gateway',
            selector: 'payment_gateway',
            sortable: true,
        },
        {
            name: 'Payment Method',
            selector: 'payment_method',
            sortable: true,
        },
        {
            name: 'Payment Purpose',
            selector: 'payment_purpose',
            sortable: true,
        },
        {
            name: ' Date Created',
            selector: 'created_at',
            sortable: true,
            cell: row => row.created_at &&
                <div>
                    <Moment format="D MMM YYYY">{row.created_at}</Moment>
                </div>
        },
            {
                name: 'Status',
                selector: 'payment_status',
                sortable: true,
                cell: row => row.payment_status ?
                    <div>
                        <span className="btn btn-sm btn-success text-white">success</span>
                    </div>
                    :
                    <div>
                        <span className="btn btn-sm btn-danger text-white">fail</span>
                    </div>
            },
    ],
    )



    const data = transactions?.data;
    const totalRows = transactions?.total;
    const tableData = { columns, data };
    return (
        <>
            <div id="layoutSidenav">

                <SidebarNavbar />
                <div id="layoutSidenav_content">
                    <main>
                        <div className="container-fluid px-4">
                            <h5 className="mt-4">Dashboard</h5>

                            <div className="row mb-4">
                                <div className="col-xl-3 col-md-6">
                                    <div className="card bg-default mb-4">
                                        <div className="d-flex justify-content-between lh-lg py-3 px-3">
                                            <div
                                                className="border me-3 p-2"
                                                style={{borderRadius: '50%', width: 50, height: 50, textAlign: 'center', background:"#dc3545"}}
                                            >
                                                <i className="fas fa-tachometer-alt text-white"></i>
                                            </div>

                                            <div>
                                                <p className="mb-2">Total Products</p>
                                                <h5 className="text-start fw-medium"> {dashboardStats?.total_product ? dashboardStats?.total_product : 0}</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-md-6">
                                    <div className="card bg-default mb-4">
                                        <div className="d-flex justify-content-between lh-lg py-3 px-3">
                                            <div
                                                className="border me-3 p-2"
                                                style={{borderRadius: '50%', width: 50, height: 50, textAlign: 'center', background:"blue"}}
                                            >
                                                <i className="fas fa-table-columns text-white"></i>
                                            </div>

                                            <div>
                                                <p className="mb-2">Total Artisans</p>
                                                <h5 className="text-start fw-medium"> {dashboardStats?.total_artisan ? dashboardStats?.total_artisan : 0}</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-md-6">
                                    <div className="card bg-default mb-4">
                                        <div className="d-flex justify-content-between lh-lg py-3 px-3">
                                            <div
                                                className="border me-3 p-2"
                                                style={{borderRadius: '50%', width: 50, height: 50, textAlign: 'center', background:"green"}}
                                            >
                                                <i className="fas fa-table text-white"></i>
                                            </div>

                                            <div>
                                                <p className="mb-2">Total Admins</p>
                                                <h5 className="text-start fw-medium"> {dashboardStats?.total_admin ? dashboardStats?.total_admin : 0}</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-md-6">
                                    <div className="card bg-default mb-4">
                                        <div className="d-flex justify-content-between lh-lg py-3 px-3">
                                            <div
                                                className="border me-3 p-2"
                                                style={{borderRadius: '50%', width: 50, height: 50, textAlign: 'center', background:"darkorange"}}
                                            >
                                                <i className="fas fa-table-columns text-white"></i>
                                            </div>

                                            <div>
                                                <p className="mb-2">Total Orders</p>
                                                <h5 className="text-start fw-medium"> {dashboardStats?.total_order ? dashboardStats?.total_order : 0}</h5>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-xl-3 col-md-6">
                                    <div className="card bg-default mb-4">
                                        <div className="d-flex justify-content-between lh-lg py-3 px-3">
                                            <div
                                                className="border me-3 p-2"
                                                style={{borderRadius: '50%', width: 50, height: 50, textAlign: 'center', background:"lightblue"}}
                                            >
                                                <i className="fas fa-tachometer-alt text-white"></i>
                                            </div>

                                            <div>
                                                <p className="mb-2">Total Credit Balance</p>
                                                <h5 className="text-start fw-medium"> {dashboardStats?.credit_balance ? dashboardStats?.credit_balance : 0}</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-md-6">
                                    <div className="card bg-default mb-4">
                                        <div className="d-flex justify-content-between lh-lg py-3 px-3">
                                            <div
                                                className="border me-3 p-2"
                                                style={{borderRadius: '50%', width: 50, height: 50, textAlign: 'center', background:"black"}}
                                            >
                                                <i className="fas fa-chart-area text-white"></i>
                                            </div>

                                            <div>
                                                <p className="mb-2">Total Top-up</p>
                                                <h5 className="text-start fw-medium"> {dashboardStats?.top_up ? dashboardStats?.top_up : 0}</h5>
                                            </div>
                                        </div>
                                    </div>
                                   </div>
                                <div className="col-xl-3 col-md-6">
                                    <div className="card bg-default mb-4">
                                        <div className="d-flex justify-content-between lh-lg py-3 px-3">
                                            <div
                                                className="border me-3 p-2"
                                                style={{borderRadius: '50%', width: 50, height: 50, textAlign: 'center', background:"purple"}}
                                            >
                                                <i className="fas fa-table text-white"></i>
                                            </div>

                                            <div>
                                                <p className="mb-2">Total Callout Fees</p>
                                                <h5 className="text-start fw-medium"> {dashboardStats?.call_out_charge ? dashboardStats?.call_out_charge : 0}</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-md-6">
                                    <div className="card bg-default mb-4">
                                        <div className="d-flex justify-content-between lh-lg py-3 px-3">
                                            <div
                                                className="border me-3 p-2"
                                                style={{borderRadius: '50%', width: 50, height: 50, textAlign: 'center', background:"greenyellow"}}
                                            >
                                                <i className="fas fa-chart-area text-white"></i>
                                            </div>

                                            <div>
                                                <p className="mb-2">Total Marketplace Transactions</p>
                                                <h5 className="text-start fw-medium"> {dashboardStats?.market_palce ? dashboardStats?.market_place : 0}</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                             <div className="card mb-4">
                                <div className="card-body">
                                    <DataTableExtensions {...tableData}>
                                        <DataTable
                                            columns={columns}
                                            data={data}
                                            noHeader
                                            defaultSortAsc={false}
                                            highlightOnHover
                                            // progressPending={orderLoading}
                                            progressComponent={<CustomLoader />}
                                            pagination={true}
                                            paginationServer={true}
                                            paginationResetDefaultPage={true}
                                            export={false}
                                            paginationPerPage={15}
                                            paginationTotalRows={totalRows}
                                            paginationRowsPerPageOptions={[15, 30, 45, 60, 75]}
                                        // onChangeRowsPerPage={handlePerRowsChanges}
                                        // onChangePage={handlePageChanges}
                                        />
                                    </DataTableExtensions>
                                </div>
                            </div>

                        </div>
                    </main>
                    <Footer />
                </div>
            </div>
        </>
    )
}
export default SuperDashboard;