import React, {useEffect, useState} from "react";
import Moment from "react-moment";
import {useDispatch, useSelector} from "react-redux";
import DataTableExtensions from "react-data-table-component-extensions";
import DataTable from "react-data-table-component";
import 'react-data-table-component-extensions/dist/index.css';
import SidebarNavbar from "../../components/Sidebar"
import Footer from "../../components/Footer";
import CustomLoader from "../../components/CustomLoader";
import {getTransactionByUser} from "../../redux/ApiCalls/transactionCalls";
import NumberFormat from "react-number-format";
import { useLocation } from "react-router-dom";

const Transaction = () => {
    const [suspendUser, SetSuspendPopup] = useState(false);
    const [userId, setUserId] = useState(null);
    const hide = () => {
        SetSuspendPopup(false)
        document.body.style.overflow = 'hidden';
    }
    const SuspendUserPop = () => {
        SetSuspendPopup(true)
        document.body.style.overflow = 'hidden';
    }
    const locationpath = useLocation();
    const id = locationpath.pathname.split('/')[2];
    const dispatch = useDispatch();
    const [users, setData] = useState([]);

    useEffect( () => {
        getTransactionByUser(id, dispatch).then(res =>{ 
            console.log(res)             ;
            setData(res) ;
            });
        // getTransactionByUser(id, dispatch)
    }, [dispatch])
    // const users = useSelector((state) => state?.user?.users);

    const [columns, setColumns] = useState([
         
            {
                name: 'Payment Gateway',
                selector: 'payment_gateway',
                sortable: true,
            },
            {
                name: 'Payment Purpose',
                selector: 'payment_purpose',
                sortable: true,
            },
            {
                name: 'Amount',
                selector: 'amount',
                sortable: true,
                cell: row => row?.amount &&
                    <div>
                        ₦<NumberFormat value={(row?.amount)} displayType={'text'} thousandSeparator={true} />
                    </div>
            },
            {
                name: ' Date Created',
                selector: 'created_at',
                sortable: true,
                cell: row => row?.created_at &&
                    <div>
                        <Moment format="D MMM YYYY">{row?.created_at}</Moment>
                    </div>
            }
        ],
    )



    const data = users?.data;
    const totalRows = users?.total;
    const tableData = { columns, data };
    return (
            <>
                <div id="layoutSidenav">

                    <SidebarNavbar/>
                    <div id="layoutSidenav_content">
                        <main>
                            <div className="container-fluid px-4">
                              <div className="flex-display align-items-baseline">
                                  <h4 className="mt-4">Transaction Management</h4>
                              </div>

                                <div className="card mb-4">
                                    <div className="card-body">
                                        <DataTableExtensions {...tableData}>
                                            <DataTable
                                                columns={columns}
                                                data={data}
                                                noHeader
                                                defaultSortAsc={false}
                                                highlightOnHover
                                                // progressPending={orderLoading}
                                                progressComponent={<CustomLoader />}
                                                pagination={true}
                                                paginationServer={true}
                                                paginationResetDefaultPage={true}
                                                export={false}
                                                paginationPerPage={15}
                                                paginationTotalRows={totalRows}
                                                paginationRowsPerPageOptions={[15, 30, 45, 60, 75]}
                                                // onChangeRowsPerPage={handlePerRowsChanges}
                                                // onChangePage={handlePageChanges}
                                            />
                                        </DataTableExtensions>
                                    </div>
                                </div>
                            </div>
                        </main>
                        
                        
                        <Footer/>
                    </div>
                </div>
            </>
        )
    }

export default Transaction;