import { createSlice } from "@reduxjs/toolkit";

export const associationSlice = createSlice({
    name: "Association",
    initialState: {
        associations: [],
        isFetching: false,
        error: false,
    },
    reducers: {
        //GET ALL
        getAssociationStart: (state) => {
            state.isFetching = true;
            state.error = false;
        },
        getAssociationSuccess: (state, action) => {
            state.isFetching = false;
            state.associations = action.payload;
        },
        getAssociationFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },
        //DELETE
        deleteAssociationStart: (state) => {
            state.isFetching = true;
            state.error = false;
        },
        deleteAssociationSuccess: (state, action) => {
            state.isFetching = false;
            // state.associations.splice(
            //   state.associations.findIndex((item) => item._id === action.payload),
            //   1
            // );
            // state.associations = {...state.associations};
        },
        deleteAssociationFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },
        //UPDATE
        updateAssociationStart: (state) => {
            state.isFetching = true;
            state.error = false;
        },
        updateAssociationSuccess: (state, action) => {
            state.isFetching = false;
            state.associations[
                state.associations.findIndex((item) => item._id === action.payload.id)
                ] = action.payload.association;
        },
        updateAssociationFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },
        //UPDATE
        addAssociationStart: (state) => {
            state.isFetching = true;
            state.error = false;
        },
        addAssociationSuccess: (state, action) => {
            state.isFetching = false;
            state.associations.push(action.payload);
        },
        addAssociationFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },
    },
});

export const {
    getAssociationStart,
    getAssociationSuccess,
    getAssociationFailure,
    deleteAssociationStart,
    deleteAssociationSuccess,
    deleteAssociationFailure,
    updateAssociationStart,
    updateAssociationSuccess,
    updateAssociationFailure,
    addAssociationStart,
    addAssociationSuccess,
    addAssociationFailure,
} = associationSlice.actions;

export default associationSlice.reducer;
