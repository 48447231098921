import { publicRequest, userRequest } from "../../requestMethod";
import Notification from "../../utils/notification";
import {
    getSingleProductFailure,
    getSingleProductStart,
    getSingleProductSuccess,
    getProductFailure,
    getProductStart,
    getProductSuccess,
    deleteProductFailure,
    deleteProductStart,
    deleteProductSuccess,
    updateProductFailure,
    updateProductStart,
    updateProductSuccess,
    addProductFailure,
    addProductStart,
    addProductSuccess,


    addProductGalleryFailure,
    addProductGalleryStart,
    addProductGallerySuccess,
    getSingleProductGalleryFailure,
    getSingleProductGalleryStart,
    getSingleProductGallerySuccess,
    getProductGalleryFailure,
    getProductGalleryStart,
    getProductGallerySuccess,
    deleteProductGalleryFailure,
    deleteProductGalleryStart,
    deleteProductGallerySuccess,
} from "../productRedux";

//

export const addProduct = async (dispatch, product) => {
    dispatch(addProductStart());
    try {
        // const res = await userRequest.post(`/products`, Artisan);
        const res = await userRequest.post(`/admin/products`, product);
        // console.log("post data", res.data.data)
        // dispatch(addProductSuccess(res.data.data));
        Notification.success(res.data.message);
        window.location.href = "/marketplace"
        // window.location.reload();
    } catch (err) {
        if (err && err.response && err.response.status === 403) {
            localStorage.clear();
            window.location.href = "/signin";
        }else{
            dispatch(addProductFailure());
            Notification.error(err?.response?.data?.message);
        }
    }
};

export const getProducts = async (name,dispatch) => {
    // alert(5)
    dispatch(getProductStart());
    try {
        const res = await userRequest.get(`/admin/products?include=artisan&product_name=${name}`);
        dispatch(getProductSuccess(res.data.data.products));
        // Notification.success(res.data.message);
    } catch (err) {
        if (err && err.response && err.response.status === 403) {
            localStorage.clear();
            window.location.href = "/signin";
        }else{
            dispatch(getProductFailure());
            Notification.error(err.response.data.message);
        }
    }
};

export const getProduct = async (id, dispatch) => {
    // alert(5)
    dispatch(getSingleProductStart());
    try {
        const res = await userRequest.get(`/admin/products/${id}`);
        dispatch(getSingleProductSuccess(res.data.data.product));
        // Notification.success(res.data.message);
    } catch (err) {
        if (err && err.response && err.response.status === 403) {
            localStorage.clear();
            window.location.href = "/signin";
        }else{
            dispatch(getSingleProductFailure());
            Notification.error(err.response.data.message);
        }
    }
};

export const updateProduct = async (id, product, dispatch) => {
    dispatch(updateProductStart());
    try {
        const res = await userRequest.post(`/admin/products/${id}`, product);
        // dispatch(updateProductSuccess(res.data.data.product));
        Notification.success(res.data.message);
        window.location.href = "/marketplace";
    } catch (err) {
        if (err && err.response && err.response.status === 403) {
            localStorage.clear();
            window.location.href = "/signin";
        }else{
            dispatch(updateProductFailure());
            Notification.error(err.response.data.message);
        }
    }
};

//
export const deleteProductFunc = async (id, dispatch) => {
    dispatch(deleteProductStart());
    try {
        const res = await userRequest.delete(`/admin/products/${id}`);
        dispatch(deleteProductSuccess(id));
        Notification.success(res.data.message);
        window.location.reload();
    } catch (err) {
        if (err && err.response && err.response.status === 403) {
            localStorage.clear();
            window.location.href = "/signin";
        }else{
            dispatch(deleteProductFailure());
            Notification.error(err.response.data.message);
        }
    }
};


export const addProductGalley = async (dispatch, productId, data) => {
    dispatch(addProductGalleryStart());
    try {
        // const res = await userRequest.post(`/products`, Artisan);
        const res = await userRequest.post(`/admin/products/${productId}/galleries`, data);
        // console.log("post data", res.data.data)
        // dispatch(addProductGallerySuccess(res.data.data));
        Notification.success(res.data.message);
        window.location.href = "/marketplace"
        // window.location.reload();
    } catch (err) {
        if (err && err.response && err.response.status === 403) {
            localStorage.clear();
            window.location.href = "/signin";
        }else{
            dispatch(addProductGalleryFailure());
            Notification.error(err?.response?.data?.message);
        }
    }
};

export const getProductsGallery = async (dispatch, productId) => {
    // alert(5)
    dispatch(getProductGalleryStart());
    try {
        const res = await userRequest.get(`/admin/products/${productId}/galleries`);
        dispatch(getProductGallerySuccess(res.data.data.products));
        // Notification.success(res.data.message);
    } catch (err) {
        if (err && err.response && err.response.status === 403) {
            localStorage.clear();
            window.location.href = "/signin";
        }else{
            dispatch(getProductGalleryFailure());
            Notification.error(err.response.data.message);
        }
    }
};

export const getProductGallery = async (productId,galleryId, dispatch) => {
    // alert(5)
    dispatch(getSingleProductGalleryStart());
    try {
        const res = await userRequest.get(`/admin/products/${productId}/galleries/${galleryId}`);
        dispatch(getSingleProductGallerySuccess(res.data.data.product));
        // Notification.success(res.data.message);
    } catch (err) {
        if (err && err.response && err.response.status === 403) {
            localStorage.clear();
            window.location.href = "/signin";
        }else{
            dispatch(getSingleProductGalleryFailure());
            Notification.error(err.response.data.message);
        }
    }
};

//
export const deleteProductGalleryFunc = async (productId,galleryId, dispatch) => {
    dispatch(deleteProductGalleryStart());
    try {
        const res = await userRequest.delete(`/admin/products/${productId}/galleries/${galleryId}`);
        dispatch(deleteProductGallerySuccess(galleryId));
        Notification.success(res.data.message);
        window.location.reload();
    } catch (err) {
        if (err && err.response && err.response.status === 403) {
            localStorage.clear();
            window.location.href = "/signin";
        }else{
            dispatch(deleteProductGalleryFailure());
            Notification.error(err.response.data.message);
        }
    }
};

export const handlePageChange = async (pageNum, perPage, dispatch) => {
    // alert(5)
    dispatch(getProductStart());
    try {
        const res = await userRequest.get(`/admin/products?page=${pageNum}&limit=${perPage}`);
        dispatch(getProductSuccess(res.data.data.products));
        // Notification.success(res.data.message);
    } catch (err) {
        dispatch(getProductFailure());
        Notification.error(err.response.data.message);
    }
};

export const handlePerRowsChange = async (page, perPage, dispatch) => {
    // alert(5)
    dispatch(getProductStart());
    try {
        const res = await userRequest.get(`/admin/products?page=${page}&limit=${perPage}`);
        dispatch(getProductSuccess(res.data.data.products));
        // Notification.success(res.data.message);
    } catch (err) {
        dispatch(getProductFailure());
        Notification.error(err.response.data.message);
    }
};
