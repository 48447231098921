import React, { useEffect, useState } from "react";
import SidebarNavbar from "../../components/Sidebar"
import Footer from "../../components/Footer";
import Spinner from '../../components/Loading';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { getAdmin, updateAdmin } from "../../redux/ApiCalls/superadminCalls";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { getBank, updateBank } from "../../redux/ApiCalls/bankCalls";
import { getCategories, getStates, getCities } from "../../redux/ApiCalls/categoryCalls";
import { getAssociation } from "../../redux/ApiCalls/associationCalls";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

const Edit = () => {
    const dispatch = useDispatch()
    const location = useLocation();
    
    const user= JSON.parse(localStorage.getItem('user'));
    const id= user.admin.id;
    useEffect(() => {
        getAdmin(id, dispatch).then(res=>{
            setArtisan(res);
        });
    }, [dispatch])

    useEffect(() => {
           
    }, [dispatch]);

    

    const [selectedOptions, setSelectedOptions] = useState();
    
    const [first_name, setFirstname] = useState("")
    const [phone, setPhone] = useState("")
    const [designation, setDesignation] = useState("")
    const [artisan, setArtisan] = useState("")
    const [last_name, setLastname] = useState("")
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
   
    
    
   
    // const artisan = useSelector((state) => state.artisan.artisan);

    
    const { isFetching, error, isSuccess } = useSelector(state => state.artisans)

   
    const updateAdminFunc = (e) =>{
        e.preventDefault()        

        const data = {
            "first_name": first_name ? first_name : artisan?.first_name,
            "last_name": last_name ? last_name : artisan?.last_name,
            "phone": phone ? phone : artisan?.phone,
            "email": email ? email : artisan?.email,
            "role": designation
        };
    
        updateAdmin(id,(data),dispatch)
    }
    

   
    return (
        <>
            <div id="layoutSidenav">

                <SidebarNavbar />
                <div id="layoutSidenav_content">
                    <main>
                        <div className="container px-2">
                            
                            <div className="row justify-content-center">
                                <div className="col-lg-12">

                                    <div className="card shadow-lg border-0 rounded-lg mt-5">
                                        <div className="card-body">
                                            <div className="card-header">
                                                <h5 className="mt-5 font-weight-light arts">Profile</h5>
                                            </div>
                                            <form>
                                                <div className="row mb-3">
                                                    <div className="col-md-4">
                                                        <div className="form-floating mb-3 mb-md-0">
                                                            <input
                                                                className="form-control"
                                                                id="first_name"
                                                                type="text"
                                                                name="first_name"
                                                                defaultValue={artisan?.first_name}
                                                                onChange={(e) => setFirstname(e.target.value)}
                                                                placeholder="Enter first name"
                                                                required
                                                            />
                                                            <label htmlFor="inputFirstName">First name</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-floating">
                                                            <input
                                                                className="form-control"
                                                                id="inputLastName"
                                                                type="text"
                                                                name="last_name"
                                                                defaultValue={artisan?.last_name}
                                                                onChange={(e) => setLastname(e.target.value)}
                                                                placeholder="Enter last name"
                                                                required
                                                            />
                                                            <label htmlFor="inputLastName">Last name</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-floating">
                                                            <input
                                                                className="form-control"
                                                                id="email"
                                                                type="text"
                                                                name="email"
                                                                defaultValue={artisan?.email}
                                                                onChange={(e) => setEmail(e.target.value)}
                                                                placeholder="Enter Email Address"
                                                                readOnly
                                                            /> 
                                                            <label htmlFor="email">Email Address</label>
                                                        </div>
                                                    </div>
                                                </div>

                                                
                                                <div className="row mb-3">
                                                    
                                                    <div className="col-md-4">
                                                        

                                                        <div className="form-floating mb-3 mb-md-0">
                                                            <PhoneInput
                                                                placeholder="08012345678"
                                                                // onInput={(e) => e.target.value = e.target.value.slice(0, 11)} // js validation to check that number does not exceed 11 characters
                                                                value={phone}
                                                                country={'ng'}
                                                                enableSearch={true}
                                                                countryCodeEditable={false}
                                                                defaultValue={artisan?.phone}
                                                                inputProps={{ name: 'phone', required: true, autoFocus: true, maxLength: "17" }}
                                                                onChange={setPhone}
                                                                style={{ width: "100%" }}
                                                            />
                                                            
                                                        </div>
                                                    </div>

                                                    <div className="col-md-4">
                                                        

                                                        
                                                    </div>
                                                </div>

                                                <div className="col-md-4 mt-4 mb-0">
                                                    <div className="d-grid">
                                                        <a onClick={updateAdminFunc} className="btn btn-primary btn-block">
                                                            {isFetching ? (
                                                                <Spinner />
                                                            ) : (
                                                                <span>Update{'  '}</span>
                                                            )}
                                                        </a>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                    <Footer />
                </div>
            </div>
        </>
    )
}
export default Edit;