import { createSlice } from "@reduxjs/toolkit";

export const postSlice = createSlice({
    name: "post",
    initialState: {
        posts: [],
        post: null,
        isFetching: false,
        error: false,
    },
    reducers: {
        //GET ALL
        getPostStart: (state) => {
            state.isFetching = true;
            state.error = false;
        },
        getPostSuccess: (state, action) => {
            state.isFetching = false;
            state.posts = action.payload;
        },
        getPostFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },
        //GET Single Artisan
        getSinglePostStart: (state) => {
            state.isFetching = true;
            state.error = false;
        },
        getSinglePostSuccess: (state, action) => {
            state.isFetching = false;
            state.post = action.payload;
        },
        getSinglePostFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },
        //DELETE
        deletePostStart: (state) => {
            state.isFetching = true;
            state.error = false;
        },
        deletePostSuccess: (state, action) => {
            state.isFetching = false;
            // state.artisans.splice(
            //   state.artisans.findIndex((item) => item._id === action.payload),
            //   1
            // );
            // state.artisans = {...state.artisans};
        },
        deletePostFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },
        //UPDATE
        updatePostStart: (state) => {
            state.isFetching = true;
            state.error = false;
        },
        updatePostSuccess: (state, action) => {
            state.isFetching = false;
            state.artisans[
                state.artisans.findIndex((item) => item._id === action.payload.id)
                ] = action.payload.artisan;
        },
        updatePostFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },
        //UPDATE
        addPostStart: (state) => {
            state.isFetching = true;
            state.error = false;
        },
        addPostSuccess: (state, action) => {
            state.isFetching = false;
            state.artisans.push(action.payload);
        },
        addPostFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },
    },
});


export const {
    getPostStart,
    getPostSuccess,
    getPostFailure,
    getSinglePostStart,
    getSinglePostSuccess,
    getSinglePostFailure,
    deletePostStart,
    deletePostSuccess,
    deletePostFailure,
    updatePostStart,
    updatePostSuccess,
    updatePostFailure,
    addPostStart,
    addPostSuccess,
    addPostFailure,
} = postSlice.actions;

export default postSlice.reducer;
