import React, { useState } from 'react';
import SidebarNavbar from "../../components/Sidebar"
import Footer from "../../components/Footer";
import CustomLoader from "../../components/CustomLoader";
import DataTableExtensions from "react-data-table-component-extensions";
import DataTable from "react-data-table-component";
import 'react-data-table-component-extensions/dist/index.css';
import Moment from "react-moment";
import { getAdverts } from '../../redux/ApiCalls/advertCalls';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

const ArtisanManagement = () => {
    const dispatch = useDispatch()


    useEffect(() => {
        getAdverts(dispatch, '')
    }, [dispatch])
    const [columns, setColumns] = useState([
        {
            name: 'Name',
            selector: 'name',
            sortable: true,
        },
        {
            name: 'Amount',
            selector: 'amount',
            sortable: true,
        },
        {
            name: 'Description',
            selector: 'description',
            sortable: true,
        },
        {
            name: 'Created',
            selector: 'created_at',
            sortable: true,
        },
        {
            name: 'Action',
            selector: 'created_at',
            sortable: true,
            cell: row => row.created_at &&
                <div>
                    <button
                        type="submit"
                        className="btn btn-xs btn-danger text-white" >delete</button>
                </div>
        }
    ],
    )
 
    const testData = [
        {
            name: 'Monyt',
            amount: '5000',
            description: 'This is bettaee product',
            created_at: '19-02-2022',
        },
        {
            name: 'ALUSCOTER',
            amount: '3000',
            description: 'This is bettaee product',
            created_at: '19-02-2022',
        },
        {
            name: 'Bomster',
            amount: '75000',
            description: 'This is bettaee product',
            created_at: '19-02-2022',
        },
    ]



    const data = testData;
    const totalRows = 3;
    const tableData = { columns, data };
    return (
        <>
            <div id="layoutSidenav">

                <SidebarNavbar />
                <div id="layoutSidenav_content">
                    <main>
                        <div className="container-fluid px-4">
                            <div className="d-flex justify-content-between align-items-baseline">
                                <h4 className="mt-4">Advert Management</h4>
                                <div className="breadcrumb mb-4">
                                    {/*<a className="btn btn-xs btn-primary" href="/add-artisan">Add New Artisan</a>*/}
                                </div>
                            </div>
                            <div className="card mb-4">
                                <div className="card-body">
                                    <DataTableExtensions {...tableData}>
                                        <DataTable
                                            columns={columns}
                                            data={data}
                                            noHeader
                                            defaultSortAsc={false}
                                            highlightOnHover
                                            // progressPending={orderLoading}
                                            progressComponent={<CustomLoader />}
                                            pagination={true}
                                            paginationServer={true}
                                            paginationResetDefaultPage={true}
                                            export={false}
                                            paginationPerPage={15}
                                            paginationTotalRows={totalRows}
                                            paginationRowsPerPageOptions={[15, 30, 45, 60, 75]}
                                        // onChangeRowsPerPage={handlePerRowsChanges}
                                        // onChangePage={handlePageChanges}
                                        />
                                    </DataTableExtensions>
                                </div>
                            </div>

                        </div>
                    </main> <Footer />
                </div>
            </div>
        </>
    )
}
export default ArtisanManagement;



