import React, {ChangeEvent, useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import SidebarNavbar from "../../components/Sidebar"
import Footer from "../../components/Footer";
import Notification from "../../utils/notification";
import {addProduct} from "../../redux/ApiCalls/productCalls";
import {getArtisans} from "../../redux/ApiCalls/artisanCalls";
import {getCategories} from "../../redux/ApiCalls/categoryCalls";
import Spinner from "../../components/Loading";

const Add = ({location}) => {
    const [artisan_id, setArtisan] = useState("")
    const [category_id, setCategory] = useState("")
    const [name, setName] = useState("")
    const [description, setDescription] = useState("")
    const [amount, setAmount] = useState("")
    const [featured_image, setFeatureImage] = useState("")
    const [fileList, setFileList] = useState(null);
    const [fileLimit, setFileLimit] = useState(false);
    const [is_active, setActive] = useState(1)
    const [is_verified, setVerified] = useState(0)
    const [start_date, setStartDate] = useState(null)
    const [finish_date, setFinishDate] = useState(null)
    const [deliverydesc, setDeliverydesc] = useState("")
    const [deliveryrange, setDeliveryrange] = useState("")
    const [categories, setCategories] =useState({})
    const [artisans, setArtisans] =useState({})
    const dispatch = useDispatch()
    useEffect(() => {
        getCategories(dispatch).then(res=>{
            setCategories(res);
        });
    }, [dispatch]);
 
    useEffect(() => {
        getArtisans(dispatch, '').then(res=>{
            setArtisans(res);
        });
    }, [dispatch]);

    // const categories = useSelector((state) => state.categories.categories);
    //const artisans = useSelector((state) => state.artisans.artisans);
    const {isFetching, error, isSuccess} = useSelector(state=>state.products)
    const handleImage = (e) => {
        const reader = new FileReader();
        reader.readAsDataURL(e);
        setFeatureImage(e);
        reader.onload = function () {
        };
    };
    const HandleFileListUpload = (e: ChangeEvent<HTMLInputElement>) => {
        let limitExceeded=false;
        if(e.target.files.length>=3 && e.target.files.length<=5) setFileLimit(false)
        else {
            setFileLimit(true);
            Notification.error("Minimum of 3 or maximum of 5 files is required");
            return true;
        }
        setFileList(e.target.files);
      };
    
      const files = fileList ? [...fileList] : [];
    const createProduct = (e) =>{
        e.preventDefault();
        if(!fileLimit)
        {
            const formData = new FormData();
            formData.append("artisan_id", artisan_id);
            formData.append("category_id", category_id);
            formData.append("name", name);
            formData.append("description", description);
            formData.append("amount", amount);
            files.forEach((file, i) => {
                formData.append(`featured_image[${i}]`,  file);
            });
            formData.append("is_active", is_active);
            formData.append("is_verified", is_verified);
            formData.append("start_date", start_date.slice(0,10));
            formData.append("finish_date", finish_date.slice(0,10));
            formData.append("delivery_range", deliveryrange );
            formData.append("delivery_desc", deliverydesc);

            addProduct(dispatch,(formData))
        }
        else{
            Notification.error("Minimum of 3 or maximum of 5 files is required");
        }
        // addProduct(dispatch,{
        //     artisan_id,
        //     category_id,
        //     name,
        //     description,
        //     amount,
        //     featured_image,
        //     is_active,
        //     is_verified
        // })
    }


    return (
            <>
                <div id="layoutSidenav">

                    <SidebarNavbar/>
                    <div id="layoutSidenav_content">
                        <main>
                            <div className="container px-2">
                                <div className="d-flex justify-content-between align-items-baseline">
                                    <h4 className="mt-4">Marketplace</h4>
                                    <div className="breadcrumb mb-4">
                                        <a className="btn btn-xs btn-primary" href="/marketplace">List Marketplace</a>
                                    </div>
                                </div>
                                 <div className="row justify-content-center">
                                        <div className="col-lg-12">
                                            <div className="card shadow-lg border-0 rounded-lg mt-5">
                                                <div className="card-header">
                                                    <h5 className=" my-4">Create MarketPlace</h5>
                                                </div>
                                                <div className="card-body">
                                                    <form>
                                                        <div className="row mb-5">
                                                            <div className="col-md-6">
                                                                <div className="form-floating">
                                                                    <select
                                                                        className="form-control"
                                                                        name="artisan_id"
                                                                        onChange={(e)=>setArtisan(e.target.value)}
                                                                        id="artisan_id">
                                                                        <option>Select Artisan</option>
                                                                        {artisans?.data?.map((item, idx) =>
                                                                            <option key={idx} value={item.id}>{item.first_name} {" "} {item.last_name}</option>
                                                                        )}
                                                                    </select>
                                                                    <label htmlFor="inputLastName">Artisan</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="form-floating mb-3 mb-md-0">
                                                                    <input className="form-control"
                                                                           id="inputFirstName"
                                                                           type="text"
                                                                           name="name"
                                                                           onChange={(e)=>setName(e.target.value)}
                                                                           placeholder="Enter product name"/>
                                                                    <label htmlFor="inputFirstName">Product Name</label>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="row mb-5 mt-4">

                                                            <div className="col-md-6">
                                                                <div className="form-floating">
                                                                    <select
                                                                        className="form-control"
                                                                        name="category_id"
                                                                        onChange={(e)=>setCategory(e.target.value)}
                                                                        id="category_id">
                                                                        <option>Select Category</option>
                                                                        {categories?.data?.map((item, idx) =>
                                                                            <option key={idx} value={item.id}>{item.name}</option>
                                                                        )}
                                                                    </select>
                                                                    <label htmlFor="inputLastName">Category</label>
                                                                </div>
                                                            </div>

                                                            <div className="col-md-6">
                                                                <div className="form-floating mb-3 mb-md-0">
                                                                    <input className="form-control" id="inputFirstName"
                                                                           type="text"
                                                                           name="description"
                                                                           onChange={(e)=>setDescription(e.target.value)}
                                                                           placeholder="Enter description"/>
                                                                    <label htmlFor="inputFirstName">Description</label>
                                                                </div>
                                                            </div>

                                                        </div>
                                                            <div className="row mb-5 mt-4">

                                                            <div className="col-md-6">
                                                                <div className="form-floating">
                                                                    <input className="form-control" id="inputLastName"
                                                                           type="text"
                                                                           name="amount"
                                                                           onChange={(e)=>setAmount(e.target.value)}
                                                                           placeholder="Enter amount"/>
                                                                    <label htmlFor="inputLastName">Amount</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="form-floating">
                                                                    <input className="form-control" id="inputLastName"
                                                                           type="file"
                                                                           accept="image/png, image/gif, image/jpeg"
                                                                           onChange={HandleFileListUpload}
                                                                            disabled={fileLimit}
                                                                            multiple
                                                                        />
                                                                    <label htmlFor="inputLastName">Upload Pictures of product</label>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="row mb-5">
                                                    <div className="col-md-6">
                                                        <div className="form-floating mb-3 mb-md-0">
                                                            <input className="form-control"
                                                                id="inputFirstName"
                                                                type="text"
                                                                name="name"
                                                                onChange={(e) => setDeliveryrange(e.target.value)}
                                                                placeholder="Enter time range" />
                                                            <label htmlFor="inputFirstName">Delivery Time Range</label>
                                                        </div>
                                                    </div>


                                                    <div className="col-md-6">
                                                        <div className="form-floating">
                                                            <select
                                                                className="form-control"
                                                                name="category_id"
                                                                onChange={(e) => setDeliverydesc(e.target.value)}
                                                                id="category_id">
                                                                <option>Select Description</option>
                                                                <option>min</option>
                                                                <option>hour</option>
                                                                <option>day</option>
                                                                <option>week</option>
                                                                <option>month</option>                                                                
                                                            </select>
                                                            <label htmlFor="inputLastName">Delivery Time Description</label>
                                                        </div>
                                                    </div>
                                                </div>

                                                        <div className="row mb-5">
                                                            <div className="col-md-6">
                                                                <div className="form-floating mb-3 mb-md-0">
                                                                    <input className="form-control" id="inputStartDate"
                                                                           type="datetime-local"
                                                                           name="start_date"
                                                                           onChange={(e)=>setStartDate(e.target.value)}
                                                                           placeholder="Enter start date"/>
                                                                    <label htmlFor="inputStartDate">Start Date</label>
                                                                </div>
                                                            </div>

                                                            <div className="col-md-6">
                                                                <div className="form-floating">
                                                                    <input className="form-control" id="inputEndDate"
                                                                           type="datetime-local"
                                                                           name="finish_date"
                                                                           onChange={(e)=>setFinishDate(e.target.value)}
                                                                           placeholder="Enter finish date"/>
                                                                    <label htmlFor="inputEndDate">Finish Date</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row mb-5">
                                                            <div className="form-group">
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="checkbox" id="inlineCheckbox1"
                                                                           name="is_active"
                                                                           onChange={(e) => e.target.checked ? setActive(1) : setActive( 0)}
                                                                    />
                                                                    <label style={{fontSize:"16px"}} className="form-check-label" htmlFor="inlineCheckbox1">Make Active</label>
                                                                </div>

                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="checkbox" id="inlineCheckbox2"
                                                                           name="is_verified"
                                                                           onChange={(e) => e.target.checked ? setVerified( 1) : setVerified( 0)}
                                                                    />
                                                                    <label style={{fontSize:"16px"}} className="form-check-label" htmlFor="inlineCheckbox2">Make Verified</label>
                                                                </div>

                                                            </div>

                                                        </div>


                                                        <div className="col-md-4 mt-4 mb-0">
                                                            <div className="d-grid">
                                                                <a onClick={createProduct} className="btn btn-primary btn-block">
                                                                    {isFetching ? (
                                                                        <Spinner/>
                                                                    ) : (
                                                                        <span>Submit{'  '}</span>
                                                                    )}
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                            </div>
                        </main>
                        <Footer/>
                    </div>
                </div>
            </>
        )
    }
export default Add;