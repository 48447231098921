import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SidebarNavbar from "../../components/Sidebar"
import Footer from "../../components/Footer";
import Spinner from '../../components/Loading'
import { addPost } from "../../redux/ApiCalls/postCalls";
import { getCategories } from "../../redux/ApiCalls/categoryCalls";
import {Editor} from "react-draft-wysiwyg";
import {EditorState} from "draft-js";
import {convertToHTML} from "draft-convert";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import 'react-phone-input-2/lib/style.css'
import {TagsInput} from "react-tag-input-component";
import Select from 'react-select';

const Add = ({ location }) => {
    const dispatch = useDispatch()

    const [title, setTitle] = useState("")
    const [meta, setMeta] = useState("")
    const [post_type, setPostType] = useState("") //lagos 2671  abia 2647
    const [is_featured, setFeatured] = useState(0)
    const [is_published, setPublished] = useState(0)
    const [is_approved, setApproved] = useState(0)
    const [featured_image, setFeaturedImg] = useState(null)
    const [limit, setLimit] = useState(2)
    const [tags, setTags] = useState([]);

    const [postBody, setpostBody] = useState("");
    const [editorState, setEditorState] = useState(() =>
        EditorState.createEmpty()
    );
    const [convertedContent, setConvertedContent] = useState(null);
    const handleEditorChange = (state) => {
        setEditorState(state);
        convertContentToHTML();
    };
    const convertContentToHTML = () => {
        let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
        setConvertedContent(currentContentAsHTML);
        setpostBody(currentContentAsHTML);
    };

    useEffect(() => {
        getCategories(dispatch);
    }, [dispatch]);


    const categories = useSelector((state) => state.categories.categories);

    const [selectedCategories, setSelectedCategories] = useState([]);
    const [options, setOptions] = useState([]);

    useEffect(() => {
        setOptions(categories?.data?.map(category => ({
            label: category?.name,
            value: category?.id,
        })));
    }, [categories]);


    console.log("options", options)
    const handleChangeCat = (e, no) => {
        setSelectedCategories(Array.isArray(e) ? e.map(x => x.value) : []);
    }

    const handleAddTags = (tags) => {
        setTags(tags );
    };

    const handleImage = (e) => {
        const reader = new FileReader();
        reader.readAsDataURL(e);
        setFeaturedImg(e);
        reader.onload = function () {
        };
    };

    const createPost = (e) => {
        e.preventDefault()
        const formData = new FormData();
        formData.append("title", title);
        formData.append("meta", meta);
        formData.append("body", postBody);
        formData.append("post_type", post_type);
        formData.append("is_featured", is_featured);
        formData.append("is_published", is_published);
        formData.append("is_approved", is_approved);
        selectedCategories.forEach((cat,i, data) => {
            formData.append(`categories[${i}]`, cat)
        });
        tags.forEach((tag,i, data) => {
            formData.append(`tags[${i}]`, tag)
        })
        formData.append("featured_image", featured_image);


        addPost(dispatch,(formData))
        // addPost(dispatch, {
        //     title,
        //     meta,
        //     post_type,
        //     category_id,
        //     tags,
        //     is_featured,
        //     is_published,
        //     is_approved,
        //     photo
        // })
    }

    return (
        <>
            <div id="layoutSidenav">

                <SidebarNavbar />
                <div id="layoutSidenav_content">
                    <main>
                        <div className="container px-2">
                            <div className="d-flex justify-content-between align-items-baseline">
                                <h4 className="mt-4">Post Management</h4>
                                <div className="breadcrumb mb-4">
                                    <a className="btn btn-xs btn-primary" href="/post-management">List Post</a>
                                </div>
                            </div>
                            <div className="row justify-content-center">
                                <div className="col-lg-12">

                                    <div className="card shadow-lg border-0 rounded-lg mt-5">
                                        <div className="card-body">
                                                    <div className="card-header">
                                                        <h5 className="mt-5 font-weight-light arts">Create Post </h5>
                                                    </div>
                                                    <form>
                                                        <div className="row mb-5">
                                                            <div className="col-md-6">
                                                                <div className="form-floating mb-3 mb-md-0">
                                                                    <input
                                                                        className="form-control"
                                                                        id="inputTitle"
                                                                        type="text"
                                                                        name="title"
                                                                        onChange={(e) => setTitle(e.target.value)}
                                                                        placeholder="Enter title"
                                                                        required
                                                                    />
                                                                    <label htmlFor="inputTitle">Title</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="form-floating">
                                                                    <input
                                                                        className="form-control"
                                                                        id="inputDesc"
                                                                        type="text"
                                                                        name="meta"
                                                                        onChange={(e) => setMeta(e.target.value)}
                                                                        placeholder="Enter Description"
                                                                        required
                                                                    />
                                                                    <label htmlFor="inputDesc">Description</label>
                                                                </div>
                                                            </div>
                                                           </div>

                                                        <div className="row mb-5">
                                                            <div className="col-md-6">
                                                                <div className="form-floating mb-3 mb-md-0">
                                                                    {/*<select*/}
                                                                    {/*    className="form-control"*/}
                                                                    {/*    name="category_id"*/}
                                                                    {/*    onChange={(e) => setCategory(e.target.value)}*/}
                                                                    {/*    id="category_id">*/}
                                                                    {/*    <option>Select Category</option>*/}
                                                                    {/*    {categories?.data?.map((item, idx) =>*/}
                                                                    {/*        <option key={idx} value={item.id}>{item.name}</option>*/}
                                                                    {/*    )}*/}
                                                                    {/*</select>*/}
                                                                    {/*<label htmlFor="inputCategories">Category</label>*/}
                                                                    <p className="form-label">Category</p>
                                                                    <Select
                                                                        className="dropdown mb-24"
                                                                        placeholder="Select Option"
                                                                        value={options.filter(obj => selectedCategories.includes(obj.value))} // set selectedCategories values
                                                                        options={options} // set list of the data
                                                                        onChange={(event) => handleChangeCat(event, 0)} // assign onChange function
                                                                        isMulti
                                                                        isClearable
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="form-floating">
                                                                    {/*<select*/}
                                                                    {/*    className="form-control"*/}
                                                                    {/*    name="tags"*/}
                                                                    {/*    onChange={(e) => setTags(e.target.value)}*/}
                                                                    {/*    id="tags">*/}
                                                                    {/*    <option>Select tags</option>*/}
                                                                    {/*    <option>tag 1</option>*/}
                                                                    {/*    <option>tag 2</option>*/}
                                                                    {/*    /!*{associations.map((item, idx) =>*!/*/}
                                                                    {/*    /!*    <option key={idx} value={item.id}>{item.name}</option>*!/*/}
                                                                    {/*    /!*)}*!/*/}
                                                                    {/*</select>*/}
                                                                    {/*<label htmlFor="inputLastName">Add Tags</label>*/}
                                                                    <p className="form-label">Tags</p>

                                                                    <TagsInput
                                                                        name="tags"
                                                                        maxLength={limit}
                                                                        placeHolder="Enter Tags & press enter"
                                                                        onChange={handleAddTags}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="row mb-5">
                                                            <div className="col-md-6">
                                                                <div className="form-floating">
                                                                    <input className="form-control" id="inputLastName"
                                                                        type="file"
                                                                           name="featured_image"
                                                                        accept="image/png, image/gif, image/jpeg"
                                                                        onChange={(e) => handleImage(e.target.files[0])}
                                                                        placeholder="Enter city" />
                                                                    <label htmlFor="inputLastName">Passport Photo Upload</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="form-floating mb-3 mb-md-0">
                                                                    <select
                                                                        className="form-control"
                                                                        name="post_type"
                                                                        onChange={(e) => setPostType(e.target.value)}
                                                                        id="post_type">
                                                                        <option>Post Type</option>
                                                                            <option value="blog">Blog</option>
                                                                            <option value="forum">Forum</option>
                                                                    </select>
                                                                    <label htmlFor="inputCategories">Post Type</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row mb-5">
                                                            <div className="col-md-12">
                                                                <div className="form-floating">
                                                                    <p className="form-label">Body</p>
                                                                    <Editor
                                                                        editorState={editorState}
                                                                        toolbarClassName="toolbarClassName"
                                                                        wrapperClassName="wrapperClassName"
                                                                        editorClassName="editorClassName"
                                                                        onEditorStateChange={handleEditorChange}
                                                                        placeholder="Write your post here"
                                                                    />
                                                                     </div>
                                                            </div>
                                                             </div>

                                                        <div className="row mb-5">
                                                            <div className="form-group">
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="checkbox" id="inlineCheckbox1"
                                                                           name="is_featured"
                                                                           onChange={(e) => e.target.checked ? setFeatured( 1) : setFeatured( 0)}
                                                                            />
                                                                    <label style={{fontSize:"16px"}} className="form-check-label" htmlFor="inlineCheckbox1">Make Featured</label>
                                                                </div>

                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="checkbox" id="inlineCheckbox2"
                                                                           name="is_published"
                                                                           onChange={(e) => e.target.checked ? setPublished( 1) : setPublished( 0)}
                                                                            />
                                                                    <label style={{fontSize:"16px"}} className="form-check-label" htmlFor="inlineCheckbox2">Publish on post</label>
                                                                </div>

                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="checkbox" id="inlineCheckbox3"
                                                                           name="is_approved"
                                                                           onChange={(e) => e.target.checked ? setApproved( 1) : setApproved( 0)}
                                                                    />
                                                                    <label style={{fontSize:"16px"}} className="form-check-label" htmlFor="inlineCheckbox3">Approved on post</label>
                                                                </div>
                                                            </div>

                                                        </div>

                                                        <div className="col-md-6 mt-4 mb-0">
                                                            <div className="d-grid">
                                                                <a onClick={createPost} className="btn btn-primary btn-block">
                                                                    {/*{isFetching ? (*/}
                                                                    {/*    <Spinner />*/}
                                                                    {/*) : (*/}
                                                                    {/*    <span>Submit{'  '}</span>*/}
                                                                    {/*)}*/}
                                                                    submit
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </form>


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                    <Footer />
                </div>
            </div>
        </>
    )
}
export default Add;