import {publicRequest, userRequest} from "../../requestMethod";
import Notification from "../../utils/notification";
import {
    getSingleArtisanFailure,
    getSingleArtisanStart,
    getSingleArtisanSuccess,
    getArtisanFailure,
    getArtisanStart,
    getArtisanSuccess,
    deleteArtisanFailure,
    deleteArtisanStart,
    deleteArtisanSuccess,
    updateArtisanFailure,
    updateArtisanStart,
    updateArtisanSuccess,
    addArtisanFailure,
    addArtisanStart,
    addArtisanSuccess,
} from "../artisanRedux";

//

export const addArtisan = async (dispatch, Artisan) => {
    dispatch(addArtisanStart());
    try {
        const res = await userRequest.post(`/admin/artisans`, Artisan);
        // console.log("post data", res.data?.data?.artisan?.id)

        // dispatch(addArtisanSuccess(res.data.data));
        Notification.success(res?.data?.message);
        localStorage.setItem("tabstate", true);
        localStorage.setItem("artisan_id", res.data?.data?.artisan?.id);
        // window.location.href="/artisan-management";
        window.location.reload();
    } catch (err) {
        if (err && err.response && err.response.status === 403) {
            localStorage.clear();
            window.location.href = "/signin";
        }else{
            dispatch(addArtisanFailure());
            Notification.error(err?.response?.data?.message);
        }

    }
};

export const creditArtisan = async (id,dispatch, Artisan) => {
    dispatch(addArtisanStart());
    try {
        const res = await userRequest.post(`/admin/artisans/${id}/credit`, Artisan);
        // console.log("post data", res.data?.data?.artisan?.id)

        // dispatch(addArtisanSuccess(res.data.data));
        Notification.success(res?.data?.message);
        // window.location.href="/artisan-management";
        window.location.reload();
    } catch (err) {
        if (err && err.response && err.response.status === 403) {
            localStorage.clear();
            window.location.href = "/signin";
        }else{
            dispatch(addArtisanFailure());
            Notification.error(err?.response?.data?.message);
        }

    }
};

export const bulkArtisan = async (dispatch, Artisan) => {
    dispatch(addArtisanStart());
    try {
        const res = await userRequest.post(`/admin/artisans/bulk`, Artisan);
       
        Notification.success(res?.data?.message);
        window.location.href="/artisan-management";
    } catch (err) {
        if (err && err.response && err.response.status === 403) {
            localStorage.clear();
            window.location.href = "/signin";
        }else{
            dispatch(addArtisanFailure());
            Notification.error(err?.response?.data?.message);
        }

    }
};
export const getArtisans = async (dispatch, status, business='') => {
    // alert(5)
    dispatch(getArtisanStart());
    try {
        const res = await userRequest.get(`/admin/artisans?business=${business}&filter[is_active]=${status}`);
        dispatch(getArtisanSuccess(res?.data?.data?.artisans));
        // Notification.success(res.data.message);
        return res?.data?.data?.artisans;
    } catch (err) {
        if (err && err.response && err.response.status === 403) {
            localStorage.clear();
            window.location.href = "/signin";
        }else{
            dispatch(getArtisanFailure());
            Notification.error(err.response.data.message);
        }
    }
};

export const getArtisan = async (id, dispatch) => {
    // alert(5)
    dispatch(getSingleArtisanStart());
    try {
        const res = await userRequest.get(`/admin/artisans/${id}?include=bankDetail`);
        dispatch(getSingleArtisanSuccess(res?.data?.data?.artisan));
        // Notification.success(res.data.message);
    } catch (err) {
        if (err && err.response && err.response.status === 403) {
            localStorage.clear();
            window.location.href = "/signin";
        }else{
            dispatch(getSingleArtisanFailure());
            Notification.error(err.response.data.message);
        }

    }
};

export const updateArtisan = async (id, artisan, dispatch) => {
    dispatch(updateArtisanStart());
    try {
        const res = await userRequest.post(`/admin/artisans/${id}`, artisan);
        // dispatch(updateArtisanSuccess(res.data.data));
        Notification.success(res.data.message);
        localStorage.setItem("tabstate", true);
        window.location.reload();
    } catch (err) {
        if (err && err.response && err.response.status === 403) {
            localStorage.clear();
            window.location.href = "/signin";
        }else{
            dispatch(updateArtisanFailure());
            Notification.error(err.response.data.message);
        }
    }
};

//
export const deleteArtisanFunc = async (id, dispatch) => {
    dispatch(deleteArtisanStart());
    try {
        const res = await userRequest.delete(`/admin/artisans/${id}`);
        dispatch(deleteArtisanSuccess(id));
        Notification.success(res.data.message);
        window.location.reload();
    } catch (err) {
        if (err && err.response && err.response.status === 403) {
            localStorage.clear();
            window.location.href = "/signin";
        }else{
            dispatch(deleteArtisanFailure());
            Notification.error(err.response.data.message);
        }

    }
};

export const handlePageChange = async (pageNum, perPage, dispatch) => {
    // alert(5)
    dispatch(getArtisanStart());
    try {
        const res = await userRequest.get(`/admin/artisans?page=${pageNum}&limit=${perPage}`);
        dispatch(getArtisanSuccess(res.data.data.artisans));
        // Notification.success(res.data.message);
    } catch (err) {
        dispatch(getArtisanFailure());
        Notification.error(err.response.data.message);
    }
};

export const handlePerRowsChange = async (page, perPage, dispatch) => {
    // alert(5)
    dispatch(getArtisanStart());
    try {
        const res = await userRequest.get(`/admin/artisans?page=${page}&limit=${perPage}`);
        dispatch(getArtisanSuccess(res.data.data.artisans));
        // Notification.success(res.data.message);
    } catch (err) {
        dispatch(getArtisanFailure());
        Notification.error(err.response.data.message);
    }
};
