import React, { useEffect, useState } from "react";
import SidebarNavbar from "../../components/Sidebar"
import Footer from "../../components/Footer";
import CustomLoader from "../../components/CustomLoader";
import DataTableExtensions from "react-data-table-component-extensions";
import DataTable from "react-data-table-component";
import 'react-data-table-component-extensions/dist/index.css';
import { getCategories, handlePageChange, handlePerRowsChange,addCategory, getACategory, updateCategory } from "../../redux/ApiCalls/categoryCalls";
import { useDispatch, useSelector } from "react-redux";
import Moment from "react-moment";
import Modals from "../../components/Modals";
import Input from "../../components/Input";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Spinner } from "react-bootstrap";
import { startCase } from 'lodash';
import { format } from 'date-fns';

const Categories = () => {
    const excludedFields = [
        'id',
        'is_active',
        'slug',
        'created_at',
        'updated_at',
        'deleted_at'        
      ];
    const dispatch = useDispatch();
    const queryClient = useQueryClient();
    const [open, setOpen]=useState(false);
    const [edit, setEdit] = useState(false)
    const [current, setCurrent] = useState('');
    const [currentData, setCurrentData] = useState(null);
    const createCategoryMutation = useMutation(addCategory, {
        onSuccess: (res) => {
          queryClient.invalidateQueries('donations')
          setOpen(false);
          window.location.reload();
        //   notify(res.message, { type: 'success' })
        },
        onError: err => {
          console.log(err)
        //   notify(err.message, { type: 'error' })
        }
      });
      const updateCategoryMutation = useMutation(updateCategory, {
        onSuccess: (res) => {
          queryClient.invalidateQueries('donations')
          setOpen(false);
          window.location.reload();
        },
        onError: err => {
          console.log(err)
        }
      })
    const validationSchema = Yup.object({
        name: Yup.string()
          .matches(
            /^[a-zA-Z0-9\s .!?,"-]+$/,
            'field accepts only string, numbers and hyphens'
          )
          .required('Field cannot be empty'),
        description: Yup.string()
          .matches(
            /^[a-zA-Z0-9\s .!?,"-]+$/,
            'field accepts only string, numbers and hyphens'
          )
          .required('Field cannot be empty'),      
      
      })
    const categoryCreate = useFormik({
        validationSchema: validationSchema,
        initialValues: {
          name: '',
          description: '',
          is_active: true
        },
        onSubmit: (values) => {
          
            createCategoryMutation.mutate(values)
        },
      });

      const viewCategory = (obj) => {
        setCurrent('viewCategory')
        setOpen(true)
        setCurrentData(obj)
      }
      const updateMyData = () => {       
          const details = {
            id: currentData.id,
            name: currentData.name,
            is_active: currentData.is_active,
            description: currentData.description,            
            _method: 'PUT',
          }    
          updateCategoryMutation.mutate(details)
        
    
      }



      const singleDonor = useQuery(['data', 'id', currentData?.id], () => getACategory(currentData?.id), {
        select: data => data,
        onError: err => {
         
        },
        staleTime: Infinity,
        enabled: current === 'viewCategory'
      })
      console.log(singleDonor);
    const [products, setProducts] =useState({
        data:[],
        total: 0
    });
    useEffect(() => {
        getCategories('',dispatch).then(resp=>{
            
            setProducts(resp);
        });
    }, [dispatch]);

    const handleSearchProduct= async(e) => {
        getCategories(e.target.value, dispatch);
      }

    const [columns, setColumns] = useState([
       
        {
            name: 'Category',
            selector: 'name',
            sortable: true,
        },
        
        
        {
            name: 'Description',
            selector: 'description',
            sortable: true,
        },
        {
            name: 'Created',
            selector: 'created_at',
            sortable: true,
            cell: row => row.created_at ?
                <div>
                    <Moment format="D MMM YYYY">{row.created_at}</Moment>
                </div>
                :
                <div>

                </div>
        },
        {
            name: 'Action',
            selector: 'created_at',
            sortable: true,
            cell: row => row.created_at &&
                <div>
                    <a className="btn btn-xs btn-primary text-white" style={{fontSize: '10px'}} onClick={()=>{
                        viewCategory(row)
                    }} >view</a> 
                    
                </div>
        }
    ],
    )

    const [deleteProduct, SetDeletePopup] = useState(false)
    const [productId, setProductId] = useState(null)

   

    const handlePageChanges = (pageNum, perPage) => {
        handlePageChange(pageNum, 15, dispatch);
    }

    const handlePerRowsChanges = (perPage, page) => {
        handlePerRowsChange(page, perPage, dispatch);
    }

    const deleteProductPop = () => {
        SetDeletePopup(true)
        document.body.style.overflow = 'hidden';
    }

    const hide = () => {
        SetDeletePopup(false)
        document.body.style.overflow = 'hidden';
    }
    // const products = useSelector((state) => state.products.products);
    const data = products.data;
    const totalRows = products.total
    const tableData = { columns, data };

    return (
        <>
            <div id="layoutSidenav">

                <SidebarNavbar />
                <div id="layoutSidenav_content">
                    <main>
                        <div className="container-fluid px-4">
                            <div className="d-flex justify-content-between align-items-baseline">
                                <h5 className="mt-4">Categories</h5>
                                <div className="breadcrumb mb-4">
                                    <a className="btn btn-xs btn-primary" onClick={() =>{
                                        setOpen(true);
                                        setCurrent('addCategory');
                                    }
                                        }>
                                            Add Category
                                        </a>
                                </div>
                            </div>

                            <div className="card mb-4">
                                <div className="card-body">
                                <div className="form-floating" style={{ width: "20%", float: "right", marginBottom: "10px" }}>
                                    <input className="form-control" id="inputLastName"
                                            type="text"
                                            name="amount"
                                            onChange={(e)=>handleSearchProduct(e)}
                                            placeholder="Enter product name"/>
                                                                   
                                        <label htmlFor="inputLastName">Filter By Category Name</label>
                                    </div>
                                    <DataTableExtensions {...tableData}>
                                        <DataTable
                                            columns={columns}
                                            data={data}
                                            noHeader
                                            defaultSortAsc={false}
                                            highlightOnHover
                                            // progressPending={orderLoading}
                                            progressComponent={<CustomLoader />}
                                            pagination={true}
                                            paginationServer={true}
                                            paginationResetDefaultPage={true}
                                            export={false}
                                            paginationPerPage={15}
                                            paginationTotalRows={totalRows}
                                            paginationRowsPerPageOptions={[15, 30, 45, 60, 75]}
                                            onChangeRowsPerPage={handlePerRowsChanges}
                                            onChangePage={handlePageChanges}
                                        />
                                    </DataTableExtensions>
                                </div>
                            </div>

                        </div>
                    </main>

                    <div>
                        {deleteProduct && (
                            <div style={{
                                position: "fixed", /* Sit on top of the page content */
                                width: "100%", /* Full width (cover the whole page) */
                                height: "100%", /* Full height (cover the whole page) */
                                top: "0",
                                left: "0",
                                right: "0",
                                bottom: "0",
                                backgroundColor: "rgba(0, 0, 0, 0.7)", /* #333333 background with opacity */
                                zIndex: "1110"
                            }} className="overflow-auto">
                                <div
                                    style={{
                                        height: "100vh",
                                        display: "flex",
                                        alignItems: "center",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                    }}
                                >
                                    <div
                                        style={{
                                            padding: "24px",
                                            gap: "40px",
                                            position: "relative",
                                            width: "22%",
                                            background: "#FFFFFF",
                                            borderRadius: "12px",
                                        }}
                                        className="col-6 col-md-6">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <label htmlFor="email">Are you sure you want to delete the record</label>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>

                    <Footer />
                </div>
            </div>

            <Modals
                show={open && current === 'addCategory'}
                onHide={() => setOpen(false)}
                size={'md'}
                title="Add new category"
            >
                <form onSubmit={categoryCreate.handleSubmit}>
                
                <Input
                    name="name"
                    value={categoryCreate.values.name}
                    type="text"
                    placeholder="Category"
                    label="Category"
                    handleChange={categoryCreate.handleChange}
                    handleBlur={categoryCreate.handleBlur}
                    error={
                    categoryCreate.errors.name && categoryCreate.touched.name
                        ? categoryCreate.errors.name
                        : null
                    }
                />
                <Input
                    name="description"
                    value={categoryCreate.values.description}
                    type="text"
                    placeholder="Description"
                    label="Description"
                    handleChange={categoryCreate.handleChange}
                    handleBlur={categoryCreate.handleBlur}
                    error={
                    categoryCreate.errors.description && categoryCreate.touched.description
                        ? categoryCreate.errors.description
                        : null
                    }
                />
                
                
                <div className="flex justify-content-end">
                    <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={createCategoryMutation.isLoading}
                    >
                    {createCategoryMutation.isLoading && <Spinner />} Create
                    </button>
                </div>
                </form>
            </Modals>

            <Modals
        show={open && current === 'viewCategory' && singleDonor}
        onHide={() => {
          setEdit(false);
          setOpen(false);
        }}
        title="View Category"
        size="md"
        footer={
          <button
            type="button"
            className="btn btn-primary"
            disabled={singleDonor?.isLoading}
            onClick={() => {
              if (edit) {
                setEdit(false);
                updateMyData('donor');
              } else {
                setEdit(true);
              }
            }}
          >
            {updateCategoryMutation.isLoading && <Spinner />}
            {edit ? 'Update' : 'Edit'}
          </button>
        }
      >
        {singleDonor?.isLoading && <Spinner />}
        <dl className="striped">
        {singleDonor?.data &&
            Object.entries(singleDonor?.data)?.map(([key, value]) =>
              excludedFields.includes(key) ? (
                []
              ) : key === 'updated_at' || key === 'created_at' ? (
                <div key={key} className="row">
                  <dt className="col-4">{`${startCase(key)} :`}</dt>
                  <dd className="col-8">
                    {format(new Date(value), 'yyyy/MM/dd')}
                  </dd>
                </div>
              ) : key === 'start_date' || key === 'end_date' ? (
                <div key={key} className="row">
                  <dt className="col-4">{`${startCase(key)} :`}</dt>
                  {!edit && (
                    <>
                      <dd className="col-8">
                        {format(new Date(value), 'yyyy/MM/dd')}
                      </dd>
                    </>
                  )}

                  {edit && (
                    <>
                      <dd className="col-8">
                        <input
                          className="form-control"
                          type="date"
                          defaultValue={value}
                          onChange={(e) =>
                            setCurrentData({
                              ...currentData,
                              [key]: e.target.value,
                            })
                          }
                        />
                      </dd>
                    </>
                  )}
                </div>
              ) : (
                <div key={key} className="row">
                  <dt className="col-4">{`${startCase(key)} :`}</dt>
                  {!edit && <dd className="col-8">{value}</dd>}
                  {edit && (
                    <div className="col-8">
                      <input
                        className="form-control"
                        type="text"
                        defaultValue={value}
                        onChange={(e) =>
                          setCurrentData({
                            ...currentData,
                            [key]: e.target.value,
                          })
                        }
                      />
                    </div>
                  )}
                </div>
              )
            )}
        </dl>
      </Modals>
        </>
    )
}

export default Categories;