import {publicRequest, userRequest} from "../../requestMethod";
import Notification from "../../utils/notification";
import {
    getSingleArtisanFailure,
    getSingleArtisanStart,
    getSingleArtisanSuccess,
    getArtisanFailure,
    getArtisanStart,
    getArtisanSuccess,
    deleteArtisanFailure,
    deleteArtisanStart,
    deleteArtisanSuccess,
    updateArtisanFailure,
    updateArtisanStart,
    updateArtisanSuccess,
    addArtisanFailure,
    addArtisanStart,
    addArtisanSuccess,
} from "../artisanRedux";

//





export const getAdmin = async (id, dispatch) => {
    // alert(5)
    dispatch(getSingleArtisanStart());
    try {
        const res = await userRequest.get(`/admin/admins/${id}`);
        dispatch(getSingleArtisanSuccess(res?.data?.data?.admin));
        return res?.data?.data?.admin
    } catch (err) {
        if (err && err.response && err.response.status === 403) {
            localStorage.clear();
            window.location.href = "/signin";
        }else{
            dispatch(getSingleArtisanFailure());
            Notification.error(err.response.data.message);
        }

    }
};

export const updateAdmin = async (id, artisan, dispatch) => {
    dispatch(updateArtisanStart());
    try {
        const res = await userRequest.put(`/admin/admins/${id}`, artisan);
        // dispatch(updateArtisanSuccess(res.data.data));
        Notification.success(res.data.message);        
        window.location.reload();
    } catch (err) {
        if (err && err.response && err.response.status === 403) {
            localStorage.clear();
            window.location.href = "/signin";
        }else{
            dispatch(updateArtisanFailure());
            Notification.error(err.response.data.message);
        }
    }
};



