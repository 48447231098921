import React, { Component } from 'react';
import SidebarNavbar from "../../components/Sidebar"
import Footer from "../../components/Footer";

class Add extends Component {
    render() {
        return (
            <>
                <div id="layoutSidenav">

                    <SidebarNavbar/>
                    <div id="layoutSidenav_content">
                        <main>
                            <div className="container px-2">
                                <div className="d-flex justify-content-between align-items-baseline">
                                    <h4 className="mt-4">User Management</h4>
                                    <div className="breadcrumb mb-4">
                                        <a className="btn btn-xs btn-primary" href="/users">List Artisan</a>
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-lg-12">
                                        <div className="card shadow-lg border-0 rounded-lg mt-5">
                                            <div className="card-header">
                                                <h5 className=" my-4">User Information</h5>
                                            </div>
                                            <div className="card-body">
                                                <h1>User  Information Here</h1>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </main>
                        <Footer/>
                    </div>
                </div>
            </>
        )
    }
}
export default Add;