import { publicRequest, useAxios, userRequest } from "../../requestMethod";
import Notification from "../../utils/notification";
import {
    getCategoryFailure,
    getCategoryStart,
    getCategorySuccess,
    deleteCategoryFailure,
    deleteCategoryStart,
    deleteCategorySuccess,
    updateCategoryFailure,
    updateCategoryStart,
    updateCategorySuccess,
    addCategoryFailure,
    addCategoryStart,
    addCategorySuccess,
} from "../categoryRedux";

//



export const addCategory = async (Category) => {
    
    try {
        const res = await userRequest.post(`/admin/categories`, Category);       
        Notification.success(res.data.message);
    } catch (err) {
        if (err && err.response && err.response.status === 403) {
            localStorage.clear();
            window.location.href = "/signin";
        }else{
            Notification.error(err.response.data.message);
        }
    }
};

export const updateCategory = async (category) => {
    try {
        const res = await userRequest.post(`/admin/categories/${category.id}`, category);
        Notification.success(res.data.message);
        return res;
    } catch (err) {
        if (err && err.response && err.response.status === 403) {
            localStorage.clear();
            window.location.href = "/signin";
        }else{
            Notification.error(err.response.data.message);
        }
    }
};
export const getACategory = async (id) => {
    // dispatch(getCategoryStart());
    try {
        const res = await userRequest.get(`/admin/categories/${id}`);
        // dispatch(getCategorySuccess(res?.data?.data?.categories));
        
        return res?.data?.data?.category;
        // console.log(res?.data?.data?.categories);
        // Notification.success(res.data.status.msg);
    } catch (err) {
        if (err && err.response && err.response.status === 403) {
            localStorage.clear();
            window.location.href = "/signin";
        }else{
            // dispatch(getCategoryFailure());
            // Notification.error(err.response.data.message);
        }
    }
};

export const getCategories = async (dispatch) => {
    // dispatch(getCategoryStart());
    try {
        const res = await userRequest.get("/admin/categories");
        // dispatch(getCategorySuccess(res?.data?.data?.categories));
        
        return res?.data?.data?.categories;
        // console.log(res?.data?.data?.categories);
        // Notification.success(res.data.status.msg);
    } catch (err) {
        if (err && err.response && err.response.status === 403) {
            localStorage.clear();
            window.location.href = "/signin";
        }else{
            // dispatch(getCategoryFailure());
            // Notification.error(err.response.data.message);
        }
    }
};

export const handlePageChange = async (pageNum, perPage, dispatch) => {
    // alert(5)
    dispatch(getCategoryStart());
    try {
        const res = await userRequest.get(`/admin/products?page=${pageNum}&limit=${perPage}`);
        dispatch(getCategorySuccess(res.data.data.products));
        // Notification.success(res.data.message);
    } catch (err) {
        dispatch(getCategoryFailure());
        Notification.error(err.response.data.message);
    }
};

export const handlePerRowsChange = async (page, perPage, dispatch) => {
    // alert(5)
    dispatch(getCategoryStart());
    try {
        const res = await userRequest.get(`/admin/products?page=${page}&limit=${perPage}`);
        dispatch(getCategorySuccess(res.data.data.products));
        // Notification.success(res.data.message);
    } catch (err) {
        dispatch(getCategoryFailure());
        Notification.error(err.response.data.message);
    }
};

export const getStates = async (dispatch) => {
    dispatch(getCategoryStart());
    try {
        const res = await userRequest.get("/generic/states/160");
        dispatch(getCategorySuccess(res?.data?.data?.states));
        return res?.data?.data?.states;
        // Notification.success(res.data.status.msg);
    } catch (err) {
        if (err && err.response && err.response.status === 403) {
            localStorage.clear();
            window.location.href = "/signin";
        }else{
            dispatch(getCategoryFailure());
            Notification.error(err.response.data.message);
        }
    }
};

export const getCities = async (id,dispatch) => {
    dispatch(getCategoryStart());
    try {
        const res = await userRequest.get(`/generic/cities/${id}`);
        dispatch(getCategorySuccess(res?.data?.data?.cities));
        return res?.data?.data?.cities;
        // Notification.success(res.data.status.msg);
    } catch (err) {
        if (err && err.response && err.response.status === 403) {
            localStorage.clear();
            window.location.href = "/signin";
        }else{
            dispatch(getCategoryFailure());
            Notification.error(err.response.data.message);
        }
    }
};

//
export const deleteCategoryFunc = async (id, dispatch) => {
    dispatch(deleteCategoryStart());
    try {
        const res = await userRequest.delete(`/Categorys/${id}`);
        dispatch(deleteCategorySuccess(id));
        Notification.success(res.data.status.msg);
        window.location.reload();
    } catch (err) {
        if (err && err.response && err.response.status === 403) {
            localStorage.clear();
            window.location.href = "/signin";
        }else{
            dispatch(deleteCategoryFailure());
            Notification.error(err.response.data.message);
        }
    }
};
