import React, { useEffect, useState } from "react";
import SidebarNavbar from "../../components/Sidebar"
import Footer from "../../components/Footer";
import Spinner from '../../components/Loading';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { getArtisan, updateArtisan } from "../../redux/ApiCalls/artisanCalls";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { getBank, updateBank,addBank } from "../../redux/ApiCalls/bankCalls";
import { getCategories, getStates, getCities } from "../../redux/ApiCalls/categoryCalls";
import { getAssociation } from "../../redux/ApiCalls/associationCalls";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

const Edit = () => {
    const dispatch = useDispatch()
    const location = useLocation();
    const id = location.pathname.split('/')[2];
    useEffect(() => {
        getArtisan(id, dispatch);
    }, [dispatch])

    const [categories, setCategoryEl] = useState([]);
    const [associations, setAssociationEl] = useState([]);
    const [states, setStateEl] = useState([]);
    const [cities, setCityEl] = useState([]);
    useEffect(() => {
        getCategories(dispatch).then(res =>{        
            setCategoryEl(res.data) ;
        });

        getStates(dispatch).then(res =>{              
            setStateEl(res) ;
            });

        getCities(artisan.state_id,dispatch).then(res =>{              
            setCityEl(res) ;
        });
        getAssociation(dispatch).then(res =>{              
            setAssociationEl(res) ;
            });
        //setCategoryEl(getCategories(dispatch));
        // getAssociation(dispatch);
        // getStates(dispatch);    
        // getCities(artisan.state_id, dispatch);    
    }, [dispatch]);

    

    const [first_name, setFirstname] = useState("")
    const [last_name, setLastname] = useState("")
    const [phone, setPhone] = useState("")
    const [email, setEmail] = useState("")
    const [business_name, setBusinessname] = useState("")
    const [address, setAddress] = useState("")
    const [state_id, setState] = useState("") //lagos 2671  abia 2647
    const [city_id, setCity] = useState("") //lagos30984 30983  abia 30692
    const [category_id, setCategory] = useState("")
    const [association_id, setAssociation] = useState("")
    const [photo, setPhoto] = useState(null)
    const [attachment, setAttachment] = useState([]);
    
    //Banks state
    const [owner_type, setOwnerType] = useState("artisan");
    const [bank_name, setBankName] = useState("");
    const [owner_id, setOwnerOd] = useState("1");
    const [account_type, setAccountType] = useState("");
    const [account_number, setAccountNumber] = useState("");
    const [account_holder_name, setAccountHolderName] = useState("");
    const [tabState, setTabState] = useState(localStorage.getItem("tabstate"))


    const artisan = useSelector((state) => state.artisan.artisan);

    
    const { isFetching, error, isSuccess } = useSelector(state => state.artisans)
    const { isFetchingBank } = useSelector(state => state.banks)

    // console.log("hfhfhfhfhfhf", artisan?.bank_detail)
    const handleImage = (e) => {
        const reader = new FileReader();
        reader.readAsDataURL(e);
        setPhoto(e);
        reader.onload = function () {
        };
    };

    // const updateArtisanFunc = (e) => {
    //     e.preventDefault()
    //     const data = {
    //         "first_name": first_name ? first_name : artisan?.first_name,
    //         "last_name": last_name ? last_name : artisan?.last_name,
    //         "phone": phone ? phone : artisan?.phone,
    //         "business_name": business_name ? business_name : artisan?.business_name,
    //         "address": address ? address : artisan?.address,
    //         "state_id": state_id ? state_id : artisan?.state_id,
    //         "city_id": city_id ? city_id : artisan?.city_id,
    //         "category_id": category_id ? category_id : artisan?.category_id,
    //         "association_id": association_id ? association_id : artisan?.association_id,
    //         "_method": "PUT",
    //     };
    //     updateArtisan(id, data, dispatch)
    // }

    const updateArtisanFunc = (e) =>{
        e.preventDefault()
        const formData = new FormData();
        formData.append("first_name", first_name ? first_name : artisan?.first_name);
        formData.append("last_name", last_name ? last_name : artisan?.last_name);
        formData.append("phone", phone ? phone : artisan?.phone);
        formData.append("business_name", business_name ? business_name : artisan?.business_name);
        formData.append("address", address ? address : artisan?.address);
        formData.append("state_id", state_id ? state_id : artisan?.state_id);
        formData.append("city_id", city_id ? city_id : artisan?.city_id);
        formData.append("category_id", category_id ? category_id : artisan?.category_id);
        formData.append("association_id", association_id ? association_id : artisan?.association_id);
        formData.append("logo", attachment);
        // formData.append("_method", "PUT");
    
        updateArtisan(id,(formData),dispatch)
    }
    

    const updateBankFunc = (e) => {
        e.preventDefault()
        if(artisan.bank_detail==null)
        {
            const data = {
                "owner_type": owner_type ,
                "owner_id": id,
                "bank_name": bank_name ,
                "account_type": account_type ,
                "account_number": account_number ,
                "account_holder_name": account_holder_name                
            };
            addBank(dispatch, data)
        }
        else{
        const data = {
            "owner_type": owner_type ? owner_type : artisan?.bank_detail?.owner_type,
            "owner_id": artisan?.bank_detail?.owner_id,
            "bank_name": bank_name ? bank_name : artisan?.bank_detail?.bank_name,
            "account_type": account_type ? account_type : artisan?.bank_detail?.account_type,
            "account_number": account_number ? account_number : artisan?.bank_detail?.account_number,
            "account_holder_name": account_holder_name ? account_holder_name : artisan?.bank_detail?.account_holder_name,
            "_method": "PUT",
        };
        updateBank(artisan?.bank_detail?.id, data, dispatch)
    }
    }
    return (
        <>
            <div id="layoutSidenav">
                <SidebarNavbar />
                <div id="layoutSidenav_content">
                    <main>
                        <div className="container px-2">
                            <div className="d-flex justify-content-between align-items-baseline">
                                <h4 className="mt-4">Artisan Management</h4>
                                <div className="breadcrumb mb-4">
                                    <a className="btn btn-xs btn-primary" href="/add-artisan">Add Artisan</a>
                                </div>
                            </div>
                            <div className="row justify-content-center">
                                <div className="col-lg-12">
                                    <div className="card shadow-lg border-0 rounded-lg mt-5">
                                        <div className="card-header">
                                            <h5 className=" my-4">Artisan Information</h5>
                                        </div>
                                        <div className="card-body">
                                            <Tabs
                                                // defaultActiveKey="artisan"
                                                defaultActiveKey={tabState ? "bank" : "artisan"}
                                                id="fill-tab-example"
                                                className="mb-3"
                                                fill
                                            >
                                                <Tab eventKey="artisan" title="Edit Artisan">
                                                    <div className="card-header">
                                                        <h5 className="mt-5 font-weight-light">Artisan Details</h5>
                                                    </div>
                                                    <form>
                                                        <div className="row mb-3">
                                                            <div className="col-md-4">
                                                                <div className="form-floating mb-3 mb-md-0">
                                                                    <input className="form-control"
                                                                        id="first_name"
                                                                        type="text"
                                                                        name="first_name"
                                                                        defaultValue={artisan?.first_name}
                                                                        onChange={(e) => setFirstname(e.target.value)}
                                                                        placeholder="Enter first name" />
                                                                    <label htmlFor="inputFirstName">First name</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="form-floating">
                                                                    <input className="form-control"
                                                                        // id="inputLastName"
                                                                        type="text"
                                                                        name="last_name"
                                                                        defaultValue={artisan?.last_name}
                                                                        onChange={(e) => setLastname(e.target.value)}
                                                                        placeholder="Enter last name" />
                                                                    <label htmlFor="inputLastName">Last name</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="form-floating">
                                                                    <PhoneInput
                                                                            placeholder="08012345678"
                                                                            // onInput={(e) => e.target.value = e.target.value.slice(0, 11)} // js validation to check that number does not exceed 11 characters
                                                                            value={artisan?.phone}
                                                                            country={'ng'}
                                                                            enableSearch={true}
                                                                            countryCodeEditable={false}
                                                                            inputProps={{ name: 'phone', required: true, autoFocus: true, maxLength: "17" }}
                                                                            onChange={setPhone}
                                                                        />
                                                                    
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="row mb-3">
                                                            <div className="col-md-4">
                                                                <div className="form-floating mb-3 mb-md-0">
                                                                    <input className="form-control" id="inputFirstName"
                                                                        type="text"
                                                                        name="address"
                                                                        defaultValue={artisan?.address}
                                                                        onChange={(e) => setAddress(e.target.value)}
                                                                        placeholder="Enter address"
                                                                    />
                                                                    <label htmlFor="inputFirstName">Address</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="form-floating">
                                                                    <select
                                                                        className="form-control"
                                                                        name="state_id"
                                                                        defaultValue={artisan?.state_id}
                                                                        onChange={(e) => setState(e.target.value)}
                                                                        id="state_id">
                                                                        <option>Select State</option>
                                                                        {states?.map((item, idx) =>
                                                                            <option key={idx} value={item.id}>{item.name}</option>
                                                                        )}
                                                                    </select>
                                                                    <label htmlFor="inputLastName">State</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="form-floating">
                                                                    <select
                                                                        className="form-control"
                                                                        name="city_id"
                                                                        defaultValue={artisan?.city_id}
                                                                        onChange={(e) => setCity(e.target.value)}
                                                                        id="city_id">
                                                                        <option>Select City</option>
                                                                        {cities?.map((item, idx) =>
                                                                            <option key={idx} value={item.id}>{item.name}</option>
                                                                        )}
                                                                    </select>
                                                                    <label htmlFor="inputLastName">City</label>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="row mb-3">
                                                            <div className="col-md-4">
                                                                <div className="form-floating mb-3 mb-md-0">
                                                                    <select
                                                                        className="form-control"
                                                                        name="category_id"
                                                                        value={artisan?.category_id}
                                                                        onChange={(e) => setCategory(e.target.value)}
                                                                        id="category_id">
                                                                        <option>Select Category</option>
                                                                        {categories?.map((item, idx) =>
                                                                            <option key={idx} value={item.id}>{item.name}</option>
                                                                        )}
                                                                    </select>
                                                                    <label htmlFor="inputCategories">Category</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="form-floating">
                                                                    <select
                                                                        className="form-control"
                                                                        name="association_id"
                                                                        value={artisan?.association_id}
                                                                        onChange={(e) => setAssociation(e.target.value)}
                                                                        id="association_id">
                                                                        <option>Select Association</option>
                                                                        {associations.map((item, idx) =>
                                                                            <option key={idx} value={item.id}>{item.name}</option>
                                                                        )}
                                                                    </select>
                                                                    <label htmlFor="inputLastName">Association</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="form-floating">
                                                                    <input className="form-control" id="inputLastName"
                                                                        type="file"
                                                                        accept="image/png, image/gif, image/jpeg"
                                                                        onChange={(e) => setAttachment(e.target.files[0])}
                                                                        placeholder="Enter city" />
                                                                    <label htmlFor="inputLastName">Passport Photo Upload</label>
                                                                </div> 
                                                            </div>
                                                        </div>


                                                        <div className="col-md-4 mt-4 mb-0">
                                                            <div className="d-grid">
                                                                <a onClick={updateArtisanFunc} type="submit" className="btn btn-primary btn-block">
                                                                    {isFetching ? (
                                                                        <Spinner />
                                                                    ) : (
                                                                        <span>Submit{'  '}</span>
                                                                    )}
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </Tab>

                                                <Tab eventKey="bank" title="Edit Bank" >
                                                    {/*<Tab eventKey="bank" title="Edit Bank" disabled={tabState ? false : true}>*/}
                                                    <div className="card-header">
                                                        <h5 className="mt-5 font-weight-light">Bank Details</h5>
                                                    </div>
                                                    <form>
                                                        <div className="row mb-3">
                                                            {/* <div className="col-md-4">
                                                                    <div className="form-floating mb-3 mb-md-0">
                                                                        <select
                                                                            name="owner_type"
                                                                            defaultValue={artisan?.bank_detail?.owner_type}
                                                                            onChange={(e)=>setOwnerType(e.target.value)}
                                                                            className="form-control" id="inputCategories" name="owner_type" id="owner_type">
                                                                            <option>Select Owner Type</option>
                                                                            <option value="user">User</option>
                                                                            <option value="admin">Admin</option>
                                                                            <option value="artisan">Artisan</option>
                                                                        </select>
                                                                        <label htmlFor="inputCategories">Owner  Type</label>
                                                                    </div>
                                                                </div> */}

                                                            <div className="col-md-6">
                                                                <div className="form-floating mb-3 mb-md-0">
                                                                    <input className="form-control" id="inputFirstName"
                                                                        type="text"
                                                                        name="bank_name"
                                                                        defaultValue={artisan?.bank_detail?.bank_name}
                                                                        onChange={(e) => setBankName(e.target.value)}
                                                                        placeholder="Enter bank name" />
                                                                    <label htmlFor="inputFirstName">Bank Name</label>
                                                                </div>
                                                            </div>

                                                            <div className="col-md-6">
                                                                <div className="form-floating mb-3 mb-md-0">
                                                                    <select
                                                                        name="account_type"
                                                                        defaultValue={artisan?.bank_detail?.account_type}
                                                                        onChange={(e) => setAccountType(e.target.value)}
                                                                        className="form-control" id="inputCategories">
                                                                        <option>Select Account Type</option>
                                                                        <option value="savings">Savings</option>
                                                                        <option value="current">Current</option>
                                                                    </select>
                                                                    <label htmlFor="inputFirstName">Account Type</label>
                                                                </div>
                                                            </div>

                                                        </div>

                                                        <div className="row mb-3">
                                                            <div className="col-md-6">
                                                                <div className="form-floating">
                                                                    <input className="form-control" id="inputLastName"
                                                                        type="text"
                                                                        name="account_holder_name"
                                                                        defaultValue={artisan?.bank_detail?.account_holder_name}
                                                                        onChange={(e) => setAccountHolderName(e.target.value)}
                                                                        placeholder="Enter account name" />
                                                                    <label htmlFor="inputLastName">Account Holder Name</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="form-floating">
                                                                    <input className="form-control" id="inputLastName"
                                                                        type="text"
                                                                        name="account_number"
                                                                        defaultValue={artisan?.bank_detail?.account_number}
                                                                        onChange={(e) => setAccountNumber(e.target.value)}
                                                                        placeholder="Enter account number" />
                                                                    <label htmlFor="inputLastName">Account Number</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4 mt-4 mb-0">
                                                            <div className="d-grid">
                                                                <a
                                                                    onClick={updateBankFunc}
                                                                    className="btn btn-primary btn-block"
                                                                >
                                                                    {/*{isFetchingBank ? (*/}
                                                                    {/*    <Spinner/>*/}
                                                                    {/*) : (*/}
                                                                    <span>Submit{'  '}</span>
                                                                    {/*)}*/}
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </Tab>
                                            </Tabs>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                    <Footer />
                </div>
            </div>
        </>
    )
}
export default Edit;