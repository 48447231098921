import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SidebarNavbar from "../../components/Sidebar";
import Footer from "../../components/Footer";
import Spinner from '../../components/Loading'
import { bulkArtisan } from "../../redux/ApiCalls/artisanCalls";
import 'react-phone-input-2/lib/style.css'


const Bulk = ({ location }) => {

   
    const dispatch = useDispatch()
    const BASE_URL = process.env.REACT_APP_BASE_URL.replace('api/v1/','');
    

    
   
    const { isFetching } = useSelector(state => state.artisans)
    const [attachment, setAttachment] = useState([]);
   

    const createArtisan = (e) => {
        e.preventDefault()
        const formData = new FormData();       
        formData.append("file", attachment);      
        
        bulkArtisan(dispatch,(formData))
        
    }
    

    return (
        <>
            <div id="layoutSidenav">

                <SidebarNavbar />
                <div id="layoutSidenav_content">
                    <main>
                        <div className="container px-2">
                            <div className="d-flex justify-content-between align-items-baseline">
                                <h4 className="mt-4">Artisan Management</h4>
                                <div className="breadcrumb mb-4">
                                    <a className="btn btn-xs btn-primary" href="/artisan-management">List Artisan</a>
                                </div>
                            </div>
                            <div className="row justify-content-center">
                                <div className="col-lg-12">

                                    <div className="card shadow-lg border-0 rounded-lg mt-5">
                                        <div className="card-body">

                                            <div className="card-header">
                                                <h5 className="mt-5 font-weight-light arts">Bulk Upload</h5>
                                            </div>
                                            <form>
                                                <div className="row mb-3">
                                                    <div className="col-md-4">
                                                        <div className="form-floating mb-3 mb-md-0">                                                            
                                                            <label htmlFor="inputFirstName">Download Template</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-floating">
                                                            <a href={BASE_URL+'images/jambolo-template.xlsx'} className="btn btn-success" >Template</a>                                                           
                                                        </div>
                                                    </div>
                                                    
                                                </div>

                                                <div className="row mb-3">
                                                    <div className="col-md-4">
                                                        <div className="form-floating mb-3 mb-md-0">
                                                           
                                                            <label htmlFor="inputCategories">Upload</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-floating">
                                                            <input className="form-control" id="inputLastName"
                                                                        type="file"
                                                                        accept=".xlsx, .xls"
                                                                        onChange={(e) => setAttachment(e.target.files[0])}
                                                                         />
                                                        </div>
                                                    </div>
                                                    

                                                </div>

                                                
                                                <div className="col-md-4 mt-4 mb-0">
                                                    <div className="d-grid">
                                                        <a onClick={createArtisan} className="btn btn-primary btn-block">
                                                            {isFetching ? (
                                                                <Spinner />
                                                            ) : (
                                                                <span>Submit{'  '}</span>
                                                            )}
                                                        </a>
                                                    </div>
                                                </div>
                                            </form>
                                                
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                    <Footer />
                </div>
            </div>
        </>
    )
}
export default Bulk;