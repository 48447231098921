import {publicRequest, userRequest} from "../../requestMethod";
import Notification from "../../utils/notification";
import {
  logout,
  loginFailure,
  loginStart,
  loginSuccess,
  getAllUserStart,
  getAllUserSuccess,
  getAllUserFailure,
  suspendUserStart,
  suspendUserSuccess,
  suspendUserFailure
} from "../authRedux";

export const loginFunc = async (dispatch, user)=>{
  dispatch(loginStart());
  try{
    const res = await publicRequest.post("/admin/auth/login",user)
    dispatch(loginSuccess(res.data))
    if(res.data.success){
      
      if(res.data.data.admin.role=="SuperAdmin"){
        localStorage.setItem(
            "JamboloSuperAdmintoken",
            res.data.data.token
        );
        localStorage.setItem('user', JSON.stringify(res.data.data))
        Notification.success(res.data.message);
        window.location.href = "/s/dashboard"
      }
      else{
      localStorage.setItem(
          "JamboloAdmintoken",
          res.data.data.token
      );
      localStorage.setItem('user', JSON.stringify(res.data.data))
      Notification.success(res.data.message);
      window.location.href = "/dashboard"
      }
    }
  }catch (err){
    dispatch(loginFailure())
    Notification.error(err.response.data.message);
  }
};

export const resetFunc = async (dispatch, user)=>{
  dispatch(loginStart());
  try{
    const res = await publicRequest.post("/admin/auth/password/reset",user)
    dispatch(loginSuccess(res.data))
    if(res.data.success){
      
      if(res.data.data.admin.role=="SuperAdmin"){
        localStorage.setItem(
            "JamboloSuperAdmintoken",
            res.data.data.token
        );
        localStorage.setItem('user', JSON.stringify(res.data.data))
        Notification.success(res.data.message);
        window.location.href = "/s/dashboard"
      }
      else{
      localStorage.setItem(
          "JamboloAdmintoken",
          res.data.data.token
      );
      localStorage.setItem('user', JSON.stringify(res.data.data))
      Notification.success(res.data.message);
      window.location.href = "/dashboard"
      }
    }
  }catch (err){
    dispatch(loginFailure())
    Notification.error(err.response.data.message);
  }
};

export const getAllUsers = async (dispatch) => {
  // alert(5)
  dispatch(getAllUserStart());
  try {
    const res = await userRequest.get(`/admin/users`);
    dispatch(getAllUserSuccess(res.data.data.users));
    // Notification.success(res.data.message);
  } catch (err) {
    dispatch(getAllUserFailure());
    Notification.error(err.response.data.message);
  }
};

// Logout user
export const logoutFunc = async (dispatch) => {
  localStorage.clear();
  window.location.reload();
}


export const suspendUserFunc = async (id, dispatch) => {
  dispatch(suspendUserStart());
  try {
      const res = await userRequest.post(`/admin/users/${id}/suspend`);
      dispatch(suspendUserSuccess(id));
      Notification.success(res.data.message);
      window.location.reload();
  } catch (err) {
      if (err && err.response && err.response.status === 403) {
          localStorage.clear();
          window.location.href = "/signin";
      }else{
          dispatch(suspendUserFailure());
          Notification.error(err.response.data.message);
      }
  }
};

export const FeatureArtisanFunc = async (id, dispatch) => {
  dispatch(suspendUserStart());
  try {
      const res = await userRequest.post(`/admin/artisans/${id}/feature`);
      dispatch(suspendUserSuccess(id));
      Notification.success(res.data.message);
      window.location.reload();
  } catch (err) {
      if (err && err.response && err.response.status === 403) {
          localStorage.clear();
          window.location.href = "/signin";
      }else{
          dispatch(suspendUserFailure());
          Notification.error(err.response.data.message);
      }
  }
};




