import React, {useEffect, useState} from "react";
import Moment from "react-moment";
import {useDispatch, useSelector} from "react-redux";
import DataTableExtensions from "react-data-table-component-extensions";
import DataTable from "react-data-table-component";
import 'react-data-table-component-extensions/dist/index.css';
import SidebarNavbar from "../../components/Sidebar"
import Footer from "../../components/Footer";
import CustomLoader from "../../components/CustomLoader";
import {getAllUsers, suspendUserFunc} from "../../redux/ApiCalls/authCalls";
import NumberFormat from "react-number-format";
import Switch from "react-switch";

const Users = () => {
    const [suspendUser, SetSuspendPopup] = useState(false);
    const [userId, setUserId] = useState(null);
    const [status, setStatus] = useState(null);
    const hide = () => {
        SetSuspendPopup(false)
        document.body.style.overflow = 'hidden';
    }
    const SuspendUserPop = () => {
        SetSuspendPopup(true)
        document.body.style.overflow = 'hidden';
    }
    const SuspendFunc = () => {
        suspendUserFunc(userId, dispatch);
    }
    const dispatch = useDispatch()

    useEffect( () => {
        getAllUsers(dispatch)
    }, [dispatch])
    const users = useSelector((state) => state?.user?.users);

    const [columns, setColumns] = useState([
            {
                name: 'First Name',
                selector: 'first_name',
                sortable: true,
            },
            {
                name: 'Last Name',
                selector: 'last_name',
                sortable: true,
            },
            {
                name: 'Email',
                selector: 'email',
                sortable: true,
            },
            {
                name: 'Wallet Balance',
                selector: 'wallet_balance',
                sortable: true,
                cell: row => row?.wallet_balance &&
                    <div>
                        ₦<NumberFormat value={(row?.wallet_balance)} displayType={'text'} thousandSeparator={true} />
                    </div>
            },
            {
                name: ' Date Created',
                selector: 'created_at',
                sortable: true,
                cell: row => row?.created_at &&
                    <div>
                        <Moment format="D MMM YYYY">{row?.created_at}</Moment>
                    </div>
            },
            {
                name: ' Status',
                selector: 'created_at',
                sortable: true,
                cell: row => row?.created_at &&
                    <div>
                        <Switch checked={row.is_blocked==1?'checked':''} onChange={(e) => {
                            SuspendUserPop();
                            setUserId(row.id);
                            setStatus(row.is_blocked)
                        }}  />
                        
                    </div>
            },
            {
                name: 'Action',
                selector: 'created_at',
                sortable: true,
                cell: row => row.created_at &&
                    <div>
                        
                        
                        <a className="btn btn-xs btn-primary text-white" href={`view-transactions/${row.id}`}>transaction</a> 
                    </div>
            }
        ],
    )



    const data = users?.data;
    const totalRows = users?.total;
    const tableData = { columns, data };
    return (
            <>
                <div id="layoutSidenav">

                    <SidebarNavbar/>
                    <div id="layoutSidenav_content">
                        <main>
                            <div className="container-fluid px-4">
                              <div className="flex-display align-items-baseline">
                                  <h4 className="mt-4">Users Management</h4>
                              </div>

                                <div className="card mb-4">
                                    <div className="card-body">
                                        <DataTableExtensions {...tableData}>
                                            <DataTable
                                                columns={columns}
                                                data={data}
                                                noHeader
                                                defaultSortAsc={false}
                                                highlightOnHover
                                                // progressPending={orderLoading}
                                                progressComponent={<CustomLoader />}
                                                pagination={true}
                                                paginationServer={true}
                                                paginationResetDefaultPage={true}
                                                export={false}
                                                paginationPerPage={15}
                                                paginationTotalRows={totalRows}
                                                paginationRowsPerPageOptions={[15, 30, 45, 60, 75]}
                                                // onChangeRowsPerPage={handlePerRowsChanges}
                                                // onChangePage={handlePageChanges}
                                            />
                                        </DataTableExtensions>
                                    </div>
                                </div>
                            </div>
                        </main>
                        
                        <div>
                        {suspendUser && (
                            <div style={{
                                position: "fixed", /* Sit on top of the page content */
                                width: "100%", /* Full width (cover the whole page) */
                                height: "100%", /* Full height (cover the whole page) */
                                top: "0",
                                left: "0",
                                right: "0",
                                bottom: "0",
                                backgroundColor: "rgba(0, 0, 0, 0.7)", /* #333333 background with opacity */
                                zIndex: "1110"
                            }} className="overflow-auto">
                                <div
                                    style={{
                                        height: "100vh",
                                        display: "flex",
                                        alignItems: "center",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                    }}
                                >
                                    <div
                                        style={{
                                            padding: "24px",
                                            gap: "40px",
                                            position: "relative",
                                            width: "22%",
                                            background: "#FFFFFF",
                                            borderRadius: "12px",
                                        }}
                                        className="col-6 col-md-6">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <label htmlFor="email">Are you sure you want to {status==1?'un':''}suspend the user</label>
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-md-12">

                                                <button
                                                    onClick={SuspendFunc}
                                                    style={{ fontSize: "14px", width: "35%" }} type="button" className="btn btn-primary btn-sm text-white mx-2 my-2" data-bs-toggle="tooltip" data-bs-placement="top"
                                                    title="500 Naira Charges Apply">
                                                    {/* {isFetching ? (
                                                    <Spinner />
                                                ) : ( */}
                                                    <span>Yes{'  '}</span>
                                                    {/* )} */}
                                                </button>

                                                <button
                                                    onClick={() => hide(false)}
                                                    className="btn btn-danger btn-sm text-white mx-2 my-2"
                                                    style={{ width: "35%", float: "right" }}
                                                >
                                                    <span>No{'  '}</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                        <Footer/>
                    </div>
                </div>
            </>
        )
    }

export default Users;