import {publicRequest, userRequest} from "../../requestMethod";
import Notification from "../../utils/notification";
import {
    getAssociationFailure,
    getAssociationStart,
    getAssociationSuccess,
    deleteAssociationFailure,
    deleteAssociationStart,
    deleteAssociationSuccess,
    updateAssociationFailure,
    updateAssociationStart,
    updateAssociationSuccess,
    addAssociationFailure,
    addAssociationStart,
    addAssociationSuccess,
} from "../associationRedux";

//

export const addAssociation = async (dispatch, Association) => {
    dispatch(addAssociationStart());
    try {
        const res = await userRequest.post(`/admin/associations`, Association);
        console.log("post data", res.data.association.data)
        dispatch(addAssociationSuccess(res.data.association.data));
        Notification.success(res.data.message);
        window.location.href="/";
    } catch (err) {
        if (err && err.response && err.response.status === 403) {
            localStorage.clear();
            window.location.href = "/signin";
        }else{
            dispatch(addAssociationFailure());
            Notification.error(err.response.data.message);
        }
    }
};

export const getAssociation = async (dispatch) => {
    dispatch(getAssociationStart());
    try {
        const res = await userRequest.get("/admin/associations");
        dispatch(getAssociationSuccess(res.data.data.associations.data));
        // Notification.success(res.data.status.msg);
        return res.data.data.associations.data;
    } catch (err) {
        if (err && err.response && err.response.status === 403) {
            localStorage.clear();
            window.location.href = "/signin";
        }else{
            dispatch(getAssociationFailure());
            Notification.error(err.response.data.message);
        }

    }
};

//
export const deleteAssociationFunc = async (id, dispatch) => {
    dispatch(deleteAssociationStart());
    try {
        const res = await userRequest.delete(`/admin/associations/${id}`);
        dispatch(deleteAssociationSuccess(id));
        Notification.success(res.data.status.msg);
        window.location.reload();
    } catch (err) {
        if (err && err.response && err.response.status === 403) {
            localStorage.clear();
            window.location.href = "/signin";
        }else{
            dispatch(deleteAssociationFailure());
            Notification.error(err.response.data.message);
        }

    }
};
