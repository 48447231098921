import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SidebarNavbar from "../../components/Sidebar";
import Footer from "../../components/Footer";
import Spinner from '../../components/Loading'
import { addAdmin } from "../../redux/ApiCalls/superadminCalls";
import AsyncSelect from 'react-select/async';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'


const Add = ({ location }) => {

    
    const [selectedOptions, setSelectedOptions] = useState();
    
    const [first_name, setFirstname] = useState("")
    const [phone, setPhone] = useState("")
    const [designation, setDesignation] = useState("")
    
    const [last_name, setLastname] = useState("")
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const dispatch = useDispatch()
    const { isFetching } = useState(false)
    

    const createAdmin = (e) => {
        e.preventDefault()
        const data = {
            "first_name": first_name ,
            "last_name": last_name ,
            "phone": phone ,
            "email": email ,
            "password": password,
            "role": designation,
            "callbackContactUrl":"resetPassword"
        };
        
        
        addAdmin(dispatch,(data))
        
    }
  

    return (
        <>
            <div id="layoutSidenav">

                <SidebarNavbar />
                <div id="layoutSidenav_content">
                    <main>
                        <div className="container px-2">
                            <div className="d-flex justify-content-between align-items-baseline">
                                <h4 className="mt-4">Admin Users</h4>
                                <div className="breadcrumb mb-4">
                                    <a className="btn btn-xs btn-primary" href="/admin-accounts">List Admin Users</a>
                                </div>
                            </div>
                            <div className="row justify-content-center">
                                <div className="col-lg-12">

                                    <div className="card shadow-lg border-0 rounded-lg mt-5">
                                        <div className="card-body">
                                            <div className="card-header">
                                                <h5 className="mt-5 font-weight-light arts">Admin Details</h5>
                                            </div>
                                            <form>
                                                <div className="row mb-3">
                                                    <div className="col-md-4">
                                                        <div className="form-floating mb-3 mb-md-0">
                                                            <input
                                                                className="form-control"
                                                                id="inputFirstName"
                                                                type="text"
                                                                name="first_name"
                                                                value={selectedOptions}
                                                                onChange={(e) => setFirstname(e.target.value)}
                                                                placeholder="Enter first name"
                                                                required
                                                            />
                                                            <label htmlFor="inputFirstName">First name</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-floating">
                                                            <input
                                                                className="form-control"
                                                                id="inputLastName"
                                                                type="text"
                                                                name="last_name"
                                                                onChange={(e) => setLastname(e.target.value)}
                                                                placeholder="Enter last name"
                                                                required
                                                            />
                                                            <label htmlFor="inputLastName">Last name</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-floating">
                                                            <input
                                                                className="form-control"
                                                                id="email"
                                                                type="text"
                                                                name="email"
                                                                onChange={(e) => setEmail(e.target.value)}
                                                                placeholder="Enter Email Address"
                                                                required
                                                            /> 
                                                            <label htmlFor="email">Email Address</label>
                                                        </div>
                                                    </div>
                                                </div>

                                                
                                                <div className="row mb-3">
                                                    
                                                    <div className="col-md-4">
                                                        

                                                        <div className="form-floating mb-3 mb-md-0">
                                                            <PhoneInput
                                                                placeholder="08012345678"
                                                                // onInput={(e) => e.target.value = e.target.value.slice(0, 11)} // js validation to check that number does not exceed 11 characters
                                                                value={phone}
                                                                country={'ng'}
                                                                enableSearch={true}
                                                                countryCodeEditable={false}
                                                                inputProps={{ name: 'phone', required: true, autoFocus: true, maxLength: "17" }}
                                                                onChange={setPhone}
                                                                style={{ width: "100%" }}
                                                            />
                                                            
                                                        </div>
                                                    </div>

                                                    <div className="col-md-4">
                                                        

                                                        <div className="form-floating mb-3 mb-md-0">
                                                            <select
                                                                    className="form-control"
                                                                    name="role"
                                                                    onChange={(e) => setDesignation(e.target.value)}
                                                                    id="role">
                                                                    <option>Select Designation</option>
                                                                    <option>Admin</option>
                                                                    <option>SuperAdmin</option>
                                                                                                                                
                                                                </select>
                                                            
                                                        </div>
                                                    </div>

                                                </div>

                                                <div className="col-md-4 mt-4 mb-0">
                                                    <div className="d-grid">
                                                        <a onClick={createAdmin} className="btn btn-primary btn-block">
                                                            {isFetching ? (
                                                                <Spinner />
                                                            ) : (
                                                                <span>Submit{'  '}</span>
                                                            )}
                                                        </a>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                    <Footer />
                </div>
            </div>
        </>
    )
}
export default Add;