import React, { useEffect, useState } from "react";
import SidebarNavbar from "../../components/Sidebar"
import Footer from "../../components/Footer";
import CustomLoader from "../../components/CustomLoader";
import DataTableExtensions from "react-data-table-component-extensions";
import DataTable from "react-data-table-component";
import 'react-data-table-component-extensions/dist/index.css';
import { getProducts, deleteProductFunc, handlePageChange, handlePerRowsChange } from "../../redux/ApiCalls/productCalls";
import { useDispatch, useSelector } from "react-redux";
import Moment from "react-moment";

const MarketPlace = () => {
    const dispatch = useDispatch()

    useEffect(() => {
        getProducts('',dispatch)
    }, [dispatch]);

    const handleSearchProduct= async(e) => {
        getProducts(e.target.value, dispatch);
      }

    const [columns, setColumns] = useState([
        {
            name: 'logo',
            sortable: true,
            cell: row => row.featured_image ?
                <img src={row.featured_image} height={20} width={20} />          
                
                :
                <img src={row.logo} height={20} width={20}/>
                
        },
        {
            name: 'Product Name',
            selector: 'name',
            sortable: true,
        },
        {
            name: 'Artisan Name',
            selector: 'artisan',
            sortable: true,
            cell: row => row.artisan ?
                <div>
                    {row?.artisan?.first_name} {" "} {row?.artisan?.last_name}
                </div>
                :
                <div>
                    N/A

                </div>

        },
        {
            name: 'Amount',
            selector: 'amount',
            sortable: true,
        },
        {
            name: 'Description',
            selector: 'description',
            sortable: true,
        },
        {
            name: 'Created',
            selector: 'created_at',
            sortable: true,
            cell: row => row.created_at ?
                <div>
                    <Moment format="D MMM YYYY">{row.created_at}</Moment>
                </div>
                :
                <div>

                </div>
        },
        {
            name: 'Action',
            selector: 'created_at',
            sortable: true,
            cell: row => row.created_at &&
                <div>
                    <a className="btn btn-xs btn-primary text-white" style={{fontSize: '10px'}}  href={`view-marketplace/${row.id}`}>view</a> {"  "}
                    <button
                        onMouseUp={(e) => {
                            deleteProductPop();
                            setProductId(row.id);
                        }}
                        type="submit"
                        style={{fontSize: '10px'}} 
                        className="btn btn-xs btn-danger text-white" >delete</button>
                </div>
        }
    ],
    )

    const [deleteProduct, SetDeletePopup] = useState(false)
    const [productId, setProductId] = useState(null)

    const deleteFunc = () => {
        deleteProductFunc(productId, dispatch);
    }

    const handlePageChanges = (pageNum, perPage) => {
        handlePageChange(pageNum, 15, dispatch);
    }

    const handlePerRowsChanges = (perPage, page) => {
        handlePerRowsChange(page, perPage, dispatch);
    }

    const deleteProductPop = () => {
        SetDeletePopup(true)
        document.body.style.overflow = 'hidden';
    }

    const hide = () => {
        SetDeletePopup(false)
        document.body.style.overflow = 'hidden';
    }
    const products = useSelector((state) => state.products.products);
    const data = products.data;
    const totalRows = products.total
    const tableData = { columns, data };

    return (
        <>
            <div id="layoutSidenav">

                <SidebarNavbar />
                <div id="layoutSidenav_content">
                    <main>
                        <div className="container-fluid px-4">
                            <div className="d-flex justify-content-between align-items-baseline">
                                <h5 className="mt-4">Marketplace Management</h5>
                                <div className="breadcrumb mb-4">
                                    <a className="btn btn-xs btn-primary" href="/add-marketplace">Add Product</a>
                                </div>
                            </div>

                            <div className="card mb-4">
                                <div className="card-body">
                                <div className="form-floating" style={{ width: "20%", float: "right", marginBottom: "10px" }}>
                                    <input className="form-control" id="inputLastName"
                                            type="text"
                                            name="amount"
                                            onChange={(e)=>handleSearchProduct(e)}
                                            placeholder="Enter product name"/>
                                                                   
                                        <label htmlFor="inputLastName">Filter By Product Name</label>
                                    </div>
                                    <DataTableExtensions {...tableData}>
                                        <DataTable
                                            columns={columns}
                                            data={data}
                                            noHeader
                                            defaultSortAsc={false}
                                            highlightOnHover
                                            // progressPending={orderLoading}
                                            progressComponent={<CustomLoader />}
                                            pagination={true}
                                            paginationServer={true}
                                            paginationResetDefaultPage={true}
                                            export={false}
                                            paginationPerPage={15}
                                            paginationTotalRows={totalRows}
                                            paginationRowsPerPageOptions={[15, 30, 45, 60, 75]}
                                            onChangeRowsPerPage={handlePerRowsChanges}
                                            onChangePage={handlePageChanges}
                                        />
                                    </DataTableExtensions>
                                </div>
                            </div>

                        </div>
                    </main>

                    <div>
                        {deleteProduct && (
                            <div style={{
                                position: "fixed", /* Sit on top of the page content */
                                width: "100%", /* Full width (cover the whole page) */
                                height: "100%", /* Full height (cover the whole page) */
                                top: "0",
                                left: "0",
                                right: "0",
                                bottom: "0",
                                backgroundColor: "rgba(0, 0, 0, 0.7)", /* #333333 background with opacity */
                                zIndex: "1110"
                            }} className="overflow-auto">
                                <div
                                    style={{
                                        height: "100vh",
                                        display: "flex",
                                        alignItems: "center",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                    }}
                                >
                                    <div
                                        style={{
                                            padding: "24px",
                                            gap: "40px",
                                            position: "relative",
                                            width: "22%",
                                            background: "#FFFFFF",
                                            borderRadius: "12px",
                                        }}
                                        className="col-6 col-md-6">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <label htmlFor="email">Are you sure you want to delete the record</label>
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-md-12">

                                                <button
                                                    onClick={deleteFunc}
                                                    style={{ fontSize: "14px", width: "35%" }} type="button" className="btn btn-primary btn-sm text-white mx-2 my-2" data-bs-toggle="tooltip" data-bs-placement="top"
                                                    title="500 Naira Charges Apply">
                                                    {/* {isFetching ? (
                                                    <Spinner />
                                                ) : ( */}
                                                    <span>Yes{'  '}</span>
                                                    {/* )} */}
                                                </button>

                                                <button
                                                    onClick={() => hide(false)}
                                                    className="btn btn-danger btn-sm text-white mx-2 my-2"
                                                    style={{ width: "35%", float: "right" }}
                                                >
                                                    <span>No{'  '}</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>

                    <Footer />
                </div>
            </div>
        </>
    )
}

export default MarketPlace;