import {publicRequest, userSuperRequest, userSuperRequestFile} from "../../requestMethod";
import Notification from "../../utils/notification";
import {
    getSingleArtisanFailure,
    getSingleArtisanStart,
    getSingleArtisanSuccess,
    getArtisanFailure,
    getArtisanStart,
    getArtisanSuccess,
    deleteArtisanFailure,
    deleteArtisanStart,
    deleteArtisanSuccess,
    updateArtisanFailure,
    updateArtisanStart,
} from "../artisanRedux";

//



export const getMarketReports = async (dispatch, status) => {
    // alert(5)
    try {
        const res = await userSuperRequest.get(`/admin/reports/market?include=transaction,product`);
        // dispatch(getArtisanSuccess(res?.data?.data?.users));
        // Notification.success(res.data.message);
        return res?.data?.data?.transactions;
    } catch (err) {
        if (err && err.response && err.response.status === 403) {
            localStorage.clear();
            window.location.href = "/signin";
        }else{
            dispatch(getArtisanFailure());
            Notification.error(err.response.data.message);
        }
    }
};

export const getMarketReportExcel = async (dispatch, startDate, endDate) => {
    // alert(5)
    try {
        const res = await userSuperRequest.get(`/admin/reports/market?include=transaction,product&startDate=${startDate}&endDate=${endDate}&excel=excel`);
        // dispatch(getArtisanSuccess(res?.data?.data?.users));
        // Notification.success(res.data.message);
        return res;
    } catch (err) {
        if (err && err.response && err.response.status === 403) {
            localStorage.clear();
            window.location.href = "/signin";
        }else{
            dispatch(getArtisanFailure());
            Notification.error(err.response.data.message);
        }
    }
};

export const getReports = async (dispatch, status) => {
    // alert(5)
    try {
        const res = await userSuperRequest.get(`/admin/reports?include=user,artisan&filter[payment_purpose]=${status}`);
        // dispatch(getArtisanSuccess(res?.data?.data?.users));
        // Notification.success(res.data.message);
        return res?.data?.data?.transactions;
    } catch (err) {
        if (err && err.response && err.response.status === 403) {
            localStorage.clear();
            window.location.href = "/signin";
        }else{
            dispatch(getArtisanFailure());
            Notification.error(err.response.data.message);
        }
    }
};

export const getReportByDate = async (dispatch, startDate, endDate) => {
    // alert(5)
    try {
        const res = await userSuperRequest.get(`/admin/reports?include=user,artisan&filter[payment_purpose]=callout-charge&startDate=${startDate}&endDate=${endDate}`);
        // dispatch(getArtisanSuccess(res?.data?.data?.users));
        // Notification.success(res.data.message);
        return res?.data?.data?.transactions;
    } catch (err) {
        if (err && err.response && err.response.status === 403) {
            localStorage.clear();
            window.location.href = "/signin";
        }else{
            dispatch(getArtisanFailure());
            Notification.error(err.response.data.message);
        }
    }
};

export const getReportExcel = async (dispatch, startDate, endDate) => {
    // alert(5)
    try {
        
        const res = await userSuperRequestFile.get(`/admin/reports?include=user,artisan&filter[payment_purpose]=callout-charge&startDate=${startDate}&endDate=${endDate}&excel=excel`);
        // dispatch(getArtisanSuccess(res?.data?.data?.users));
        // Notification.success(res.data.message);
        
        return res;
    } catch (err) {
        if (err && err.response && err.response.status === 403) {
            localStorage.clear();
            window.location.href = "/signin";
        }else{
            dispatch(getArtisanFailure());
            Notification.error(err.response.data.message);
        }
    }
};

export const handlePageChange = async (pageNum, perPage, dispatch) => {
    // alert(5)
    dispatch(getArtisanStart());
    try {
        const res = await userSuperRequest.get(`/admin/reports?page=${pageNum}&limit=${perPage}`);
        dispatch(getArtisanSuccess(res.data.data.transactions));
        // Notification.success(res.data.message);
    } catch (err) {
        dispatch(getArtisanFailure());
        Notification.error(err.response.data.message);
    }
};

export const handlePerRowsChange = async (page, perPage, dispatch) => {
    // alert(5)
    dispatch(getArtisanStart());
    try {
        const res = await userSuperRequest.get(`/admin/reports?page=${page}&limit=${perPage}`);
        dispatch(getArtisanSuccess(res.data.data.transactions));
        // Notification.success(res.data.message);
    } catch (err) {
        dispatch(getArtisanFailure());
        Notification.error(err.response.data.message);
    }
};
