import React, { useEffect, useState } from "react";
import SidebarNavbar from "../../components/Sidebar"
import Footer from "../../components/Footer";
import Spinner from '../../components/Loading';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { getAdmin, updateAdmin } from "../../redux/ApiCalls/superadminCalls";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { getBank, updateBank } from "../../redux/ApiCalls/bankCalls";
import { getCategories, getStates, getCities } from "../../redux/ApiCalls/categoryCalls";
import { getAssociation } from "../../redux/ApiCalls/associationCalls";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

const View = () => {
    const dispatch = useDispatch()
    const location = useLocation();
    const id = location.pathname.split('/')[2];
    useEffect(() => {
        getAdmin(id, dispatch);
    }, [dispatch])

    useEffect(() => {
           
    }, [dispatch]);

    const artisan = useSelector((state) => state.artisan.artisan);
    const [first_name, setFirstname] = useState("")
    const [last_name, setLastname] = useState("")
    const [phone, setPhone] = useState("")
    const [email, setEmail] = useState("")
    const [business_name, setBusinessname] = useState("")
    const [address, setAddress] = useState("")
    

    
    
   

   


   
    return (
        <>
            <div id="layoutSidenav">
                <SidebarNavbar />
                <div id="layoutSidenav_content">
                    <main>
                        <div className="container px-2">
                            <div className="d-flex justify-content-between align-items-baseline">
                                <h4 className="mt-4">Admin Users</h4>
                                <div className="breadcrumb mb-4">
                                    <a className="btn btn-xs btn-primary" href="/add-admin">Add Admin</a>|
                                    <a className="btn btn-xs btn-primary" href="/admin-accounts">List Admins</a>
                                </div>
                                
                            </div>
                            <div className="row justify-content-center">
                                <div className="col-lg-12">
                                    <div className="card shadow-lg border-0 rounded-lg mt-5">
                                        <div className="card-header">
                                            <h5 className=" my-4">Admin Information</h5>
                                        </div>
                                        <div className="card-body">
                                          
                                               
                                                    <div className="card-header">
                                                        <h5 className="mt-5 font-weight-light">Admin Details</h5>
                                                    </div>
                                                        <div className="row mb-3">
                                                        <img src={artisan?.logo} style={{width: `50%`, height: 90}} /> 
                                                        </div>
                                                        <div className="row mb-3">
                                                            <div className="col-md-4">
                                                                <div className="form-floating mb-3 mb-md-0">
                                                                    <input className="form-control"
                                                                        id="first_name"
                                                                        type="text"
                                                                        name="first_name"
                                                                        readOnly
                                                                        defaultValue={artisan?.first_name}
                                                                        onChange={(e) => setFirstname(e.target.value)}
                                                                        placeholder="Enter first name" />
                                                                    <label htmlFor="inputFirstName">First name</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="form-floating">
                                                                    <input className="form-control"
                                                                        // id="inputLastName"
                                                                        type="text"
                                                                        name="last_name"
                                                                        readOnly
                                                                        defaultValue={artisan?.last_name}
                                                                        onChange={(e) => setLastname(e.target.value)}
                                                                        placeholder="Enter last name" />
                                                                    <label htmlFor="inputLastName">Last name</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="form-floating">
                                                                    <input className="form-control" id="inputLastName"
                                                                        type="text"
                                                                        name="business_name"
                                                                        readOnly
                                                                        defaultValue={artisan?.email} />
                                                                    <label htmlFor="inputLastName">Email Address</label>
                                                                </div>
                                                            </div>
                                                        </div>

                                                      

                                                        <div className="row mb-3">
                                                            
                                                            
                                                            <div className="col-md-4">
                                                                <div className="form-floating mb-3 mb-md-0">
                                                                    <input className="form-control" id="inputFirstName"
                                                                        type="text"
                                                                        name="address"
                                                                        readOnly
                                                                        defaultValue={artisan?.phone}
                                                                    />
                                                                    <label htmlFor="inputFirstName">Mobile</label>
                                                                </div>
                                                            </div>
                                                            
                                                        </div>

                                                                                                              
                                                    

                                                   
                                               
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                    <Footer />
                </div>
            </div>
        </>
    )
}
export default View;