import { createSlice } from "@reduxjs/toolkit";

export const bankSlice = createSlice({
    name: "bank",
    initialState: {
        bank:null,
        banks: [],
        isFetchingBank: false,
        error: false,
    },
    reducers: {
        //GET ALL
        getBankStart: (state) => {
            state.isFetchingBank = true;
            state.error = false;
        },
        getBankSuccess: (state, action) => {
            state.isFetchingBank = false;
            state.banks = action.payload;
        },
        getBankFailure: (state) => {
            state.isFetchingBank = false;
            state.error = true;
        },
        //DELETE
        deleteBankStart: (state) => {
            state.isFetchingBank = true;
            state.error = false;
        },
        deleteBankSuccess: (state, action) => {
            state.isFetchingBank = false;
            // state.Banks.splice(
            //   state.Banks.findIndex((item) => item._id === action.payload),
            //   1
            // );
            // state.Banks = {...state.Banks};
        },
        //GET Single Bank
        getSingleBankStart: (state) => {
            state.isFetching = true;
            state.error = false;
        },
        getSingleBankSuccess: (state, action) => {
            state.isFetching = false;
            state.bank = action.payload;
        },
        getSingleBankFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },
        deleteBankFailure: (state) => {
            state.isFetchingBank = false;
            state.error = true;
        },
        //UPDATE
        updateBankStart: (state) => {
            state.isFetchingBank = true;
            state.error = false;
        },
        updateBankSuccess: (state, action) => {
            state.isFetchingBank = false;
            state.banks[
                state.banks.findIndex((item) => item._id === action.payload.id)
                ] = action.payload.bank;
        },
        updateBankFailure: (state) => {
            state.isFetchingBank = false;
            state.error = true;
        },
        //UPDATE
        addBankStart: (state) => {
            state.isFetchingBank = true;
            state.error = false;
        },
        addBankSuccess: (state, action) => {
            state.isFetchingBank = false;
            state.banks.push(action.payload);
        },
        addBankFailure: (state) => {
            state.isFetchingBank = false;
            state.error = true;
        },
    },
});

export const {
    getBankStart,
    getBankSuccess,
    getBankFailure,
    deleteBankStart,
    deleteBankSuccess,
    deleteBankFailure,
    getSingleBankFailure,
    getSingleBankStart,
    getSingleBankSuccess,
    updateBankStart,
    updateBankSuccess,
    updateBankFailure,
    addBankStart,
    addBankSuccess,
    addBankFailure,
} = bankSlice.actions;

export default bankSlice.reducer;
