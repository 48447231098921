import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SidebarNavbar from "../../components/Sidebar"
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Footer from "../../components/Footer";
import Spinner from '../../components/Loading'
import { addArtisan } from "../../redux/ApiCalls/artisanCalls";
import { addBank } from "../../redux/ApiCalls/bankCalls";
import { getCategories } from "../../redux/ApiCalls/categoryCalls";
import { getAssociation } from "../../redux/ApiCalls/associationCalls";
import { getCountries, getStates, getCities } from "../../redux/ApiCalls/genericLocationsCalls";
import AsyncSelect from 'react-select/async';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'


const Add = ({ location }) => {

    
    const [selectedOptions, setSelectedOptions] = useState();
    // const handleVendFlow = async(e) => {
    //     await setVendor(e)
    //     setCurrency(e?.currency_code)
    //   }
    // const loadPayabletoOptions = ( callback) => {
    //     // perform a request


    //     axios.get(`${baseUrl}/vendors/${inputValue}`, config)
          
    //       .then(res =>{
    //         const data =[];
    //         res?.data?.data?.accounts.forEach(function (item, index) {
    //           var m={'value':item.id,'label': item.name, 'currency_code': item.currency_code};
    //           data.push(m);
    //       }); 
            
    //         callback(data)})
         
    //       .then(res => setLoading(false))
    //       .catch(err => console.log(err))
        
    //   }
    //Artusan States
    const [tabState, setTabState] = useState(localStorage.getItem("tabstate"))
    const [first_name, setFirstname] = useState("")
    const [phone, setPhone] = useState("")
    const [last_name, setLastname] = useState("")
    const [email, setEmail] = useState("")
    const [business_name, setBusinessname] = useState("")
    const [address, setAddress] = useState("")
    const [state_id, setState] = useState("") //lagos 2671  abia 2647
    const [city_id, setCity] = useState("") //lagos30984 30983  abia 30692
    const [category_id, setCategory] = useState("")
    const [country_id, setCountry] = useState("")
    const [association_id, setAssociation] = useState("")
    const [photo, setPhoto] = useState(null)
    //Banks state
    const [owner_type, setOwnerType] = useState("artisan");
    const [bank_name, setBankName] = useState("");
    const [owner_id, setOwnerOd] = useState(localStorage.getItem("artisan_id"));
    const [account_type, setAccountType] = useState("");
    const [account_number, setAccountNumber] = useState("");
    const [account_holder_name, setAccountHolderName] = useState("");
    const [categories, setCategories] = useState({});
    const dispatch = useDispatch()

    const handleStateFlow = async(e) => {
        setCountry(e.target.value)
        getStates(e.target.value, dispatch);
      }
      const handleCityFlow = async(e) => {
        setState(e.target.value)
        getCities(e.target.value, dispatch);
      }
      
    // console.log("jrgjjgjfgjfgjgjgjgf", tabState)
    useEffect(() => {
        getCategories(dispatch).then(res=>{
            setCategories(res);
        });
        
        getAssociation(dispatch);
    }, [dispatch]);
    // useEffect(() => {
    //     getAssociation(dispatch);
    // }, [dispatch]);

    useEffect(() => {
        getCountries(dispatch);
    }, [dispatch]);

    // if(country_id){
    //         getStates(country_id, dispatch);
    // }

    // if(state_id){
    //         getCities(state_id, dispatch);
    // }
    
    const associations = useSelector((state) => state.associations.associations);
    const countries = useSelector((state) => state.countries.countries);
    const states = useSelector((state) => state.states.states);
    const cities = useSelector((state) => state.cities.cities);
    const { isFetching } = useSelector(state => state.artisans)
    const { isFetchingBank } = useSelector(state => state.banks)
    const [attachment, setAttachment] = useState([]);
    // const handleImage = (e) => {
    //     const reader = new FileReader();
    //     reader.readAsDataURL(e);
    //     setPhoto(e);
    //     reader.onload = function () {
    //     };
    // };

    const createArtisan = (e) => {
        e.preventDefault()
        const formData = new FormData();
        formData.append("first_name", first_name);
        formData.append("last_name", last_name);
        formData.append("business_name", business_name);
        formData.append("address", address);
        formData.append("phone", phone);
        formData.append("state_id", state_id);
        formData.append("city_id", city_id);
        formData.append("category_id", category_id);
        formData.append("association_id", association_id);
        formData.append("logo", attachment);
        
        
        addArtisan(dispatch,(formData))
        // addArtisan(dispatch, {
        //     first_name,
        //     last_name,
        //     phone,
        //     business_name,
        //     address,
        //     state_id,
        //     city_id,
        //     category_id,
        //     association_id,
        //     photo
        // })
    }
    const createBank = (e) => {
        e.preventDefault()
        addBank(dispatch, {
            owner_type,
            owner_id,
            bank_name,
            account_type,
            account_number,
            account_holder_name
        })
    }

    return (
        <>
            <div id="layoutSidenav">

                <SidebarNavbar />
                <div id="layoutSidenav_content">
                    <main>
                        <div className="container px-2">
                            <div className="d-flex justify-content-between align-items-baseline">
                                <h4 className="mt-4">Artisan Management</h4>
                                <div className="breadcrumb mb-4">
                                    <a className="btn btn-xs btn-primary" href="/artisan-management">List Artisan</a>
                                </div>
                            </div>
                            <div className="row justify-content-center">
                                <div className="col-lg-12">

                                    <div className="card shadow-lg border-0 rounded-lg mt-5">
                                        <div className="card-body">

                                            <Tabs
                                                // defaultActiveKey="artisan"
                                                defaultActiveKey={tabState ? "bank" : "artisan"}
                                                id="fill-tab-example"
                                                className="mb-3"
                                                fill
                                            >
                                                <Tab eventKey="artisan" title="Create Artisan">
                                                    <div className="card-header">
                                                        <h5 className="mt-5 font-weight-light arts">Artisan Details</h5>
                                                    </div>
                                                    <form>
                                                        <div className="row mb-3">
                                                            <div className="col-md-4">
                                                                <div className="form-floating mb-3 mb-md-0">
                                                                    <input
                                                                        className="form-control"
                                                                        id="inputFirstName"
                                                                        type="text"
                                                                        name="first_name"
                                                                        value={selectedOptions}
                                                                        onChange={(e) => setFirstname(e.target.value)}
                                                                        placeholder="Enter first name"
                                                                        required
                                                                    />
                                                                    <label htmlFor="inputFirstName">First name</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="form-floating">
                                                                    <input
                                                                        className="form-control"
                                                                        id="inputLastName"
                                                                        type="text"
                                                                        name="last_name"
                                                                        onChange={(e) => setLastname(e.target.value)}
                                                                        placeholder="Enter last name"
                                                                        required
                                                                    />
                                                                    <label htmlFor="inputLastName">Last name</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="form-floating">
                                                                    <input
                                                                        className="form-control"
                                                                        id="inputLastName"
                                                                        type="text"
                                                                        name="business_name"
                                                                        onChange={(e) => setBusinessname(e.target.value)}
                                                                        placeholder="Enter business name"
                                                                        required
                                                                    />
                                                                    <label htmlFor="inputLastName">Business name</label>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="row mb-3">
                                                            <div className="col-md-4">
                                                                <div className="form-floating mb-3 mb-md-0">
                                                                    <select
                                                                        className="form-control"
                                                                        name="country_id"  
                                                                        onChange={handleStateFlow}
                                                                        id="country_id">
                                                                        <option>Select Country</option>
                                                                        {countries?.map((item, idx) =>
                                                                            <option key={idx} value={item.id}>{item.name}</option>
                                                                        )}
                                                                    </select>
                                                                    
                                                                    <label htmlFor="inputCategories">Country</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="form-floating">
                                                                    <select
                                                                        className="form-control"
                                                                        name="state_id"
                                                                        onChange={handleCityFlow}
                                                                        id="state_id">
                                                                        <option>Select State</option>
                                                                        {states?.map((item, idx) =>
                                                                            <option key={idx} value={item.id}>{item.name}</option>
                                                                        )}
                                                                    </select>
                                                                    <label htmlFor="inputLastName">State</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="form-floating">
                                                                    <select
                                                                        className="form-control"
                                                                        name="city_id"
                                                                        onChange={(e) => {
                                                                            setCity(e.target.value)
                                                                        }}
                                                                        id="city_id">
                                                                        <option>Select City</option>
                                                                        {cities?.map((item, idx) =>
                                                                            <option key={idx} value={item.id}>{item.name}</option>
                                                                        )}
                                                                    </select>
                                                                    <label htmlFor="inputLastName">City</label>
                                                                </div>
                                                            </div>

                                                        </div>

                                                        <div className="row mb-3">
                                                            <div className="col-md-4">
                                                                <div className="form-floating mb-3 mb-md-0">
                                                                    <select
                                                                        className="form-control"
                                                                        name="category_id"
                                                                        onChange={(e) => setCategory(e.target.value)}
                                                                        id="category_id">
                                                                        <option>Select Category</option>
                                                                        {categories?.data?.map((item, idx) =>
                                                                            <option key={idx} value={item.id}>{item.name}</option>
                                                                        )}
                                                                    </select>
                                                                    <label htmlFor="inputCategories">Category</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="form-floating">
                                                                    <select
                                                                        className="form-control"
                                                                        name="association_id"
                                                                        onChange={(e) => setAssociation(e.target.value)}
                                                                        id="association_id">
                                                                        <option>Select Association</option>
                                                                        {associations.map((item, idx) =>
                                                                            <option key={idx} value={item.id}>{item.name}</option>
                                                                        )}
                                                                    </select>
                                                                    <label htmlFor="inputLastName">Association</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="form-floating">
                                                                    <input className="form-control" id="inputLastName"
                                                                        type="file"
                                                                        accept="image/png, image/gif, image/jpeg"
                                                                        onChange={(e) => setAttachment(e.target.files[0])}
                                                                        placeholder="Enter city" />
                                                                    <label htmlFor="inputLastName">Passport Photo Upload</label>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="row mb-3">
                                                            <div className="col-md-4">
                                                                <label htmlFor="inputFirstName">Address</label>

                                                                <div className="form-floating mb-3 mb-md-0">
                                                                    <input
                                                                        className="form-control"
                                                                        id="inputFirstName"
                                                                        type="text"
                                                                        placeholder="Enter address"
                                                                        name="address"
                                                                        onChange={(e) => setAddress(e.target.value)}
                                                                        required
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">

                                                                <label htmlFor="inputMobile">Mobile</label>

                                                                <div className="form-floating mb-3 mb-md-0">
                                                                    <PhoneInput
                                                                        placeholder="08012345678"
                                                                        // onInput={(e) => e.target.value = e.target.value.slice(0, 11)} // js validation to check that number does not exceed 11 characters
                                                                        value={phone}
                                                                        country={'ng'}
                                                                        enableSearch={true}
                                                                        countryCodeEditable={false}
                                                                        inputProps={{ name: 'phone', required: true, autoFocus: true, maxLength: "17" }}
                                                                        onChange={setPhone}
                                                                        style={{ width: "100%" }}
                                                                    />
                                                                </div>
                                                            </div>

                                                        </div>

                                                        <div className="col-md-4 mt-4 mb-0">
                                                            <div className="d-grid">
                                                                <a onClick={createArtisan} className="btn btn-primary btn-block">
                                                                    {isFetching ? (
                                                                        <Spinner />
                                                                    ) : (
                                                                        <span>Submit{'  '}</span>
                                                                    )}
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </Tab>
                                                <Tab eventKey="bank" title="Create Bank" disabled={tabState ? false : true}>
                                                    <div className="card-header">
                                                        <h5 className="mt-5 font-weight-light">Bank Details</h5>
                                                    </div>
                                                    <form>

                                                        <div className="row mb-3">
                                                            {/* <div className="col-md-4">
                                                                <div className="form-floating mb-3 mb-md-0">
                                                                    <select
                                                                        name="owner_type"
                                                                        onChange={(e) => setOwnerType(e.target.value)}
                                                                        className="form-control" id="inputCategories" name="owner_type" id="owner_type">
                                                                        <option>Select Owner Type</option>
                                                                        <option value="user">User</option>
                                                                        <option value="admin">Admin</option>
                                                                        <option value="artisan">Artisan</option>
                                                                    </select>
                                                                    <label htmlFor="inputCategories">Owner  Type</label>
                                                                </div>
                                                            </div> */}

                                                            <div className="col-md-6">
                                                                <div className="form-floating mb-3 mb-md-0">
                                                                    <select className="form-control"
                                                                        name="bank_name"
                                                                        onChange={(e) => setBankName(e.target.value)}
                                                                        id="bank_name">
                                                                        <option selected>Choose Bank</option>
                                                                        <option value="access">Access Bank
                                                                        </option>
                                                                        <option value="citibank">Citibank
                                                                        </option>
                                                                        <option value="diamond">Diamond Bank
                                                                        </option>
                                                                        <option value="ecobank">Ecobank</option>
                                                                        <option value="fidelity">Fidelity Bank
                                                                        </option>
                                                                        <option value="firstbank">First Bank
                                                                        </option>
                                                                        <option value="fcmb">First City Monument
                                                                            Bank (FCMB)
                                                                        </option>
                                                                        <option value="gtb">Guaranty Trust Bank
                                                                            (GTB)
                                                                        </option>
                                                                        <option value="heritage">Heritage Bank
                                                                        </option>
                                                                        <option value="keystone">Keystone Bank
                                                                        </option>
                                                                        <option value="polaris">Polaris Bank
                                                                        </option>
                                                                        <option value="providus">Providus Bank
                                                                        </option>
                                                                        <option value="stanbic">Stanbic IBTC
                                                                            Bank
                                                                        </option>
                                                                        <option value="standard">Standard
                                                                            Chartered Bank
                                                                        </option>
                                                                        <option value="sterling">Sterling Bank
                                                                        </option>
                                                                        <option value="suntrust">Suntrust Bank
                                                                        </option>
                                                                        <option value="union">Union Bank
                                                                        </option>
                                                                        <option value="uba">United Bank for
                                                                            Africa (UBA)
                                                                        </option>
                                                                        <option value="unity">Unity Bank
                                                                        </option>
                                                                        <option value="wema">Wema Bank</option>
                                                                        <option value="zenith">Zenith Bank
                                                                        </option>
                                                                    </select>
                                                                    <label htmlFor="inputFirstName">Bank Name</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="form-floating mb-3 mb-md-0">
                                                                    <select
                                                                        name="account_type"
                                                                        onChange={(e) => setAccountType(e.target.value)}
                                                                        className="form-control" id="account_type">
                                                                        <option>Select Account Type</option>
                                                                        <option value="savings">Savings</option>
                                                                        <option value="current">Current</option>
                                                                    </select>
                                                                    <label htmlFor="inputFirstName">Account Type</label>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="row mb-3">
                                                            <div className="col-md-6">
                                                                <div className="form-floating">
                                                                    <input className="form-control" id="inputLastName"
                                                                        type="text"
                                                                        name="account_holder_name"
                                                                        onChange={(e) => setAccountHolderName(e.target.value)}
                                                                        placeholder="Enter account name" />
                                                                    <label htmlFor="inputLastName">Account Holder Name</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="form-floating">
                                                                    <input className="form-control"
                                                                        type="text" pattern="\d*" maxlength="10" name="account_number"
                                                                        onChange={(e) => setAccountNumber(e.target.value)}
                                                                        placeholder="Enter account number" />
                                                                    <label htmlFor="inputLastName">Account Number</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4 mt-4 mb-0">
                                                            <div className="d-grid">
                                                                <a
                                                                    onClick={createBank}
                                                                    className="btn btn-primary btn-block"
                                                                >
                                                                    {isFetchingBank ? (
                                                                        <Spinner />
                                                                    ) : (
                                                                        <span>Submit{'  '}</span>
                                                                    )}
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </Tab>

                                            </Tabs>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                    <Footer />
                </div>
            </div>
        </>
    )
}
export default Add;