import React from 'react';
import {logoutFunc} from "../../redux/ApiCalls/authCalls";
import {useDispatch} from "react-redux";
import LogoWhite from "../../asset/images/jambolo-logo-w.png";



function Header () {
    const dispatch = useDispatch()

    return (
            <>
                <nav className="sb-topnav navbar navbar-expand navbar-dark bg-dark">
                    <a className="navbar-brand er mx-3" href="/">
                        <img style={{ height: "40px",marginTop:"20px", objectFit: "cover" }} src={LogoWhite} alt="Jambolo" className="main-logo" />
                    </a>

                </nav>
            </>
        )
    }
export default Header;
