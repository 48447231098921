 import Modal from 'react-bootstrap/Modal';
import React  from 'react';

const Modals = (props) => {
    return (
        <Modal
            {...props}
            size={props.size || 'lg'}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                {props.title && <Modal.Title id="contained-modal-title-vcenter">
                    {props.title}
                </Modal.Title>}
            </Modal.Header>
            <Modal.Body>
                {props.children}
            </Modal.Body>
            {props.footer && <Modal.Footer>
                {props.footer}
            </Modal.Footer>}
        </Modal>
    );
}
export default Modals;
