import React, { useEffect, useState } from "react";
import SidebarNavbar from "../../components/Sidebar";
import Footer from "../../components/Footer";
import CustomLoader from "../../components/CustomLoader";
import DataTableExtensions from "react-data-table-component-extensions";
import DataTable from "react-data-table-component";
import 'react-data-table-component-extensions/dist/index.css';

import { useDispatch, useSelector } from "react-redux";
import Moment from "react-moment";
import zIndex from "@material-ui/core/styles/zIndex";
import NumberFormat from "react-number-format";
import Switch from "react-switch";
import { getReports, handlePerRowsChange, handlePageChange, getReportByDate, getReportExcel } from "../../redux/ApiCalls/reportCalls";
import FileSaver from "file-saver";

const ReportManagement = () => {
    const dispatch = useDispatch();
    const [transactions, setTransactions] =useState({
        data:[],
        total: 0
    });
    const filterPhone = (e) => {
        getReports(dispatch, 'callout-charge').then(res=>{
            setTransactions(res);
        })
    }

    const [startDate, setStartDate] = useState("")
    const [endDate, setEndDate] = useState("")
    useEffect(() => {
        getReports(dispatch, 'callout-charge').then(res =>{
            
            setTransactions(res)
        })
    }, [dispatch])

    const [columns, setColumns] = useState([
        {
            name: 'Name',
            selector: 'name',
            sortable: true,
            cell: row => row.user?.first_name+' '+row.user?.last_name
        },
        {
            name: 'Amount',
            selector: 'amount',
            sortable: true,
        },
        {
            name: 'Artisan',
            selector: 'description',
            sortable: true,
            cell: row => row.artisan?.first_name+' '+row.artisan?.last_name
        },
        {
            name: 'Payment Purpose',
            selector: 'payment_purpose',
            sortable: true,
        },
        {
            name: 'Date',
            selector: 'created_at',
            sortable: true,
        },
        
    ],
    )
    const customStyles = {
        rows: {
            style: {
                minHeight: '72px', // override the row height
                minWidth: '10px'
            },
        },
        headCells: {
            style: {
                paddingLeft: '2px', // override the cell padding for head cells
                paddingRight: '2px',
            },
        },
        // cells: {
        //     style: {
        //         paddingLeft: '8px', // override the cell padding for data cells
        //         paddingRight: '8px',
        //         width: '15px'
        //     },
        // },
    };
 
  const searchByDate= ()=>{
    getReportByDate(dispatch, startDate, endDate).then(res =>{
            
        setTransactions(res)
    })
  }
    
  const downloadExcel= ()=>{
    getReportExcel(dispatch, startDate, endDate).then(res =>{
        // debugger;
        let data='transactionreport.xlsx';        
        window.open(`${process.env.REACT_APP_BASE_URL}generic/downloadFile/`+data, '_blank');
       
    })
  }

    const handlePageChanges = (pageNum, perPage) => {
        handlePageChange(pageNum, 15, dispatch);
    }

    const handlePerRowsChanges = (perPage, page) => {
        handlePerRowsChange(page, perPage, dispatch);
    }

  

    const artisans = useSelector((state) => state.artisans.artisans);
    const data = transactions?.data;
    const totalRows = transactions?.total
    const tableData = { columns, data };
    return (
        <>
            <div id="layoutSidenav">

                <SidebarNavbar />
                <div id="layoutSidenav_content">
                     <main>
                        <div className="container-fluid px-4">
                            <div className="d-flex justify-content-between align-items-baseline">
                                <h5 className="mt-4">Artisan Report</h5>
                                <div className="breadcrumb mb-6">
                                    
                                    <div className="breadcrumb mb-2">
                                        <a className="btn btn-xs btn-primary" href="/market-management">Market Report</a>
                                    </div>
                                </div>
                            </div>

                            <div className="card mb-4">
                                <div className="card-body">
                                    
                                <div className="form-floating" style={{ width: "20%", float: "left", marginBottom: "10px", paddingRight:"5px" }}>
                                        <input
                                            className="form-control"
                                            type="date"
                                            onChange={(e) => setStartDate(e.target.value)}
                                            />
                                            
                                        <label htmlFor="inputLastName">Start Date</label>
                                    </div>
                                    <div className="form-floating" style={{ width: "20%", float: "left", marginBottom: "10px", paddingRight:"5px" }}>
                                        <input
                                            className="form-control"
                                            onChange={(e) => setEndDate(e.target.value)}
                                            type="date"/>
                                            
                                        <label htmlFor="inputLastName">End Date</label>
                                    </div>
                                    <div className="form-floating" style={{ width: "8%", float: "left", marginBottom: "10px", paddingRight:"5px" }}>
                                   
                                    <input
                                            className="btn btn-xs btn-primary"
                                            onClick={searchByDate}
                                            type="button" value="Search"/>
                                        
                                    </div>
                                    <div className="form-floating" style={{ width: "10%", float: "left", marginBottom: "10px", paddingRight:"5px" }}>
                                    <input
                                            className="btn btn-xs btn-primary"
                                            onClick={downloadExcel}
                                            type="button" value="Download"/>
                                    </div>
                                    <div className="form-floating" style={{ width: "20%", float: "right", marginBottom: "10px", paddingRight:"5px" }}>
                                        <input
                                            className="form-control"
                                            type="text"
                                            onChange={(e) => filterPhone(e.target.value)}/>
                                            
                                        <label htmlFor="inputLastName">Filter</label>
                                    </div>

                                    
                                    <DataTableExtensions {...tableData}>
                                        <DataTable
                                            columns={columns}
                                            data={data}
                                            noHeader
                                            defaultSortAsc={false}
                                            highlightOnHover
                                            // progressPending={orderLoading}
                                            progressComponent={<CustomLoader />}
                                            pagination={true}
                                            paginationServer={true}
                                            paginationResetDefaultPage={true}
                                            export={false}
                                            filterHidden={true}
                                            print={false}
                                            paginationPerPage={15}
                                            paginationTotalRows={totalRows}
                                            paginationRowsPerPageOptions={[15, 30, 45, 60, 75]}
                                            onChangeRowsPerPage={handlePerRowsChanges}
                                            onChangePage={handlePageChanges}
                                            // customStyles={customStyles}
                                        />
                                    </DataTableExtensions>
                                </div>
                            </div>
                        </div>
                    </main>

                    

                    <Footer />
                </div>


            </div>

        </>
    )
}
export default ReportManagement;