import React, { Component } from 'react';
import Header from "../Navbar";
import {Link, useLocation } from "react-router-dom";
import {logoutFunc} from "../../redux/ApiCalls/authCalls";
import {useDispatch} from "react-redux";
import LogoWhite from "../../asset/images/jambolo-logo-w.png";
import { getUser } from '../../helpers/auth';



const Sidebar = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const currentUser =getUser();
    const page = location.pathname.split('/')[1];
    if(currentUser?.admin?.role=="SuperAdmin")
        return (
            <>
                
                {/*<Header/>*/}
                <div id="layoutSidenav_nav">
                    <nav style={{paddingTop:"20px"}} className="sb-sidenav accordion sb-sidenav-dark" id="sidenavAccordion">
                        <a className="navbar-brand er mx-3" href="/">
                            <img style={{ height: "40px", objectFit: "cover" }} src={LogoWhite} alt="Jambolo" className="main-logo" />
                        </a>
                        <div className="sb-sidenav-menu">
                            <div className="nav" style={{marginTop:"80px"}}>
                                <Link className={"nav-link" + (page === "s_dashboard" ? " active-style nav-link-active" : "")} to="/s/dashboard">
                                    <div className={"sb-nav-link-icon" + (page === "s_dashboard" ? " sb-nav-link-icon-active" : "")}><i className="fas fa-tachometer-alt"></i></div>
                                    Dashboard
                                </Link>


                               
                                <Link className={"nav-link" + (page === "admin-accounts" ? " active-style nav-link-active" : "")} to="/admin-accounts">
                                    <div className={"sb-nav-link-icon" + (page === "admin-accounts" ? " sb-nav-link-icon-active" : "")}><i className="fas fa-chart-area"></i></div>
                                    Admin Users
                                </Link>

                                <Link className={"nav-link" + (page === "report" ? " active-style nav-link-active" : "")} to="/report">
                                    <div className={"sb-nav-link-icon" + (page === "report" ? " sb-nav-link-icon-active" : "")}><i className="fas fa-chart-area"></i></div>
                                    Report
                                </Link>
                                <Link className={"nav-link" + (page === "profile" ? " active-style nav-link-active" : "")} to="/profile">
                                    <div className={"sb-nav-link-icon" + (page === "profile" ? " sb-nav-link-icon-active" : "")}><i className="fas fa-chart-area"></i></div>
                                    Profile
                                </Link>
                            </div>
                        </div>
                        <div className="sb-sidenav-footer">
                            <div onClick={() => logoutFunc(dispatch)} className="small cursor-pointer"><i className="fas fa-sign-out"></i>
                                <a style={{color:"white", marginLeft:"10px"}} href='/'
                                >Logout</a>
                            </div>
                        </div>
                    </nav>
                </div>
            </>
        )
    else 
    return (
        <>
            
            {/*<Header/>*/}
            
            <button className="navbar-toggler mobile_menu" type="button" data-bs-toggle="collapse" data-bs-target="#layoutSidenav_nav" aria-controls="layoutSidenav_nav" aria-expanded="false"
            aria-label="Toggle navigation"><span className="navbar-toggler-icon" style={{color: "black", fontSize: 'xx-large'}}>V</span>
          </button>
            
            
            <div className="navbar-collapse" id="layoutSidenav_nav">
                <nav style={{paddingTop:"20px"}} className="sb-sidenav accordion sb-sidenav-dark" id="sidenavAccordion">
                    <a className="navbar-brand er mx-3" href="/">
                        <img style={{ height: "40px", objectFit: "cover" }} src={LogoWhite} alt="Jambolo" className="main-logo" />
                    </a>                   
                    <div className="sb-sidenav-menu">
                        <div className="nav" style={{marginTop:"80px"}}>
                            <Link className={"nav-link" + (page === "dashboard" ? " active-style nav-link-active" : "")} to="/dashboard">
                                <div className={"sb-nav-link-icon" + (page === "dashboard" ? " sb-nav-link-icon-active" : "")}><i className="fas fa-tachometer-alt"></i></div>
                                Dashboard
                            </Link>


                            <Link className={"nav-link" + (page === "marketplace" ? " active-style nav-link-active" : "")} to="/marketplace">
                                <div className={"sb-nav-link-icon" + (page === "marketplace" ? " sb-nav-link-icon-active" : "")}><i className="fas fa-table-columns"></i></div>
                                Market Place
                            </Link>
                            <Link className={"nav-link" + (page === "table" ? " active-style nav-link-active" : "")} to="/table">
                                <div  className={"sb-nav-link-icon" + (page === "table" ? " sb-nav-link-icon-active" : "")}><i className="fas fa-table"></i></div>
                                Advert Management
                            </Link>
                            <Link className={"nav-link" + (page === "artisan-management" ? " active-style nav-link-active" : "")} to="/artisan-management">
                                <div className={"sb-nav-link-icon" + (page === "artisan-management" ? " sb-nav-link-icon-active" : "")}><i className="fas fa-book-open"></i></div>
                                Artisan Management
                            </Link>
                            <Link className={"nav-link" + (page === "categories" ? " active-style nav-link-active" : "")} to="/categories">
                                <div className={"sb-nav-link-icon" + (page === "categories" ? " sb-nav-link-icon-active" : "")}><i className="fas fa-book-open"></i></div>
                                Categories
                            </Link>
                            {/* <Link className={"nav-link" + (page === "post-management" ? " active-style nav-link-active" : "")} to="/post-management">
                                <div className={"sb-nav-link-icon" + (page === "post-management" ? " sb-nav-link-icon-active" : "")}><i className="fas fa-book-open"></i></div>
                                Post Management
                            </Link> */}

                            <Link className={"nav-link" + (page === "users" ? " active-style nav-link-active" : "")} to="/users">
                                <div className={"sb-nav-link-icon" + (page === "users" ? " sb-nav-link-icon-active" : "")}><i className="fas fa-chart-area"></i></div>
                                Settings
                            </Link>
                            <Link className={"nav-link" + (page === "edit-Profile" ? " active-style nav-link-active" : "")} to="/edit-Profile">
                                <div className={"sb-nav-link-icon" + (page === "edit-Profile" ? " sb-nav-link-icon-active" : "")}><i className="fas fa-chart-area"></i></div>
                                Profile
                            </Link>
                        </div>
                    </div>
                    <div className="sb-sidenav-footer">
                        <div onClick={() => logoutFunc(dispatch)} className="small cursor-pointer"><i className="fas fa-sign-out"></i>
                            <a style={{color:"white", marginLeft:"10px"}} href='/'
                            >Logout</a>
                        </div>
                    </div>
                </nav>
            </div>
        </>
    )
}
export default Sidebar;