import {publicRequest, userSuperRequest} from "../../requestMethod";
import Notification from "../../utils/notification";
import {
    getSingleArtisanFailure,
    getSingleArtisanStart,
    getSingleArtisanSuccess,
    getArtisanFailure,
    getArtisanStart,
    getArtisanSuccess,
    deleteArtisanFailure,
    deleteArtisanStart,
    deleteArtisanSuccess,
    updateArtisanFailure,
    updateArtisanStart,
    updateArtisanSuccess,
    addArtisanFailure,
    addArtisanStart,
    addArtisanSuccess,
} from "../artisanRedux";

//

export const addAdmin = async (dispatch, Admin) => {
    dispatch(addArtisanStart());
    try {
        const res = await userSuperRequest.post(`/admin/admins`, Admin);
        // console.log("post data", res.data?.data?.artisan?.id)

        // dispatch(addArtisanSuccess(res.data.data));
        Notification.success(res?.data?.message);
        
        window.location.href="/admin-accounts";
        // window.location.reload();
    } catch (err) {
        if (err && err.response && err.response.status === 403) {
            localStorage.clear();
            window.location.href = "/signin";
        }else{
            dispatch(addArtisanFailure());
            Notification.error(err?.response?.data?.message);
        }

    }
};

export const getAdmins = async (dispatch, status) => {
    // alert(5)
    dispatch(getArtisanStart());
    try {
        const res = await userSuperRequest.get(`/admin/admins?filter[is_active]=${status}`);
        // dispatch(getArtisanSuccess(res?.data?.data?.users));
        // Notification.success(res.data.message);
        return res?.data?.data?.users;
    } catch (err) {
        if (err && err.response && err.response.status === 403) {
            localStorage.clear();
            window.location.href = "/signin";
        }else{
            dispatch(getArtisanFailure());
            Notification.error(err.response.data.message);
        }
    }
};

export const getAdmin = async (id, dispatch) => {
    // alert(5)
    dispatch(getSingleArtisanStart());
    try {
        const res = await userSuperRequest.get(`/admin/admins/${id}`);
        dispatch(getSingleArtisanSuccess(res?.data?.data?.admin));
        return res?.data?.data?.admin
    } catch (err) {
        if (err && err.response && err.response.status === 403) {
            localStorage.clear();
            window.location.href = "/signin";
        }else{
            dispatch(getSingleArtisanFailure());
            Notification.error(err.response.data.message);
        }

    }
};

export const updateAdmin = async (id, artisan, dispatch) => {
    dispatch(updateArtisanStart());
    try {
        const res = await userSuperRequest.put(`/admin/admins/${id}`, artisan);
        // dispatch(updateArtisanSuccess(res.data.data));
        Notification.success(res.data.message);        
        window.location.reload();
    } catch (err) {
        if (err && err.response && err.response.status === 403) {
            localStorage.clear();
            window.location.href = "/signin";
        }else{
            dispatch(updateArtisanFailure());
            Notification.error(err.response.data.message);
        }
    }
};

//
export const deleteAdminFunc = async (id, dispatch) => {
    dispatch(deleteArtisanStart());
    try {
        const res = await userSuperRequest.delete(`/admin/admins/${id}`);
        dispatch(deleteArtisanSuccess(id));
        Notification.success(res.data.message);
        window.location.reload();
    } catch (err) {
        if (err && err.response && err.response.status === 403) {
            localStorage.clear();
            window.location.href = "/signin";
        }else{
            dispatch(deleteArtisanFailure());
            Notification.error(err.response.data.message);
        }

    }
};

export const handlePageChange = async (pageNum, perPage, dispatch) => {
    // alert(5)
    dispatch(getArtisanStart());
    try {
        const res = await userSuperRequest.get(`/admin/artisans?page=${pageNum}&limit=${perPage}`);
        dispatch(getArtisanSuccess(res.data.data.artisans));
        // Notification.success(res.data.message);
    } catch (err) {
        dispatch(getArtisanFailure());
        Notification.error(err.response.data.message);
    }
};

export const handlePerRowsChange = async (page, perPage, dispatch) => {
    // alert(5)
    dispatch(getArtisanStart());
    try {
        const res = await userSuperRequest.get(`/admin/artisans?page=${page}&limit=${perPage}`);
        dispatch(getArtisanSuccess(res.data.data.artisans));
        // Notification.success(res.data.message);
    } catch (err) {
        dispatch(getArtisanFailure());
        Notification.error(err.response.data.message);
    }
};
