import React, { useEffect, useState } from "react";
import SidebarNavbar from "../../components/Sidebar";
import Footer from "../../components/Footer";
import CustomLoader from "../../components/CustomLoader";
import DataTableExtensions from "react-data-table-component-extensions";
import DataTable from "react-data-table-component";
import 'react-data-table-component-extensions/dist/index.css';
import { getAdmins, deleteAdminFunc, handlePageChange, handlePerRowsChange } from "../../redux/ApiCalls/superadminCalls";
import { useDispatch, useSelector } from "react-redux";
import Moment from "react-moment";
import zIndex from "@material-ui/core/styles/zIndex";

const AdminManagement = () => {
    const dispatch = useDispatch()

    const [admins, setAdmins] =useState({
        data:[],
        total: 0
    });
    useEffect(() => {
        getAdmins(dispatch, '').then(res =>{
            setAdmins(res)
        })
    }, [dispatch])

    const [columns, setColumns] = useState([
        
        {
            name: 'First Name',
            selector: 'first_name',
            sortable: true,
        },
        {
            name: 'Last Name',
            selector: 'last_name',
            sortable: true,
        },
        {
            name: 'Mobile',
            selector: 'phone',
            sortable: true,
            cell: row => row.phone ?
                <div>
                    {row.phone}
                </div>
                :
                <div>
                    N/A
                </div>
        },
       
        {
            name: 'Created',
            selector: 'created_at',
            sortable: true,
            cell: row => row.created_at ?
                <div>
                    <Moment format="D MMM YYYY">{row.created_at}</Moment>
                </div>
                :
                <div>

                </div>
        },
        {
            name: 'Action',
            selector: 'created_at',
            sortable: true,
            cell: row => row.created_at &&
                <div style={{ width: 900 }}>
                    <a className="btn btn-xs btn-primary text-white" href={`view-admin/${row.id}`}>view</a> {"|"}
                    {/* <a className="btn btn-xs btn-primary text-white" href={`edit-admin/${row.id}`}>edit</a> {"|"} */}
                    <button

                        onMouseUp={(e) => {
                            deleteAdminPop();
                            setAdminId(row.id);
                        }}
                        type="submit"
                        className="btn btn-xs btn-danger text-white" >delete</button>
                </div>
        }
    ],
    )

    const [deleteAdmin, SetDeletePopup] = useState(false)
    const [adminId, setAdminId] = useState(null)

    const deleteFunc = () => {
        deleteAdminFunc(adminId, dispatch);
    }

    const handlePageChanges = (pageNum, perPage) => {
        handlePageChange(pageNum, 15, dispatch);
    }

    const handlePerRowsChanges = (perPage, page) => {
        handlePerRowsChange(page, perPage, dispatch);
    }

    const deleteAdminPop = () => {
        SetDeletePopup(true)
        document.body.style.overflow = 'hidden';
    }

    const hide = () => {
        SetDeletePopup(false)
        document.body.style.overflow = 'hidden';
    }

    // const admins = useSelector((state) => state.artisans.artisans);
    const data = admins.data;
    const totalRows = admins.total
    const tableData = { columns, data };
    return (
        <>
            <div id="layoutSidenav">

                <SidebarNavbar />
                <div id="layoutSidenav_content">
                     <main>
                        <div className="container-fluid px-4">
                            <div className="d-flex justify-content-between align-items-baseline">
                                <h5 className="mt-4">Admin Users</h5>
                                <div className="breadcrumb mb-4">
                                    <a className="btn btn-xs btn-primary" href="/add-admin">Add New Admin</a>
                                </div>
                            </div>

                            <div className="card mb-4">
                                <div className="card-body">
                                    <div className="form-floating" style={{ width: "20%", float: "right", marginBottom: "10px" }}>
                                        <select
                                            className="form-control"
                                            onChange={(e) => getAdmins(dispatch, e.target.value)}>
                                            {/*<option value="">Filter By Status</option>*/}
                                            <option value="">All</option>
                                            <option value="1">Active</option>
                                            <option value="0">Not Active</option>
                                        </select>
                                        <label htmlFor="inputLastName">Filter By Status</label>
                                    </div>
                                    <DataTableExtensions {...tableData}>
                                        <DataTable
                                            columns={columns}
                                            data={data}
                                            noHeader
                                            defaultSortAsc={false}
                                            highlightOnHover
                                            // progressPending={orderLoading}
                                            progressComponent={<CustomLoader />}
                                            pagination={true}
                                            paginationServer={true}
                                            paginationResetDefaultPage={true}
                                            export={false}
                                            filterHidden={true}
                                            print={false}
                                            paginationPerPage={15}
                                            paginationTotalRows={totalRows}
                                            paginationRowsPerPageOptions={[15, 30, 45, 60, 75]}
                                            onChangeRowsPerPage={handlePerRowsChanges}
                                            onChangePage={handlePageChanges}
                                        />
                                    </DataTableExtensions>
                                </div>
                            </div>
                        </div>
                    </main>

                    <div>
                        {deleteAdmin && (
                            <div style={{
                                position: "fixed", /* Sit on top of the page content */
                                width: "100%", /* Full width (cover the whole page) */
                                height: "100%", /* Full height (cover the whole page) */
                                top: "0",
                                left: "0",
                                right: "0",
                                bottom: "0",
                                backgroundColor: "rgba(0, 0, 0, 0.7)", /* #333333 background with opacity */
                                zIndex: "1110"
                            }} className="overflow-auto">
                                <div
                                    style={{
                                        height: "100vh",
                                        display: "flex",
                                        alignItems: "center",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                    }}
                                >
                                    <div
                                        style={{
                                            padding: "24px",
                                            gap: "40px",
                                            position: "relative",
                                            width: "22%",
                                            background: "#FFFFFF",
                                            borderRadius: "12px",
                                        }}
                                        className="col-6 col-md-6">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <label htmlFor="email">Are you sure you want to delete the admin</label>
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-md-12">

                                                <button
                                                    onClick={deleteFunc}
                                                    style={{ fontSize: "14px", width: "35%" }} type="button" className="btn btn-primary btn-sm text-white mx-2 my-2" data-bs-toggle="tooltip" data-bs-placement="top"
                                                    title="remove this admin access">
                                                    {/* {isFetching ? (
                                                    <Spinner />
                                                ) : ( */}
                                                    <span>Yes{'  '}</span>
                                                    {/* )} */}
                                                </button>

                                                <button
                                                    onClick={() => hide(false)}
                                                    className="btn btn-danger btn-sm text-white mx-2 my-2"
                                                    style={{ width: "35%", float: "right" }}
                                                >
                                                    <span>No{'  '}</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>

                    <Footer />
                </div>


            </div>

        </>
    )
}
export default AdminManagement;