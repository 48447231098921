import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import jwt_decode from "jwt-decode";
import {useSelector} from "react-redux";

import {store} from "../redux/store";
// import { logoutFunc } from "../redux/authRedux";

const PrivateRoute = ({ component: Component, user, path, ...rest }) => {
    return (
        <Route
            {...rest}
            render={props => {
                const content = user && localStorage.JamboloAdmintoken ? (
                    path === "/" ? (
                        <Redirect
                            to={{
                                pathname: "/dashboard",
                                state: { from: props.location }
                            }}
                        />
                    ) : (
                        <Component {...props} />
                    )
                ) : (
                    <Redirect
                        to={{
                            pathname: "/",
                            state: { from: props.location }
                        }}
                    />
                );
                return content;
            }}
        />
    );
};

const mapStateToProps = ({ user }) => ({
    user: user.currentUser
});

export default connect(mapStateToProps, {})(PrivateRoute);
