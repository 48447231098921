import {publicRequest, userRequest} from "../../requestMethod";
import Notification from "../../utils/notification";
import {
    getSinglePostFailure,
    getSinglePostStart,
    getSinglePostSuccess,
    getPostFailure,
    getPostStart,
    getPostSuccess,
    deletePostFailure,
    deletePostStart,
    deletePostSuccess,
    updatePostFailure,
    updatePostStart,
    updatePostSuccess,
    addPostFailure,
    addPostStart,
    addPostSuccess,
} from "../postRedux";

//

export const addPost = async (dispatch, Post) => {
    dispatch(addPostStart());
    try {
        const res = await userRequest.post(`/admin/posts`, Post);
        // console.log("post data", res.data?.data?.Post?.id)

        // dispatch(addPostSuccess(res.data.data));
        Notification.success(res?.data?.message);
        // window.location.href="/Post-management";
        window.location.reload();
    } catch (err) {
        if (err && err.response && err.response.status === 403) {
            localStorage.clear();
            window.location.href = "/signin";
        }else{
            dispatch(addPostFailure());
            Notification.error(err?.response?.data?.message);
        }

    }
};

export const getPosts = async (dispatch, status) => {
    // alert(5)
    dispatch(getPostStart());
    try {
        // const res = await userRequest.get(`/admin/posts?filter[is_active]=${status}`);
        const res = await userRequest.get(`/admin/posts`);
        dispatch(getPostSuccess(res?.data?.data?.posts));
        // Notification.success(res.data.message);
    } catch (err) {
        if (err && err.response && err.response.status === 403) {
            localStorage.clear();
            window.location.href = "/signin";
        }else{
            dispatch(getPostFailure());
            Notification.error(err.response.data.message);
        }
    }
};

export const getPost = async (id, dispatch) => {
    // alert(5)
    dispatch(getSinglePostStart());
    try {
        const res = await userRequest.get(`/admin/posts/${id}?include=bankDetail`);
        dispatch(getSinglePostSuccess(res?.data?.data?.post));
        // Notification.success(res.data.message);
    } catch (err) {
        if (err && err.response && err.response.status === 403) {
            localStorage.clear();
            window.location.href = "/signin";
        }else{
            dispatch(getSinglePostFailure());
            Notification.error(err.response.data.message);
        }

    }
};

export const updatePost = async (id, Post, dispatch) => {
    dispatch(updatePostStart());
    try {
        const res = await userRequest.put(`/admin/posts/${id}`, Post);
        // dispatch(updatePostSuccess(res.data.data));
        Notification.success(res.data.message);
        window.location.reload();
    } catch (err) {
        if (err && err.response && err.response.status === 403) {
            localStorage.clear();
            window.location.href = "/signin";
        }else{
            dispatch(updatePostFailure());
            Notification.error(err.response.data.message);
        }
    }
};

//
export const deletePostFunc = async (id, dispatch) => {
    dispatch(deletePostStart());
    try {
        const res = await userRequest.delete(`/admin/posts/${id}`);
        dispatch(deletePostSuccess(id));
        Notification.success(res.data.message);
        window.location.reload();
    } catch (err) {
        if (err && err.response && err.response.status === 403) {
            localStorage.clear();
            window.location.href = "/signin";
        }else{
            dispatch(deletePostFailure());
            Notification.error(err.response.data.message);
        }

    }
};

export const handlePageChange = async (pageNum, perPage, dispatch) => {
    // alert(5)
    dispatch(getPostStart());
    try {
        const res = await userRequest.get(`/admin/Posts?page=${pageNum}&limit=${perPage}`);
        dispatch(getPostSuccess(res.data.data.posts));
        // Notification.success(res.data.message);
    } catch (err) {
        dispatch(getPostFailure());
        Notification.error(err.response.data.message);
    }
};

export const handlePerRowsChange = async (page, perPage, dispatch) => {
    // alert(5)
    dispatch(getPostStart());
    try {
        const res = await userRequest.get(`/admin/posts?page=${page}&limit=${perPage}`);
        dispatch(getPostSuccess(res.data.data.posts));
        // Notification.success(res.data.message);
    } catch (err) {
        dispatch(getPostFailure());
        Notification.error(err.response.data.message);
    }
};
