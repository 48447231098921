import React, { useEffect, useState } from "react";
import SidebarNavbar from "../../components/Sidebar"
import Footer from "../../components/Footer";
import Spinner from '../../components/Loading';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { getArtisan, updateArtisan } from "../../redux/ApiCalls/artisanCalls";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { getBank, updateBank } from "../../redux/ApiCalls/bankCalls";
import { getCategories, getStates, getCities } from "../../redux/ApiCalls/categoryCalls";
import { getAssociation } from "../../redux/ApiCalls/associationCalls";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

const View = () => {
    const dispatch = useDispatch()
    const location = useLocation();
    const id = location.pathname.split('/')[2];
    useEffect(() => {
        getArtisan(id, dispatch);
    }, [dispatch])

    const [categories, setCategoryEl] = useState([]);
    const [states, setStateEl] = useState([]);
    const [cities, setCityEl] = useState([]);
    useEffect(() => {
        getCategories(dispatch).then(res =>{        
            setCategoryEl(res.data) ;
        });

        getStates(dispatch).then(res =>{              
            setStateEl(res) ;
            });

        getCities(artisan.state_id,dispatch).then(res =>{              
            setCityEl(res) ;
        });
       
        //setCategoryEl(getCategories(dispatch));
        // getAssociation(dispatch);
        // getStates(dispatch);    
        // getCities(artisan.state_id, dispatch);    
    }, [dispatch]);
    // useEffect(() => {
    //     getAssociation(dispatch);
    // }, [dispatch]);

    const [first_name, setFirstname] = useState("")
    const [last_name, setLastname] = useState("")
    const [phone, setPhone] = useState("")
    const [email, setEmail] = useState("")
    const [business_name, setBusinessname] = useState("")
    const [address, setAddress] = useState("")
    const [state_id, setState] = useState("") //lagos 2671  abia 2647
    const [city_id, setCity] = useState("") //lagos30984 30983  abia 30692
    const [category_id, setCategory] = useState("")
    const [association_id, setAssociation] = useState("")
    const [photo, setPhoto] = useState(null)

    //Banks state
    const [owner_type, setOwnerType] = useState("artisan");
    const [bank_name, setBankName] = useState("");
    const [owner_id, setOwnerOd] = useState("1");
    const [account_type, setAccountType] = useState("");
    const [account_number, setAccountNumber] = useState("");
    const [account_holder_name, setAccountHolderName] = useState("");
    const [tabState, setTabState] = useState(localStorage.getItem("tabstate"))


    const artisan = useSelector((state) => state.artisan.artisan);
// 
// const categories = (callback) => {
//     debugger
//     callback(getCategories(dispatch));
// }
    // const categories =  useSelector((state) => 
    // state.categories)
    
    const associations = useSelector((state) => state.associations.associations);
    const { isFetching, error, isSuccess } = useSelector(state => state.artisans)
    const { isFetchingBank } = useSelector(state => state.banks)
    
    // console.log("hfhfhfhfhfhf", artisan?.bank_detail)
    const handleImage = (e) => {
        const reader = new FileReader();
        reader.readAsDataURL(e);
        setPhoto(e);
        reader.onload = function () {
        };
    };

    const updateArtisanFunc = (e) => {
        e.preventDefault()
        const data = {
            "first_name": first_name ? first_name : artisan?.first_name,
            "last_name": last_name ? last_name : artisan?.last_name,
            "phone": phone ? phone : artisan?.phone,
            "business_name": business_name ? business_name : artisan?.business_name,
            "address": address ? address : artisan?.address,
            "state_id": state_id ? state_id : artisan?.state_id,
            "city_id": city_id ? city_id : artisan?.city_id,
            "category_id": category_id ? category_id : artisan?.category_id,
            "association_id": association_id ? association_id : artisan?.association_id,
            "_method": "PUT",
        };
        updateArtisan(id, data, dispatch)
    }

    // const updateArtisanFunc = (e) =>{
    //     e.preventDefault()
    //     const formData = new FormData();
    //     formData.append("first_name", first_name ? first_name : artisan?.first_name);
    //     formData.append("last_name", last_name ? last_name : artisan?.last_name);
    //     formData.append("business_name", business_name ? business_name : artisan?.business_name);
    //     formData.append("address", address ? address : artisan?.address);
    //     formData.append("state_id", state_id ? state_id : artisan?.state_id);
    //     formData.append("city_id", city_id ? city_id : artisan?.city_id);
    //     formData.append("category_id", category_id ? category_id : artisan?.category_id);
    //     formData.append("association_id", association_id ? association_id : artisan?.association_id);
    //     formData.append("_method", "PUT");
    //
    //     updateArtisan(id,(formData),dispatch)
    // }
    //

    const updateBankFunc = (e) => {
        e.preventDefault()
        const data = {
            "owner_type": owner_type ? owner_type : artisan?.bank_detail?.owner_type,
            "owner_id": artisan?.bank_detail?.owner_id,
            "bank_name": bank_name ? bank_name : artisan?.bank_detail?.bank_name,
            "account_type": account_type ? account_type : artisan?.bank_detail?.account_type,
            "account_number": account_number ? account_number : artisan?.bank_detail?.account_number,
            "account_holder_name": account_holder_name ? account_holder_name : artisan?.bank_detail?.account_holder_name,
            "_method": "PUT",
        };
        updateBank(artisan?.bank_detail?.id, data, dispatch)
    }
    return (
        <>
            <div id="layoutSidenav">
                <SidebarNavbar />
                <div id="layoutSidenav_content">
                    <main>
                        <div className="container px-2">
                            <div className="d-flex justify-content-between align-items-baseline">
                                <h4 className="mt-4">Artisan Management</h4>
                                <div className="breadcrumb mb-4">
                                    <a className="btn btn-xs btn-primary" href="/add-artisan">Add Artisan</a>|
                                    <a className="btn btn-xs btn-primary" href="/artisan-management">List Artisan</a>
                                </div>
                                
                            </div>
                            <div className="row justify-content-center">
                                <div className="col-lg-12">
                                    <div className="card shadow-lg border-0 rounded-lg mt-5">
                                        <div className="card-header">
                                            <h5 className=" my-4">Artisan Information</h5>
                                        </div>
                                        <div className="card-body">
                                          
                                               
                                                    <div className="card-header">
                                                        <h5 className="mt-5 font-weight-light">Artisan Details</h5>
                                                    </div>
                                                        <div className="row mb-3">
                                                        <img src={artisan?.logo} style={{width: '30%', height:'30%'}} /> 
                                                        </div>
                                                        <div className="row mb-3">
                                                            <div className="col-md-4">
                                                                <div className="form-floating mb-3 mb-md-0">
                                                                    <input className="form-control"
                                                                        id="first_name"
                                                                        type="text"
                                                                        name="first_name"
                                                                        readOnly
                                                                        defaultValue={artisan?.first_name}
                                                                        onChange={(e) => setFirstname(e.target.value)}
                                                                        placeholder="Enter first name" />
                                                                    <label htmlFor="inputFirstName">First name</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="form-floating">
                                                                    <input className="form-control"
                                                                        // id="inputLastName"
                                                                        type="text"
                                                                        name="last_name"
                                                                        readOnly
                                                                        defaultValue={artisan?.last_name}
                                                                        onChange={(e) => setLastname(e.target.value)}
                                                                        placeholder="Enter last name" />
                                                                    <label htmlFor="inputLastName">Last name</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="form-floating">
                                                                    <input className="form-control" id="inputLastName"
                                                                        type="text"
                                                                        name="business_name"
                                                                        readOnly
                                                                        defaultValue={artisan?.business_name}
                                                                        onChange={(e) => setBusinessname(e.target.value)}
                                                                        placeholder="Enter business name" />
                                                                    <label htmlFor="inputLastName">Business name</label>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="row mb-3">
                                                            <div className="col-md-4">
                                                                <div className="form-floating mb-3 mb-md-0">
                                                                    <input className="form-control" id="inputFirstName"
                                                                        type="text"
                                                                        name="address"
                                                                        readOnly
                                                                        defaultValue={artisan?.address}
                                                                        onChange={(e) => setAddress(e.target.value)}
                                                                        placeholder="Enter address"
                                                                    />
                                                                    <label htmlFor="inputFirstName">Address</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="form-floating">
                                                                    <select
                                                                        className="form-control"
                                                                        name="state_id"
                                                                        readOnly
                                                                        value={artisan?.state_id}
                                                                        id="state_id">
                                                                        <option>Select State</option>
                                                                        {states?.map((item, idx) =>
                                                                            <option key={idx} value={item.id}>{item.name}</option>
                                                                        )}
                                                                    </select>
                                                                    <label htmlFor="inputLastName">State</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="form-floating">
                                                                    <select
                                                                        className="form-control"
                                                                        name="city_id"
                                                                        readOnly
                                                                        value={artisan?.city_id}
                                                                        id="city_id">
                                                                        <option>Select City</option>
                                                                        {cities?.map((item, idx) =>
                                                                            <option key={idx} value={item.id}>{item.name}</option>
                                                                        )}
                                                                    </select>
                                                                    <label htmlFor="inputLastName">City</label>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="row mb-3">
                                                            <div className="col-md-4">
                                                                <div className="form-floating mb-3 mb-md-0">
                                                                    <select
                                                                        className="form-control"
                                                                        name="category_id"
                                                                        readOnly
                                                                        value={artisan?.category_id}
                                                                        onChange={(e) => setCategory(e.target.value)}
                                                                        id="category_id">
                                                                        <option>Select Category</option>
                                                                        {categories?.map((item, idx) =>
                                                                            <option key={idx} value={item.id}>{item.name}</option>
                                                                        )}
                                                                    </select>
                                                                    <label htmlFor="inputCategories">Category</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="form-floating">
                                                                    <select
                                                                        className="form-control"
                                                                        name="association_id"
                                                                        readOnly
                                                                        defaultValue={artisan?.association_id}
                                                                        onChange={(e) => setAssociation(e.target.value)}
                                                                        id="association_id">
                                                                        <option>Select Association</option>
                                                                        {associations.map((item, idx) =>
                                                                            <option key={idx} value={item.id}>{item.name}</option>
                                                                        )}
                                                                    </select>
                                                                    <label htmlFor="inputLastName">Association</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="form-floating mb-3 mb-md-0">
                                                                    <input className="form-control" id="inputFirstName"
                                                                        type="text"
                                                                        name="address"
                                                                        readOnly
                                                                        defaultValue={artisan?.phone}
                                                                    />
                                                                    <label htmlFor="inputFirstName">Mobile</label>
                                                                </div>
                                                            </div>
                                                            
                                                        </div>

                                                                                                              
                                                    

                                                    <div className="card-header">
                                                        <h5 className="mt-5 font-weight-light">Bank Details</h5>
                                                    </div>
                                                    
                                                        <div className="row mb-3">
                                                            
                                                            <div className="col-md-6">
                                                                <div className="form-floating mb-3 mb-md-0">
                                                                    <input className="form-control" id="inputFirstName"
                                                                        type="text"
                                                                        name="bank_name"
                                                                        readOnly
                                                                        defaultValue={artisan?.bank_detail?.bank_name}
                                                                        onChange={(e) => setBankName(e.target.value)}
                                                                        placeholder="Enter bank name" />
                                                                    <label htmlFor="inputFirstName">Bank Name</label>
                                                                </div>
                                                            </div>

                                                            <div className="col-md-6">
                                                                <div className="form-floating mb-3 mb-md-0">
                                                                    <select
                                                                        name="account_type"
                                                                        readOnly
                                                                        defaultValue={artisan?.bank_detail?.account_type}
                                                                        onChange={(e) => setAccountType(e.target.value)}
                                                                        className="form-control" id="inputCategories" >
                                                                        <option>Select Account Type</option>
                                                                        <option value="savings">Savings</option>
                                                                        <option value="current">Current</option>
                                                                    </select>
                                                                    <label htmlFor="inputFirstName">Account Type</label>
                                                                </div>
                                                            </div>

                                                        </div>

                                                        <div className="row mb-3">
                                                            <div className="col-md-6">
                                                                <div className="form-floating">
                                                                    <input className="form-control" id="inputLastName"
                                                                        type="text"
                                                                        readOnly
                                                                        name="account_holder_name"
                                                                        defaultValue={artisan?.bank_detail?.account_holder_name}
                                                                        onChange={(e) => setAccountHolderName(e.target.value)}
                                                                        placeholder="Enter account name" />
                                                                    <label htmlFor="inputLastName">Account Holder Name</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="form-floating">
                                                                    <input className="form-control" id="inputLastName"
                                                                        type="text"
                                                                        name="account_number"
                                                                        readOnly
                                                                        defaultValue={artisan?.bank_detail?.account_number}
                                                                        onChange={(e) => setAccountNumber(e.target.value)}
                                                                        placeholder="Enter account number" />
                                                                    <label htmlFor="inputLastName">Account Number</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                               
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                    <Footer />
                </div>
            </div>
        </>
    )
}
export default View;