import Loader from "../../asset/images/loader4.gif";
import React from "react";

const CustomLoader = () => (
    <div style={{ padding: '24px' }}>
        <img src={Loader} className="loader-image-table" />
        {/*<div>Loader data...</div>*/}
    </div>
);

export default CustomLoader;